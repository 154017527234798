// import { i18n } from "~~/plugins/i18n"
import md5 from "@/utils/md5"
import { Base64 } from "@/utils/base64"
import { stringToBytes, byteToString, clearCookie } from "@/utils/utils"

// 加密key
const XOR_ENCRYPT_KEY = "c9b5a4"

export const useInstanceProxyT = () => {
  const { proxy } = getCurrentInstance()
  return proxy.$t
}

export const useProxy = () => {
  const { proxy } = getCurrentInstance()
  return proxy
}

// md5 加密
export const useMd5 = (str: string | number) => {
  return md5.hex_md5(typeof str === "number" ? str + "" : str)
}

// xor 加密
export function useXorEncrypt(message, key = XOR_ENCRYPT_KEY) {
  message = typeof message === "number" ? message + "" : message
  const resultMessage = stringToBytes(message)
  const resultKey = stringToBytes(key)
  const messageLength = resultMessage.length
  const keyLength = resultKey.length
  const resultText = []
  for (let i = 0; i < messageLength; i++) {
    resultText[i] = resultMessage[i] ^ (resultKey[i % keyLength] as any)
    if (resultText[i] == 255) {
      resultText[i] = 0
    } else {
      resultText[i] += 1
    }
  }
  return Base64.encode(byteToString(resultText))
}

// 数字转千分分隔
export const useNumFormat = (num) => {
  return num?.toString().replace(/\d+/, function (n) {
    // 先提取整数部分
    return n.replace(/(\d)(?=(\d{3})+$)/g, function ($1) {
      return $1 + ","
    })
  })
}

// 千分分隔转化数字
export const parseFormattedNumber = (formattedNumber) => {
  if (typeof formattedNumber !== "string") return NaN

  // 去掉千分位逗号并转换为数字
  return parseFloat(formattedNumber.replace(/,/g, ""))
}

// 数字转换为 K
export function useNumFormatByK(num, digits = 1) {
  // 如果输入是字符串，去掉逗号分隔符
  if (typeof num === "string") {
    num = num.replace(/,/g, "")
  }
  const si = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" }
  ]
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/
  let i
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break
    }
  }
  return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol
}

// 保留小数位数并且四舍五入
export function keepDecimals(val: number | string, places: number, type: 1 | 2 = 1) {
  let num = (val + "") as any
  const numStart = num.split(".")[0]
  const numEnd = num.split(".")[1]
  const powVal = Math.pow(10, places)
  if (numEnd) {
    num = Math.round(num * powVal) / powVal + ""
    if (num.split(".")[1] && num.split(".")[1].length < places) {
      for (let n = 0; n < places - num.split(".")[1].length; n++) {
        num += "0"
      }
    }
  } else {
    num = numStart + "."
    for (let n = 0; n < places; n++) {
      num += "0"
    }
  }
  return type == 1 ? (num = num * 1) : (num = num + "")
}

// window.open 跳转 - ios不支持
export function useLocationOpen(src: string) {
  const { isIOS } = useDevice()
  unref(isIOS) ? (location.href = src) : window.open(src, "_blank")
}

export function asyncAppendScript(
  src: string,
  opts?: Record<string, any>,
  domName: "script" | "noscript" = "script"
): Promise<Event> {
  return new Promise((res, rej) => {
    const script = document.createElement(domName) as HTMLScriptElement
    src && (script.src = src)
    opts && Object.assign(script, opts)
    script.onload = (data) => {
      if (opts.innerHTML) {
        const script = document.createElement(domName) as HTMLScriptElement
        script.innerHTML = opts.innerHTML
        document.body.appendChild(script)
      }
      res(data)
    }
    script.onerror = (e) => rej(e)
    document.body.appendChild(script)
  })
}

export type IAppendScriptBatchArr = {
  opts?: Record<string, any> & { innerHTML?: string }
  domName: "script" | "noscript"
}[]

export function asyncAppendScriptBatch(scripts: IAppendScriptBatchArr = []) {
  return Promise.allSettled(scripts.map((item) => asyncAppendScript(item.opts?.src, item.opts, item.domName)))
}

export function useScrollToTop() {
  document.documentElement.scrollTop = 0 // 兼容性较好的方法
  document.body.scrollTop = 0 // 兼容性较差的备用方法
}

const backupStorage = {}

export function detectedStorage(storageName = "localStorage") {
  if (storageName === "localStorage") {
    try {
      localStorage.length
      return "localStorage"
    } catch (e) {
      try {
        sessionStorage.length
        return "sessionStorage"
      } catch (e) {
        return "backupStorage"
      }
    }
  } else {
    try {
      sessionStorage.length
      return "sessionStorage"
    } catch (e) {
      return "backupStorage"
    }
  }
}

export function setLocalStorage(
  key,
  value,
  storageName: "localStorage" | "sessionStorage" | "backupStorage" = "localStorage"
) {
  const storageMode = detectedStorage(storageName)
  switch (storageMode) {
    case "localStorage":
      localStorage.setItem(key, value)
      break
    case "sessionStorage":
      sessionStorage.setItem(key, value)
      break
    case "backupStorage":
      backupStorage[key] = value
      break
  }
}
export function getLocalStorage(
  key,
  storageName: "localStorage" | "sessionStorage" | "backupStorage" = "localStorage"
) {
  const storageMode = detectedStorage(storageName)
  switch (storageMode) {
    case "localStorage":
      return localStorage.getItem(key)
    case "sessionStorage":
      return sessionStorage.getItem(key)
    case "backupStorage":
      return backupStorage[key]
  }
}

export function removeLocalStorage(
  key,
  storageName: "localStorage" | "sessionStorage" | "backupStorage" = "localStorage"
) {
  const storageMode = detectedStorage(storageName)
  switch (storageMode) {
    case "localStorage":
      localStorage.removeItem(key)
      break
    case "sessionStorage":
      sessionStorage.removeItem(key)
      break
    case "backupStorage":
      delete backupStorage[key]
  }
}
