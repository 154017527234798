
// @ts-nocheck


export const localeCodes =  [
  "en",
  "vi",
  "ko",
  "ja",
  "de",
  "fr",
  "es",
  "it",
  "pt",
  "es_Es",
  "zh-Hant",
  "pl"
]

export const localeLoaders = {
  "en": [{ key: "../lang/en/blog.json", load: () => import("../lang/en/blog.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_en_blog_json" */), cache: true },
{ key: "../lang/en/collaborate.json", load: () => import("../lang/en/collaborate.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_en_collaborate_json" */), cache: true },
{ key: "../lang/en/colorTrackingForm.json", load: () => import("../lang/en/colorTrackingForm.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_en_colorTrackingForm_json" */), cache: true },
{ key: "../lang/en/common.json", load: () => import("../lang/en/common.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_en_common_json" */), cache: true },
{ key: "../lang/en/company.json", load: () => import("../lang/en/company.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_en_company_json" */), cache: true },
{ key: "../lang/en/download.json", load: () => import("../lang/en/download.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_en_download_json" */), cache: true },
{ key: "../lang/en/exchange.json", load: () => import("../lang/en/exchange.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_en_exchange_json" */), cache: true },
{ key: "../lang/en/features.json", load: () => import("../lang/en/features.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_en_features_json" */), cache: true },
{ key: "../lang/en/help.json", load: () => import("../lang/en/help.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_en_help_json" */), cache: true },
{ key: "../lang/en/home.json", load: () => import("../lang/en/home.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_en_home_json" */), cache: true },
{ key: "../lang/en/im.json", load: () => import("../lang/en/im.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_en_im_json" */), cache: true },
{ key: "../lang/en/invitee.json", load: () => import("../lang/en/invitee.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_en_invitee_json" */), cache: true },
{ key: "../lang/en/inviter.json", load: () => import("../lang/en/inviter.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_en_inviter_json" */), cache: true },
{ key: "../lang/en/ipad.json", load: () => import("../lang/en/ipad.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_en_ipad_json" */), cache: true },
{ key: "../lang/en/landing.json", load: () => import("../lang/en/landing.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_en_landing_json" */), cache: true },
{ key: "../lang/en/login.json", load: () => import("../lang/en/login.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_en_login_json" */), cache: true },
{ key: "../lang/en/newHome.json", load: () => import("../lang/en/newHome.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_en_newHome_json" */), cache: true },
{ key: "../lang/en/payment.json", load: () => import("../lang/en/payment.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_en_payment_json" */), cache: true },
{ key: "../lang/en/profile.json", load: () => import("../lang/en/profile.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_en_profile_json" */), cache: true },
{ key: "../lang/en/referral.json", load: () => import("../lang/en/referral.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_en_referral_json" */), cache: true },
{ key: "../lang/en/search.json", load: () => import("../lang/en/search.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_en_search_json" */), cache: true },
{ key: "../lang/en/start.json", load: () => import("../lang/en/start.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_en_start_json" */), cache: true },
{ key: "../lang/en/trustpoilot.json", load: () => import("../lang/en/trustpoilot.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_en_trustpoilot_json" */), cache: true }],
  "vi": [{ key: "../lang/vi/blog.json", load: () => import("../lang/vi/blog.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_vi_blog_json" */), cache: true },
{ key: "../lang/vi/collaborate.json", load: () => import("../lang/vi/collaborate.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_vi_collaborate_json" */), cache: true },
{ key: "../lang/vi/colorTrackingForm.json", load: () => import("../lang/vi/colorTrackingForm.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_vi_colorTrackingForm_json" */), cache: true },
{ key: "../lang/vi/common.json", load: () => import("../lang/vi/common.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_vi_common_json" */), cache: true },
{ key: "../lang/vi/company.json", load: () => import("../lang/vi/company.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_vi_company_json" */), cache: true },
{ key: "../lang/vi/download.json", load: () => import("../lang/vi/download.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_vi_download_json" */), cache: true },
{ key: "../lang/vi/exchange.json", load: () => import("../lang/vi/exchange.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_vi_exchange_json" */), cache: true },
{ key: "../lang/vi/features.json", load: () => import("../lang/vi/features.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_vi_features_json" */), cache: true },
{ key: "../lang/vi/help.json", load: () => import("../lang/vi/help.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_vi_help_json" */), cache: true },
{ key: "../lang/vi/home.json", load: () => import("../lang/vi/home.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_vi_home_json" */), cache: true },
{ key: "../lang/vi/im.json", load: () => import("../lang/vi/im.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_vi_im_json" */), cache: true },
{ key: "../lang/vi/invitee.json", load: () => import("../lang/vi/invitee.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_vi_invitee_json" */), cache: true },
{ key: "../lang/vi/inviter.json", load: () => import("../lang/vi/inviter.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_vi_inviter_json" */), cache: true },
{ key: "../lang/vi/ipad.json", load: () => import("../lang/vi/ipad.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_vi_ipad_json" */), cache: true },
{ key: "../lang/vi/landing.json", load: () => import("../lang/vi/landing.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_vi_landing_json" */), cache: true },
{ key: "../lang/vi/login.json", load: () => import("../lang/vi/login.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_vi_login_json" */), cache: true },
{ key: "../lang/vi/newHome.json", load: () => import("../lang/vi/newHome.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_vi_newHome_json" */), cache: true },
{ key: "../lang/vi/payment.json", load: () => import("../lang/vi/payment.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_vi_payment_json" */), cache: true },
{ key: "../lang/vi/profile.json", load: () => import("../lang/vi/profile.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_vi_profile_json" */), cache: true },
{ key: "../lang/vi/referral.json", load: () => import("../lang/vi/referral.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_vi_referral_json" */), cache: true },
{ key: "../lang/vi/search.json", load: () => import("../lang/vi/search.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_vi_search_json" */), cache: true },
{ key: "../lang/vi/start.json", load: () => import("../lang/vi/start.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_vi_start_json" */), cache: true },
{ key: "../lang/vi/trustpoilot.json", load: () => import("../lang/vi/trustpoilot.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_vi_trustpoilot_json" */), cache: true }],
  "ko": [{ key: "../lang/ko/blog.json", load: () => import("../lang/ko/blog.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_ko_blog_json" */), cache: true },
{ key: "../lang/ko/collaborate.json", load: () => import("../lang/ko/collaborate.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_ko_collaborate_json" */), cache: true },
{ key: "../lang/ko/colorTrackingForm.json", load: () => import("../lang/ko/colorTrackingForm.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_ko_colorTrackingForm_json" */), cache: true },
{ key: "../lang/ko/common.json", load: () => import("../lang/ko/common.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_ko_common_json" */), cache: true },
{ key: "../lang/ko/company.json", load: () => import("../lang/ko/company.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_ko_company_json" */), cache: true },
{ key: "../lang/ko/download.json", load: () => import("../lang/ko/download.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_ko_download_json" */), cache: true },
{ key: "../lang/ko/exchange.json", load: () => import("../lang/ko/exchange.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_ko_exchange_json" */), cache: true },
{ key: "../lang/ko/features.json", load: () => import("../lang/ko/features.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_ko_features_json" */), cache: true },
{ key: "../lang/ko/help.json", load: () => import("../lang/ko/help.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_ko_help_json" */), cache: true },
{ key: "../lang/ko/home.json", load: () => import("../lang/ko/home.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_ko_home_json" */), cache: true },
{ key: "../lang/ko/im.json", load: () => import("../lang/ko/im.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_ko_im_json" */), cache: true },
{ key: "../lang/ko/invitee.json", load: () => import("../lang/ko/invitee.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_ko_invitee_json" */), cache: true },
{ key: "../lang/ko/inviter.json", load: () => import("../lang/ko/inviter.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_ko_inviter_json" */), cache: true },
{ key: "../lang/ko/ipad.json", load: () => import("../lang/ko/ipad.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_ko_ipad_json" */), cache: true },
{ key: "../lang/ko/landing.json", load: () => import("../lang/ko/landing.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_ko_landing_json" */), cache: true },
{ key: "../lang/ko/login.json", load: () => import("../lang/ko/login.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_ko_login_json" */), cache: true },
{ key: "../lang/ko/newHome.json", load: () => import("../lang/ko/newHome.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_ko_newHome_json" */), cache: true },
{ key: "../lang/ko/payment.json", load: () => import("../lang/ko/payment.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_ko_payment_json" */), cache: true },
{ key: "../lang/ko/profile.json", load: () => import("../lang/ko/profile.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_ko_profile_json" */), cache: true },
{ key: "../lang/ko/referral.json", load: () => import("../lang/ko/referral.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_ko_referral_json" */), cache: true },
{ key: "../lang/ko/search.json", load: () => import("../lang/ko/search.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_ko_search_json" */), cache: true },
{ key: "../lang/ko/start.json", load: () => import("../lang/ko/start.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_ko_start_json" */), cache: true },
{ key: "../lang/ko/trustpoilot.json", load: () => import("../lang/ko/trustpoilot.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_ko_trustpoilot_json" */), cache: true }],
  "ja": [{ key: "../lang/ja/blog.json", load: () => import("../lang/ja/blog.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_ja_blog_json" */), cache: true },
{ key: "../lang/ja/collaborate.json", load: () => import("../lang/ja/collaborate.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_ja_collaborate_json" */), cache: true },
{ key: "../lang/ja/colorTrackingForm.json", load: () => import("../lang/ja/colorTrackingForm.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_ja_colorTrackingForm_json" */), cache: true },
{ key: "../lang/ja/common.json", load: () => import("../lang/ja/common.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_ja_common_json" */), cache: true },
{ key: "../lang/ja/company.json", load: () => import("../lang/ja/company.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_ja_company_json" */), cache: true },
{ key: "../lang/ja/download.json", load: () => import("../lang/ja/download.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_ja_download_json" */), cache: true },
{ key: "../lang/ja/exchange.json", load: () => import("../lang/ja/exchange.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_ja_exchange_json" */), cache: true },
{ key: "../lang/ja/features.json", load: () => import("../lang/ja/features.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_ja_features_json" */), cache: true },
{ key: "../lang/ja/help.json", load: () => import("../lang/ja/help.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_ja_help_json" */), cache: true },
{ key: "../lang/ja/home.json", load: () => import("../lang/ja/home.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_ja_home_json" */), cache: true },
{ key: "../lang/ja/im.json", load: () => import("../lang/ja/im.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_ja_im_json" */), cache: true },
{ key: "../lang/ja/invitee.json", load: () => import("../lang/ja/invitee.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_ja_invitee_json" */), cache: true },
{ key: "../lang/ja/inviter.json", load: () => import("../lang/ja/inviter.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_ja_inviter_json" */), cache: true },
{ key: "../lang/ja/ipad.json", load: () => import("../lang/ja/ipad.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_ja_ipad_json" */), cache: true },
{ key: "../lang/ja/landing.json", load: () => import("../lang/ja/landing.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_ja_landing_json" */), cache: true },
{ key: "../lang/ja/login.json", load: () => import("../lang/ja/login.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_ja_login_json" */), cache: true },
{ key: "../lang/ja/newHome.json", load: () => import("../lang/ja/newHome.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_ja_newHome_json" */), cache: true },
{ key: "../lang/ja/payment.json", load: () => import("../lang/ja/payment.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_ja_payment_json" */), cache: true },
{ key: "../lang/ja/profile.json", load: () => import("../lang/ja/profile.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_ja_profile_json" */), cache: true },
{ key: "../lang/ja/referral.json", load: () => import("../lang/ja/referral.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_ja_referral_json" */), cache: true },
{ key: "../lang/ja/search.json", load: () => import("../lang/ja/search.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_ja_search_json" */), cache: true },
{ key: "../lang/ja/start.json", load: () => import("../lang/ja/start.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_ja_start_json" */), cache: true },
{ key: "../lang/ja/trustpoilot.json", load: () => import("../lang/ja/trustpoilot.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_ja_trustpoilot_json" */), cache: true }],
  "de": [{ key: "../lang/de/blog.json", load: () => import("../lang/de/blog.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_de_blog_json" */), cache: true },
{ key: "../lang/de/collaborate.json", load: () => import("../lang/de/collaborate.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_de_collaborate_json" */), cache: true },
{ key: "../lang/de/colorTrackingForm.json", load: () => import("../lang/de/colorTrackingForm.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_de_colorTrackingForm_json" */), cache: true },
{ key: "../lang/de/common.json", load: () => import("../lang/de/common.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_de_common_json" */), cache: true },
{ key: "../lang/de/company.json", load: () => import("../lang/de/company.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_de_company_json" */), cache: true },
{ key: "../lang/de/download.json", load: () => import("../lang/de/download.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_de_download_json" */), cache: true },
{ key: "../lang/de/exchange.json", load: () => import("../lang/de/exchange.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_de_exchange_json" */), cache: true },
{ key: "../lang/de/features.json", load: () => import("../lang/de/features.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_de_features_json" */), cache: true },
{ key: "../lang/de/help.json", load: () => import("../lang/de/help.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_de_help_json" */), cache: true },
{ key: "../lang/de/home.json", load: () => import("../lang/de/home.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_de_home_json" */), cache: true },
{ key: "../lang/de/im.json", load: () => import("../lang/de/im.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_de_im_json" */), cache: true },
{ key: "../lang/de/invitee.json", load: () => import("../lang/de/invitee.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_de_invitee_json" */), cache: true },
{ key: "../lang/de/inviter.json", load: () => import("../lang/de/inviter.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_de_inviter_json" */), cache: true },
{ key: "../lang/de/ipad.json", load: () => import("../lang/de/ipad.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_de_ipad_json" */), cache: true },
{ key: "../lang/de/landing.json", load: () => import("../lang/de/landing.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_de_landing_json" */), cache: true },
{ key: "../lang/de/login.json", load: () => import("../lang/de/login.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_de_login_json" */), cache: true },
{ key: "../lang/de/newHome.json", load: () => import("../lang/de/newHome.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_de_newHome_json" */), cache: true },
{ key: "../lang/de/payment.json", load: () => import("../lang/de/payment.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_de_payment_json" */), cache: true },
{ key: "../lang/de/profile.json", load: () => import("../lang/de/profile.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_de_profile_json" */), cache: true },
{ key: "../lang/de/referral.json", load: () => import("../lang/de/referral.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_de_referral_json" */), cache: true },
{ key: "../lang/de/search.json", load: () => import("../lang/de/search.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_de_search_json" */), cache: true },
{ key: "../lang/de/start.json", load: () => import("../lang/de/start.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_de_start_json" */), cache: true },
{ key: "../lang/de/trustpoilot.json", load: () => import("../lang/de/trustpoilot.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_de_trustpoilot_json" */), cache: true }],
  "fr": [{ key: "../lang/fr/blog.json", load: () => import("../lang/fr/blog.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_fr_blog_json" */), cache: true },
{ key: "../lang/fr/collaborate.json", load: () => import("../lang/fr/collaborate.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_fr_collaborate_json" */), cache: true },
{ key: "../lang/fr/colorTrackingForm.json", load: () => import("../lang/fr/colorTrackingForm.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_fr_colorTrackingForm_json" */), cache: true },
{ key: "../lang/fr/common.json", load: () => import("../lang/fr/common.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_fr_common_json" */), cache: true },
{ key: "../lang/fr/company.json", load: () => import("../lang/fr/company.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_fr_company_json" */), cache: true },
{ key: "../lang/fr/download.json", load: () => import("../lang/fr/download.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_fr_download_json" */), cache: true },
{ key: "../lang/fr/exchange.json", load: () => import("../lang/fr/exchange.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_fr_exchange_json" */), cache: true },
{ key: "../lang/fr/features.json", load: () => import("../lang/fr/features.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_fr_features_json" */), cache: true },
{ key: "../lang/fr/help.json", load: () => import("../lang/fr/help.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_fr_help_json" */), cache: true },
{ key: "../lang/fr/home.json", load: () => import("../lang/fr/home.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_fr_home_json" */), cache: true },
{ key: "../lang/fr/im.json", load: () => import("../lang/fr/im.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_fr_im_json" */), cache: true },
{ key: "../lang/fr/invitee.json", load: () => import("../lang/fr/invitee.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_fr_invitee_json" */), cache: true },
{ key: "../lang/fr/inviter.json", load: () => import("../lang/fr/inviter.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_fr_inviter_json" */), cache: true },
{ key: "../lang/fr/ipad.json", load: () => import("../lang/fr/ipad.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_fr_ipad_json" */), cache: true },
{ key: "../lang/fr/landing.json", load: () => import("../lang/fr/landing.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_fr_landing_json" */), cache: true },
{ key: "../lang/fr/login.json", load: () => import("../lang/fr/login.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_fr_login_json" */), cache: true },
{ key: "../lang/fr/newHome.json", load: () => import("../lang/fr/newHome.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_fr_newHome_json" */), cache: true },
{ key: "../lang/fr/payment.json", load: () => import("../lang/fr/payment.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_fr_payment_json" */), cache: true },
{ key: "../lang/fr/profile.json", load: () => import("../lang/fr/profile.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_fr_profile_json" */), cache: true },
{ key: "../lang/fr/referral.json", load: () => import("../lang/fr/referral.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_fr_referral_json" */), cache: true },
{ key: "../lang/fr/search.json", load: () => import("../lang/fr/search.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_fr_search_json" */), cache: true },
{ key: "../lang/fr/start.json", load: () => import("../lang/fr/start.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_fr_start_json" */), cache: true },
{ key: "../lang/fr/trustpoilot.json", load: () => import("../lang/fr/trustpoilot.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_fr_trustpoilot_json" */), cache: true }],
  "es": [{ key: "../lang/es/blog.json", load: () => import("../lang/es/blog.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_es_blog_json" */), cache: true },
{ key: "../lang/es/collaborate.json", load: () => import("../lang/es/collaborate.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_es_collaborate_json" */), cache: true },
{ key: "../lang/es/colorTrackingForm.json", load: () => import("../lang/es/colorTrackingForm.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_es_colorTrackingForm_json" */), cache: true },
{ key: "../lang/es/common.json", load: () => import("../lang/es/common.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_es_common_json" */), cache: true },
{ key: "../lang/es/company.json", load: () => import("../lang/es/company.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_es_company_json" */), cache: true },
{ key: "../lang/es/download.json", load: () => import("../lang/es/download.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_es_download_json" */), cache: true },
{ key: "../lang/es/exchange.json", load: () => import("../lang/es/exchange.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_es_exchange_json" */), cache: true },
{ key: "../lang/es/features.json", load: () => import("../lang/es/features.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_es_features_json" */), cache: true },
{ key: "../lang/es/help.json", load: () => import("../lang/es/help.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_es_help_json" */), cache: true },
{ key: "../lang/es/home.json", load: () => import("../lang/es/home.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_es_home_json" */), cache: true },
{ key: "../lang/es/im.json", load: () => import("../lang/es/im.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_es_im_json" */), cache: true },
{ key: "../lang/es/invitee.json", load: () => import("../lang/es/invitee.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_es_invitee_json" */), cache: true },
{ key: "../lang/es/inviter.json", load: () => import("../lang/es/inviter.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_es_inviter_json" */), cache: true },
{ key: "../lang/es/ipad.json", load: () => import("../lang/es/ipad.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_es_ipad_json" */), cache: true },
{ key: "../lang/es/landing.json", load: () => import("../lang/es/landing.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_es_landing_json" */), cache: true },
{ key: "../lang/es/login.json", load: () => import("../lang/es/login.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_es_login_json" */), cache: true },
{ key: "../lang/es/newHome.json", load: () => import("../lang/es/newHome.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_es_newHome_json" */), cache: true },
{ key: "../lang/es/payment.json", load: () => import("../lang/es/payment.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_es_payment_json" */), cache: true },
{ key: "../lang/es/profile.json", load: () => import("../lang/es/profile.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_es_profile_json" */), cache: true },
{ key: "../lang/es/referral.json", load: () => import("../lang/es/referral.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_es_referral_json" */), cache: true },
{ key: "../lang/es/search.json", load: () => import("../lang/es/search.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_es_search_json" */), cache: true },
{ key: "../lang/es/start.json", load: () => import("../lang/es/start.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_es_start_json" */), cache: true },
{ key: "../lang/es/trustpoilot.json", load: () => import("../lang/es/trustpoilot.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_es_trustpoilot_json" */), cache: true }],
  "it": [{ key: "../lang/it/blog.json", load: () => import("../lang/it/blog.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_it_blog_json" */), cache: true },
{ key: "../lang/it/collaborate.json", load: () => import("../lang/it/collaborate.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_it_collaborate_json" */), cache: true },
{ key: "../lang/it/colorTrackingForm.json", load: () => import("../lang/it/colorTrackingForm.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_it_colorTrackingForm_json" */), cache: true },
{ key: "../lang/it/common.json", load: () => import("../lang/it/common.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_it_common_json" */), cache: true },
{ key: "../lang/it/company.json", load: () => import("../lang/it/company.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_it_company_json" */), cache: true },
{ key: "../lang/it/download.json", load: () => import("../lang/it/download.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_it_download_json" */), cache: true },
{ key: "../lang/it/exchange.json", load: () => import("../lang/it/exchange.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_it_exchange_json" */), cache: true },
{ key: "../lang/it/features.json", load: () => import("../lang/it/features.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_it_features_json" */), cache: true },
{ key: "../lang/it/help.json", load: () => import("../lang/it/help.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_it_help_json" */), cache: true },
{ key: "../lang/it/home.json", load: () => import("../lang/it/home.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_it_home_json" */), cache: true },
{ key: "../lang/it/im.json", load: () => import("../lang/it/im.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_it_im_json" */), cache: true },
{ key: "../lang/it/invitee.json", load: () => import("../lang/it/invitee.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_it_invitee_json" */), cache: true },
{ key: "../lang/it/inviter.json", load: () => import("../lang/it/inviter.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_it_inviter_json" */), cache: true },
{ key: "../lang/it/ipad.json", load: () => import("../lang/it/ipad.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_it_ipad_json" */), cache: true },
{ key: "../lang/it/landing.json", load: () => import("../lang/it/landing.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_it_landing_json" */), cache: true },
{ key: "../lang/it/login.json", load: () => import("../lang/it/login.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_it_login_json" */), cache: true },
{ key: "../lang/it/newHome.json", load: () => import("../lang/it/newHome.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_it_newHome_json" */), cache: true },
{ key: "../lang/it/payment.json", load: () => import("../lang/it/payment.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_it_payment_json" */), cache: true },
{ key: "../lang/it/profile.json", load: () => import("../lang/it/profile.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_it_profile_json" */), cache: true },
{ key: "../lang/it/referral.json", load: () => import("../lang/it/referral.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_it_referral_json" */), cache: true },
{ key: "../lang/it/search.json", load: () => import("../lang/it/search.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_it_search_json" */), cache: true },
{ key: "../lang/it/start.json", load: () => import("../lang/it/start.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_it_start_json" */), cache: true },
{ key: "../lang/it/trustpoilot.json", load: () => import("../lang/it/trustpoilot.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_it_trustpoilot_json" */), cache: true }],
  "pt": [{ key: "../lang/pt/blog.json", load: () => import("../lang/pt/blog.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_pt_blog_json" */), cache: true },
{ key: "../lang/pt/collaborate.json", load: () => import("../lang/pt/collaborate.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_pt_collaborate_json" */), cache: true },
{ key: "../lang/pt/colorTrackingForm.json", load: () => import("../lang/pt/colorTrackingForm.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_pt_colorTrackingForm_json" */), cache: true },
{ key: "../lang/pt/common.json", load: () => import("../lang/pt/common.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_pt_common_json" */), cache: true },
{ key: "../lang/pt/company.json", load: () => import("../lang/pt/company.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_pt_company_json" */), cache: true },
{ key: "../lang/pt/download.json", load: () => import("../lang/pt/download.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_pt_download_json" */), cache: true },
{ key: "../lang/pt/exchange.json", load: () => import("../lang/pt/exchange.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_pt_exchange_json" */), cache: true },
{ key: "../lang/pt/features.json", load: () => import("../lang/pt/features.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_pt_features_json" */), cache: true },
{ key: "../lang/pt/help.json", load: () => import("../lang/pt/help.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_pt_help_json" */), cache: true },
{ key: "../lang/pt/home.json", load: () => import("../lang/pt/home.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_pt_home_json" */), cache: true },
{ key: "../lang/pt/im.json", load: () => import("../lang/pt/im.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_pt_im_json" */), cache: true },
{ key: "../lang/pt/invitee.json", load: () => import("../lang/pt/invitee.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_pt_invitee_json" */), cache: true },
{ key: "../lang/pt/inviter.json", load: () => import("../lang/pt/inviter.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_pt_inviter_json" */), cache: true },
{ key: "../lang/pt/ipad.json", load: () => import("../lang/pt/ipad.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_pt_ipad_json" */), cache: true },
{ key: "../lang/pt/landing.json", load: () => import("../lang/pt/landing.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_pt_landing_json" */), cache: true },
{ key: "../lang/pt/login.json", load: () => import("../lang/pt/login.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_pt_login_json" */), cache: true },
{ key: "../lang/pt/newHome.json", load: () => import("../lang/pt/newHome.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_pt_newHome_json" */), cache: true },
{ key: "../lang/pt/payment.json", load: () => import("../lang/pt/payment.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_pt_payment_json" */), cache: true },
{ key: "../lang/pt/profile.json", load: () => import("../lang/pt/profile.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_pt_profile_json" */), cache: true },
{ key: "../lang/pt/referral.json", load: () => import("../lang/pt/referral.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_pt_referral_json" */), cache: true },
{ key: "../lang/pt/search.json", load: () => import("../lang/pt/search.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_pt_search_json" */), cache: true },
{ key: "../lang/pt/start.json", load: () => import("../lang/pt/start.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_pt_start_json" */), cache: true },
{ key: "../lang/pt/trustpoilot.json", load: () => import("../lang/pt/trustpoilot.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_pt_trustpoilot_json" */), cache: true }],
  "es_Es": [{ key: "../lang/es_Es/blog.json", load: () => import("../lang/es_Es/blog.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_es_Es_blog_json" */), cache: true },
{ key: "../lang/es_Es/collaborate.json", load: () => import("../lang/es_Es/collaborate.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_es_Es_collaborate_json" */), cache: true },
{ key: "../lang/es_Es/colorTrackingForm.json", load: () => import("../lang/es_Es/colorTrackingForm.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_es_Es_colorTrackingForm_json" */), cache: true },
{ key: "../lang/es_Es/common.json", load: () => import("../lang/es_Es/common.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_es_Es_common_json" */), cache: true },
{ key: "../lang/es_Es/company.json", load: () => import("../lang/es_Es/company.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_es_Es_company_json" */), cache: true },
{ key: "../lang/es_Es/download.json", load: () => import("../lang/es_Es/download.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_es_Es_download_json" */), cache: true },
{ key: "../lang/es_Es/exchange.json", load: () => import("../lang/es_Es/exchange.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_es_Es_exchange_json" */), cache: true },
{ key: "../lang/es_Es/features.json", load: () => import("../lang/es_Es/features.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_es_Es_features_json" */), cache: true },
{ key: "../lang/es_Es/help.json", load: () => import("../lang/es_Es/help.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_es_Es_help_json" */), cache: true },
{ key: "../lang/es_Es/home.json", load: () => import("../lang/es_Es/home.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_es_Es_home_json" */), cache: true },
{ key: "../lang/es_Es/im.json", load: () => import("../lang/es_Es/im.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_es_Es_im_json" */), cache: true },
{ key: "../lang/es_Es/invitee.json", load: () => import("../lang/es_Es/invitee.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_es_Es_invitee_json" */), cache: true },
{ key: "../lang/es_Es/inviter.json", load: () => import("../lang/es_Es/inviter.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_es_Es_inviter_json" */), cache: true },
{ key: "../lang/es_Es/ipad.json", load: () => import("../lang/es_Es/ipad.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_es_Es_ipad_json" */), cache: true },
{ key: "../lang/es_Es/landing.json", load: () => import("../lang/es_Es/landing.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_es_Es_landing_json" */), cache: true },
{ key: "../lang/es_Es/login.json", load: () => import("../lang/es_Es/login.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_es_Es_login_json" */), cache: true },
{ key: "../lang/es_Es/newHome.json", load: () => import("../lang/es_Es/newHome.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_es_Es_newHome_json" */), cache: true },
{ key: "../lang/es_Es/payment.json", load: () => import("../lang/es_Es/payment.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_es_Es_payment_json" */), cache: true },
{ key: "../lang/es_Es/profile.json", load: () => import("../lang/es_Es/profile.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_es_Es_profile_json" */), cache: true },
{ key: "../lang/es_Es/referral.json", load: () => import("../lang/es_Es/referral.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_es_Es_referral_json" */), cache: true },
{ key: "../lang/es_Es/search.json", load: () => import("../lang/es_Es/search.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_es_Es_search_json" */), cache: true },
{ key: "../lang/es_Es/start.json", load: () => import("../lang/es_Es/start.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_es_Es_start_json" */), cache: true },
{ key: "../lang/es_Es/trustpoilot.json", load: () => import("../lang/es_Es/trustpoilot.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_es_Es_trustpoilot_json" */), cache: true }],
  "zh-Hant": [{ key: "../lang/zh-Hant/blog.json", load: () => import("../lang/zh-Hant/blog.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_zh_Hant_blog_json" */), cache: true },
{ key: "../lang/zh-Hant/collaborate.json", load: () => import("../lang/zh-Hant/collaborate.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_zh_Hant_collaborate_json" */), cache: true },
{ key: "../lang/zh-Hant/colorTrackingForm.json", load: () => import("../lang/zh-Hant/colorTrackingForm.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_zh_Hant_colorTrackingForm_json" */), cache: true },
{ key: "../lang/zh-Hant/common.json", load: () => import("../lang/zh-Hant/common.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_zh_Hant_common_json" */), cache: true },
{ key: "../lang/zh-Hant/company.json", load: () => import("../lang/zh-Hant/company.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_zh_Hant_company_json" */), cache: true },
{ key: "../lang/zh-Hant/download.json", load: () => import("../lang/zh-Hant/download.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_zh_Hant_download_json" */), cache: true },
{ key: "../lang/zh-Hant/exchange.json", load: () => import("../lang/zh-Hant/exchange.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_zh_Hant_exchange_json" */), cache: true },
{ key: "../lang/zh-Hant/features.json", load: () => import("../lang/zh-Hant/features.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_zh_Hant_features_json" */), cache: true },
{ key: "../lang/zh-Hant/help.json", load: () => import("../lang/zh-Hant/help.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_zh_Hant_help_json" */), cache: true },
{ key: "../lang/zh-Hant/home.json", load: () => import("../lang/zh-Hant/home.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_zh_Hant_home_json" */), cache: true },
{ key: "../lang/zh-Hant/im.json", load: () => import("../lang/zh-Hant/im.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_zh_Hant_im_json" */), cache: true },
{ key: "../lang/zh-Hant/invitee.json", load: () => import("../lang/zh-Hant/invitee.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_zh_Hant_invitee_json" */), cache: true },
{ key: "../lang/zh-Hant/inviter.json", load: () => import("../lang/zh-Hant/inviter.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_zh_Hant_inviter_json" */), cache: true },
{ key: "../lang/zh-Hant/ipad.json", load: () => import("../lang/zh-Hant/ipad.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_zh_Hant_ipad_json" */), cache: true },
{ key: "../lang/zh-Hant/landing.json", load: () => import("../lang/zh-Hant/landing.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_zh_Hant_landing_json" */), cache: true },
{ key: "../lang/zh-Hant/login.json", load: () => import("../lang/zh-Hant/login.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_zh_Hant_login_json" */), cache: true },
{ key: "../lang/zh-Hant/newHome.json", load: () => import("../lang/zh-Hant/newHome.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_zh_Hant_newHome_json" */), cache: true },
{ key: "../lang/zh-Hant/payment.json", load: () => import("../lang/zh-Hant/payment.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_zh_Hant_payment_json" */), cache: true },
{ key: "../lang/zh-Hant/profile.json", load: () => import("../lang/zh-Hant/profile.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_zh_Hant_profile_json" */), cache: true },
{ key: "../lang/zh-Hant/referral.json", load: () => import("../lang/zh-Hant/referral.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_zh_Hant_referral_json" */), cache: true },
{ key: "../lang/zh-Hant/search.json", load: () => import("../lang/zh-Hant/search.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_zh_Hant_search_json" */), cache: true },
{ key: "../lang/zh-Hant/start.json", load: () => import("../lang/zh-Hant/start.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_zh_Hant_start_json" */), cache: true },
{ key: "../lang/zh-Hant/trustpoilot.json", load: () => import("../lang/zh-Hant/trustpoilot.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_zh_Hant_trustpoilot_json" */), cache: true }],
  "pl": [{ key: "../lang/pl/blog.json", load: () => import("../lang/pl/blog.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_pl_blog_json" */), cache: true },
{ key: "../lang/pl/collaborate.json", load: () => import("../lang/pl/collaborate.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_pl_collaborate_json" */), cache: true },
{ key: "../lang/pl/colorTrackingForm.json", load: () => import("../lang/pl/colorTrackingForm.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_pl_colorTrackingForm_json" */), cache: true },
{ key: "../lang/pl/common.json", load: () => import("../lang/pl/common.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_pl_common_json" */), cache: true },
{ key: "../lang/pl/company.json", load: () => import("../lang/pl/company.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_pl_company_json" */), cache: true },
{ key: "../lang/pl/download.json", load: () => import("../lang/pl/download.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_pl_download_json" */), cache: true },
{ key: "../lang/pl/exchange.json", load: () => import("../lang/pl/exchange.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_pl_exchange_json" */), cache: true },
{ key: "../lang/pl/features.json", load: () => import("../lang/pl/features.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_pl_features_json" */), cache: true },
{ key: "../lang/pl/help.json", load: () => import("../lang/pl/help.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_pl_help_json" */), cache: true },
{ key: "../lang/pl/home.json", load: () => import("../lang/pl/home.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_pl_home_json" */), cache: true },
{ key: "../lang/pl/im.json", load: () => import("../lang/pl/im.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_pl_im_json" */), cache: true },
{ key: "../lang/pl/invitee.json", load: () => import("../lang/pl/invitee.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_pl_invitee_json" */), cache: true },
{ key: "../lang/pl/inviter.json", load: () => import("../lang/pl/inviter.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_pl_inviter_json" */), cache: true },
{ key: "../lang/pl/ipad.json", load: () => import("../lang/pl/ipad.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_pl_ipad_json" */), cache: true },
{ key: "../lang/pl/landing.json", load: () => import("../lang/pl/landing.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_pl_landing_json" */), cache: true },
{ key: "../lang/pl/login.json", load: () => import("../lang/pl/login.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_pl_login_json" */), cache: true },
{ key: "../lang/pl/newHome.json", load: () => import("../lang/pl/newHome.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_pl_newHome_json" */), cache: true },
{ key: "../lang/pl/payment.json", load: () => import("../lang/pl/payment.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_pl_payment_json" */), cache: true },
{ key: "../lang/pl/profile.json", load: () => import("../lang/pl/profile.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_pl_profile_json" */), cache: true },
{ key: "../lang/pl/referral.json", load: () => import("../lang/pl/referral.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_pl_referral_json" */), cache: true },
{ key: "../lang/pl/search.json", load: () => import("../lang/pl/search.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_pl_search_json" */), cache: true },
{ key: "../lang/pl/start.json", load: () => import("../lang/pl/start.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_pl_start_json" */), cache: true },
{ key: "../lang/pl/trustpoilot.json", load: () => import("../lang/pl/trustpoilot.json" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_pl_trustpoilot_json" */), cache: true }]
}

export const vueI18nConfigs = [
  
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [
    {
      "code": "en",
      "iso": "en",
      "files": [
        "lang/en/blog.json",
        "lang/en/collaborate.json",
        "lang/en/colorTrackingForm.json",
        "lang/en/common.json",
        "lang/en/company.json",
        "lang/en/download.json",
        "lang/en/exchange.json",
        "lang/en/features.json",
        "lang/en/help.json",
        "lang/en/home.json",
        "lang/en/im.json",
        "lang/en/invitee.json",
        "lang/en/inviter.json",
        "lang/en/ipad.json",
        "lang/en/landing.json",
        "lang/en/login.json",
        "lang/en/newHome.json",
        "lang/en/payment.json",
        "lang/en/profile.json",
        "lang/en/referral.json",
        "lang/en/search.json",
        "lang/en/start.json",
        "lang/en/trustpoilot.json"
      ]
    },
    {
      "code": "vi",
      "iso": "vi",
      "files": [
        "lang/vi/blog.json",
        "lang/vi/collaborate.json",
        "lang/vi/colorTrackingForm.json",
        "lang/vi/common.json",
        "lang/vi/company.json",
        "lang/vi/download.json",
        "lang/vi/exchange.json",
        "lang/vi/features.json",
        "lang/vi/help.json",
        "lang/vi/home.json",
        "lang/vi/im.json",
        "lang/vi/invitee.json",
        "lang/vi/inviter.json",
        "lang/vi/ipad.json",
        "lang/vi/landing.json",
        "lang/vi/login.json",
        "lang/vi/newHome.json",
        "lang/vi/payment.json",
        "lang/vi/profile.json",
        "lang/vi/referral.json",
        "lang/vi/search.json",
        "lang/vi/start.json",
        "lang/vi/trustpoilot.json"
      ]
    },
    {
      "code": "ko",
      "iso": "ko",
      "files": [
        "lang/ko/blog.json",
        "lang/ko/collaborate.json",
        "lang/ko/colorTrackingForm.json",
        "lang/ko/common.json",
        "lang/ko/company.json",
        "lang/ko/download.json",
        "lang/ko/exchange.json",
        "lang/ko/features.json",
        "lang/ko/help.json",
        "lang/ko/home.json",
        "lang/ko/im.json",
        "lang/ko/invitee.json",
        "lang/ko/inviter.json",
        "lang/ko/ipad.json",
        "lang/ko/landing.json",
        "lang/ko/login.json",
        "lang/ko/newHome.json",
        "lang/ko/payment.json",
        "lang/ko/profile.json",
        "lang/ko/referral.json",
        "lang/ko/search.json",
        "lang/ko/start.json",
        "lang/ko/trustpoilot.json"
      ]
    },
    {
      "code": "ja",
      "iso": "ja",
      "files": [
        "lang/ja/blog.json",
        "lang/ja/collaborate.json",
        "lang/ja/colorTrackingForm.json",
        "lang/ja/common.json",
        "lang/ja/company.json",
        "lang/ja/download.json",
        "lang/ja/exchange.json",
        "lang/ja/features.json",
        "lang/ja/help.json",
        "lang/ja/home.json",
        "lang/ja/im.json",
        "lang/ja/invitee.json",
        "lang/ja/inviter.json",
        "lang/ja/ipad.json",
        "lang/ja/landing.json",
        "lang/ja/login.json",
        "lang/ja/newHome.json",
        "lang/ja/payment.json",
        "lang/ja/profile.json",
        "lang/ja/referral.json",
        "lang/ja/search.json",
        "lang/ja/start.json",
        "lang/ja/trustpoilot.json"
      ]
    },
    {
      "code": "de",
      "iso": "de",
      "files": [
        "lang/de/blog.json",
        "lang/de/collaborate.json",
        "lang/de/colorTrackingForm.json",
        "lang/de/common.json",
        "lang/de/company.json",
        "lang/de/download.json",
        "lang/de/exchange.json",
        "lang/de/features.json",
        "lang/de/help.json",
        "lang/de/home.json",
        "lang/de/im.json",
        "lang/de/invitee.json",
        "lang/de/inviter.json",
        "lang/de/ipad.json",
        "lang/de/landing.json",
        "lang/de/login.json",
        "lang/de/newHome.json",
        "lang/de/payment.json",
        "lang/de/profile.json",
        "lang/de/referral.json",
        "lang/de/search.json",
        "lang/de/start.json",
        "lang/de/trustpoilot.json"
      ]
    },
    {
      "code": "fr",
      "iso": "fr",
      "files": [
        "lang/fr/blog.json",
        "lang/fr/collaborate.json",
        "lang/fr/colorTrackingForm.json",
        "lang/fr/common.json",
        "lang/fr/company.json",
        "lang/fr/download.json",
        "lang/fr/exchange.json",
        "lang/fr/features.json",
        "lang/fr/help.json",
        "lang/fr/home.json",
        "lang/fr/im.json",
        "lang/fr/invitee.json",
        "lang/fr/inviter.json",
        "lang/fr/ipad.json",
        "lang/fr/landing.json",
        "lang/fr/login.json",
        "lang/fr/newHome.json",
        "lang/fr/payment.json",
        "lang/fr/profile.json",
        "lang/fr/referral.json",
        "lang/fr/search.json",
        "lang/fr/start.json",
        "lang/fr/trustpoilot.json"
      ]
    },
    {
      "code": "es",
      "iso": "es",
      "files": [
        "lang/es/blog.json",
        "lang/es/collaborate.json",
        "lang/es/colorTrackingForm.json",
        "lang/es/common.json",
        "lang/es/company.json",
        "lang/es/download.json",
        "lang/es/exchange.json",
        "lang/es/features.json",
        "lang/es/help.json",
        "lang/es/home.json",
        "lang/es/im.json",
        "lang/es/invitee.json",
        "lang/es/inviter.json",
        "lang/es/ipad.json",
        "lang/es/landing.json",
        "lang/es/login.json",
        "lang/es/newHome.json",
        "lang/es/payment.json",
        "lang/es/profile.json",
        "lang/es/referral.json",
        "lang/es/search.json",
        "lang/es/start.json",
        "lang/es/trustpoilot.json"
      ]
    },
    {
      "code": "it",
      "iso": "it",
      "files": [
        "lang/it/blog.json",
        "lang/it/collaborate.json",
        "lang/it/colorTrackingForm.json",
        "lang/it/common.json",
        "lang/it/company.json",
        "lang/it/download.json",
        "lang/it/exchange.json",
        "lang/it/features.json",
        "lang/it/help.json",
        "lang/it/home.json",
        "lang/it/im.json",
        "lang/it/invitee.json",
        "lang/it/inviter.json",
        "lang/it/ipad.json",
        "lang/it/landing.json",
        "lang/it/login.json",
        "lang/it/newHome.json",
        "lang/it/payment.json",
        "lang/it/profile.json",
        "lang/it/referral.json",
        "lang/it/search.json",
        "lang/it/start.json",
        "lang/it/trustpoilot.json"
      ]
    },
    {
      "code": "pt",
      "iso": "pt",
      "files": [
        "lang/pt/blog.json",
        "lang/pt/collaborate.json",
        "lang/pt/colorTrackingForm.json",
        "lang/pt/common.json",
        "lang/pt/company.json",
        "lang/pt/download.json",
        "lang/pt/exchange.json",
        "lang/pt/features.json",
        "lang/pt/help.json",
        "lang/pt/home.json",
        "lang/pt/im.json",
        "lang/pt/invitee.json",
        "lang/pt/inviter.json",
        "lang/pt/ipad.json",
        "lang/pt/landing.json",
        "lang/pt/login.json",
        "lang/pt/newHome.json",
        "lang/pt/payment.json",
        "lang/pt/profile.json",
        "lang/pt/referral.json",
        "lang/pt/search.json",
        "lang/pt/start.json",
        "lang/pt/trustpoilot.json"
      ]
    },
    {
      "code": "es_Es",
      "iso": "es_Es",
      "files": [
        "lang/es_Es/blog.json",
        "lang/es_Es/collaborate.json",
        "lang/es_Es/colorTrackingForm.json",
        "lang/es_Es/common.json",
        "lang/es_Es/company.json",
        "lang/es_Es/download.json",
        "lang/es_Es/exchange.json",
        "lang/es_Es/features.json",
        "lang/es_Es/help.json",
        "lang/es_Es/home.json",
        "lang/es_Es/im.json",
        "lang/es_Es/invitee.json",
        "lang/es_Es/inviter.json",
        "lang/es_Es/ipad.json",
        "lang/es_Es/landing.json",
        "lang/es_Es/login.json",
        "lang/es_Es/newHome.json",
        "lang/es_Es/payment.json",
        "lang/es_Es/profile.json",
        "lang/es_Es/referral.json",
        "lang/es_Es/search.json",
        "lang/es_Es/start.json",
        "lang/es_Es/trustpoilot.json"
      ]
    },
    {
      "code": "zh-Hant",
      "iso": "zh-Hant",
      "files": [
        "lang/zh-Hant/blog.json",
        "lang/zh-Hant/collaborate.json",
        "lang/zh-Hant/colorTrackingForm.json",
        "lang/zh-Hant/common.json",
        "lang/zh-Hant/company.json",
        "lang/zh-Hant/download.json",
        "lang/zh-Hant/exchange.json",
        "lang/zh-Hant/features.json",
        "lang/zh-Hant/help.json",
        "lang/zh-Hant/home.json",
        "lang/zh-Hant/im.json",
        "lang/zh-Hant/invitee.json",
        "lang/zh-Hant/inviter.json",
        "lang/zh-Hant/ipad.json",
        "lang/zh-Hant/landing.json",
        "lang/zh-Hant/login.json",
        "lang/zh-Hant/newHome.json",
        "lang/zh-Hant/payment.json",
        "lang/zh-Hant/profile.json",
        "lang/zh-Hant/referral.json",
        "lang/zh-Hant/search.json",
        "lang/zh-Hant/start.json",
        "lang/zh-Hant/trustpoilot.json"
      ]
    },
    {
      "code": "pl",
      "iso": "pl",
      "files": [
        "lang/pl/blog.json",
        "lang/pl/collaborate.json",
        "lang/pl/colorTrackingForm.json",
        "lang/pl/common.json",
        "lang/pl/company.json",
        "lang/pl/download.json",
        "lang/pl/exchange.json",
        "lang/pl/features.json",
        "lang/pl/help.json",
        "lang/pl/home.json",
        "lang/pl/im.json",
        "lang/pl/invitee.json",
        "lang/pl/inviter.json",
        "lang/pl/ipad.json",
        "lang/pl/landing.json",
        "lang/pl/login.json",
        "lang/pl/newHome.json",
        "lang/pl/payment.json",
        "lang/pl/profile.json",
        "lang/pl/referral.json",
        "lang/pl/search.json",
        "lang/pl/start.json",
        "lang/pl/trustpoilot.json"
      ]
    }
  ],
  "defaultLocale": "en",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": true,
  "langDir": "lang/",
  "detectBrowserLanguage": {
    "alwaysRedirect": true,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "language",
    "cookieSecure": true,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "https://pre.evoto.ai",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "en",
    "iso": "en",
    "files": [
      {
        "path": "lang/en/blog.json"
      },
      {
        "path": "lang/en/collaborate.json"
      },
      {
        "path": "lang/en/colorTrackingForm.json"
      },
      {
        "path": "lang/en/common.json"
      },
      {
        "path": "lang/en/company.json"
      },
      {
        "path": "lang/en/download.json"
      },
      {
        "path": "lang/en/exchange.json"
      },
      {
        "path": "lang/en/features.json"
      },
      {
        "path": "lang/en/help.json"
      },
      {
        "path": "lang/en/home.json"
      },
      {
        "path": "lang/en/im.json"
      },
      {
        "path": "lang/en/invitee.json"
      },
      {
        "path": "lang/en/inviter.json"
      },
      {
        "path": "lang/en/ipad.json"
      },
      {
        "path": "lang/en/landing.json"
      },
      {
        "path": "lang/en/login.json"
      },
      {
        "path": "lang/en/newHome.json"
      },
      {
        "path": "lang/en/payment.json"
      },
      {
        "path": "lang/en/profile.json"
      },
      {
        "path": "lang/en/referral.json"
      },
      {
        "path": "lang/en/search.json"
      },
      {
        "path": "lang/en/start.json"
      },
      {
        "path": "lang/en/trustpoilot.json"
      }
    ]
  },
  {
    "code": "vi",
    "iso": "vi",
    "files": [
      {
        "path": "lang/vi/blog.json"
      },
      {
        "path": "lang/vi/collaborate.json"
      },
      {
        "path": "lang/vi/colorTrackingForm.json"
      },
      {
        "path": "lang/vi/common.json"
      },
      {
        "path": "lang/vi/company.json"
      },
      {
        "path": "lang/vi/download.json"
      },
      {
        "path": "lang/vi/exchange.json"
      },
      {
        "path": "lang/vi/features.json"
      },
      {
        "path": "lang/vi/help.json"
      },
      {
        "path": "lang/vi/home.json"
      },
      {
        "path": "lang/vi/im.json"
      },
      {
        "path": "lang/vi/invitee.json"
      },
      {
        "path": "lang/vi/inviter.json"
      },
      {
        "path": "lang/vi/ipad.json"
      },
      {
        "path": "lang/vi/landing.json"
      },
      {
        "path": "lang/vi/login.json"
      },
      {
        "path": "lang/vi/newHome.json"
      },
      {
        "path": "lang/vi/payment.json"
      },
      {
        "path": "lang/vi/profile.json"
      },
      {
        "path": "lang/vi/referral.json"
      },
      {
        "path": "lang/vi/search.json"
      },
      {
        "path": "lang/vi/start.json"
      },
      {
        "path": "lang/vi/trustpoilot.json"
      }
    ]
  },
  {
    "code": "ko",
    "iso": "ko",
    "files": [
      {
        "path": "lang/ko/blog.json"
      },
      {
        "path": "lang/ko/collaborate.json"
      },
      {
        "path": "lang/ko/colorTrackingForm.json"
      },
      {
        "path": "lang/ko/common.json"
      },
      {
        "path": "lang/ko/company.json"
      },
      {
        "path": "lang/ko/download.json"
      },
      {
        "path": "lang/ko/exchange.json"
      },
      {
        "path": "lang/ko/features.json"
      },
      {
        "path": "lang/ko/help.json"
      },
      {
        "path": "lang/ko/home.json"
      },
      {
        "path": "lang/ko/im.json"
      },
      {
        "path": "lang/ko/invitee.json"
      },
      {
        "path": "lang/ko/inviter.json"
      },
      {
        "path": "lang/ko/ipad.json"
      },
      {
        "path": "lang/ko/landing.json"
      },
      {
        "path": "lang/ko/login.json"
      },
      {
        "path": "lang/ko/newHome.json"
      },
      {
        "path": "lang/ko/payment.json"
      },
      {
        "path": "lang/ko/profile.json"
      },
      {
        "path": "lang/ko/referral.json"
      },
      {
        "path": "lang/ko/search.json"
      },
      {
        "path": "lang/ko/start.json"
      },
      {
        "path": "lang/ko/trustpoilot.json"
      }
    ]
  },
  {
    "code": "ja",
    "iso": "ja",
    "files": [
      {
        "path": "lang/ja/blog.json"
      },
      {
        "path": "lang/ja/collaborate.json"
      },
      {
        "path": "lang/ja/colorTrackingForm.json"
      },
      {
        "path": "lang/ja/common.json"
      },
      {
        "path": "lang/ja/company.json"
      },
      {
        "path": "lang/ja/download.json"
      },
      {
        "path": "lang/ja/exchange.json"
      },
      {
        "path": "lang/ja/features.json"
      },
      {
        "path": "lang/ja/help.json"
      },
      {
        "path": "lang/ja/home.json"
      },
      {
        "path": "lang/ja/im.json"
      },
      {
        "path": "lang/ja/invitee.json"
      },
      {
        "path": "lang/ja/inviter.json"
      },
      {
        "path": "lang/ja/ipad.json"
      },
      {
        "path": "lang/ja/landing.json"
      },
      {
        "path": "lang/ja/login.json"
      },
      {
        "path": "lang/ja/newHome.json"
      },
      {
        "path": "lang/ja/payment.json"
      },
      {
        "path": "lang/ja/profile.json"
      },
      {
        "path": "lang/ja/referral.json"
      },
      {
        "path": "lang/ja/search.json"
      },
      {
        "path": "lang/ja/start.json"
      },
      {
        "path": "lang/ja/trustpoilot.json"
      }
    ]
  },
  {
    "code": "de",
    "iso": "de",
    "files": [
      {
        "path": "lang/de/blog.json"
      },
      {
        "path": "lang/de/collaborate.json"
      },
      {
        "path": "lang/de/colorTrackingForm.json"
      },
      {
        "path": "lang/de/common.json"
      },
      {
        "path": "lang/de/company.json"
      },
      {
        "path": "lang/de/download.json"
      },
      {
        "path": "lang/de/exchange.json"
      },
      {
        "path": "lang/de/features.json"
      },
      {
        "path": "lang/de/help.json"
      },
      {
        "path": "lang/de/home.json"
      },
      {
        "path": "lang/de/im.json"
      },
      {
        "path": "lang/de/invitee.json"
      },
      {
        "path": "lang/de/inviter.json"
      },
      {
        "path": "lang/de/ipad.json"
      },
      {
        "path": "lang/de/landing.json"
      },
      {
        "path": "lang/de/login.json"
      },
      {
        "path": "lang/de/newHome.json"
      },
      {
        "path": "lang/de/payment.json"
      },
      {
        "path": "lang/de/profile.json"
      },
      {
        "path": "lang/de/referral.json"
      },
      {
        "path": "lang/de/search.json"
      },
      {
        "path": "lang/de/start.json"
      },
      {
        "path": "lang/de/trustpoilot.json"
      }
    ]
  },
  {
    "code": "fr",
    "iso": "fr",
    "files": [
      {
        "path": "lang/fr/blog.json"
      },
      {
        "path": "lang/fr/collaborate.json"
      },
      {
        "path": "lang/fr/colorTrackingForm.json"
      },
      {
        "path": "lang/fr/common.json"
      },
      {
        "path": "lang/fr/company.json"
      },
      {
        "path": "lang/fr/download.json"
      },
      {
        "path": "lang/fr/exchange.json"
      },
      {
        "path": "lang/fr/features.json"
      },
      {
        "path": "lang/fr/help.json"
      },
      {
        "path": "lang/fr/home.json"
      },
      {
        "path": "lang/fr/im.json"
      },
      {
        "path": "lang/fr/invitee.json"
      },
      {
        "path": "lang/fr/inviter.json"
      },
      {
        "path": "lang/fr/ipad.json"
      },
      {
        "path": "lang/fr/landing.json"
      },
      {
        "path": "lang/fr/login.json"
      },
      {
        "path": "lang/fr/newHome.json"
      },
      {
        "path": "lang/fr/payment.json"
      },
      {
        "path": "lang/fr/profile.json"
      },
      {
        "path": "lang/fr/referral.json"
      },
      {
        "path": "lang/fr/search.json"
      },
      {
        "path": "lang/fr/start.json"
      },
      {
        "path": "lang/fr/trustpoilot.json"
      }
    ]
  },
  {
    "code": "es",
    "iso": "es",
    "files": [
      {
        "path": "lang/es/blog.json"
      },
      {
        "path": "lang/es/collaborate.json"
      },
      {
        "path": "lang/es/colorTrackingForm.json"
      },
      {
        "path": "lang/es/common.json"
      },
      {
        "path": "lang/es/company.json"
      },
      {
        "path": "lang/es/download.json"
      },
      {
        "path": "lang/es/exchange.json"
      },
      {
        "path": "lang/es/features.json"
      },
      {
        "path": "lang/es/help.json"
      },
      {
        "path": "lang/es/home.json"
      },
      {
        "path": "lang/es/im.json"
      },
      {
        "path": "lang/es/invitee.json"
      },
      {
        "path": "lang/es/inviter.json"
      },
      {
        "path": "lang/es/ipad.json"
      },
      {
        "path": "lang/es/landing.json"
      },
      {
        "path": "lang/es/login.json"
      },
      {
        "path": "lang/es/newHome.json"
      },
      {
        "path": "lang/es/payment.json"
      },
      {
        "path": "lang/es/profile.json"
      },
      {
        "path": "lang/es/referral.json"
      },
      {
        "path": "lang/es/search.json"
      },
      {
        "path": "lang/es/start.json"
      },
      {
        "path": "lang/es/trustpoilot.json"
      }
    ]
  },
  {
    "code": "it",
    "iso": "it",
    "files": [
      {
        "path": "lang/it/blog.json"
      },
      {
        "path": "lang/it/collaborate.json"
      },
      {
        "path": "lang/it/colorTrackingForm.json"
      },
      {
        "path": "lang/it/common.json"
      },
      {
        "path": "lang/it/company.json"
      },
      {
        "path": "lang/it/download.json"
      },
      {
        "path": "lang/it/exchange.json"
      },
      {
        "path": "lang/it/features.json"
      },
      {
        "path": "lang/it/help.json"
      },
      {
        "path": "lang/it/home.json"
      },
      {
        "path": "lang/it/im.json"
      },
      {
        "path": "lang/it/invitee.json"
      },
      {
        "path": "lang/it/inviter.json"
      },
      {
        "path": "lang/it/ipad.json"
      },
      {
        "path": "lang/it/landing.json"
      },
      {
        "path": "lang/it/login.json"
      },
      {
        "path": "lang/it/newHome.json"
      },
      {
        "path": "lang/it/payment.json"
      },
      {
        "path": "lang/it/profile.json"
      },
      {
        "path": "lang/it/referral.json"
      },
      {
        "path": "lang/it/search.json"
      },
      {
        "path": "lang/it/start.json"
      },
      {
        "path": "lang/it/trustpoilot.json"
      }
    ]
  },
  {
    "code": "pt",
    "iso": "pt",
    "files": [
      {
        "path": "lang/pt/blog.json"
      },
      {
        "path": "lang/pt/collaborate.json"
      },
      {
        "path": "lang/pt/colorTrackingForm.json"
      },
      {
        "path": "lang/pt/common.json"
      },
      {
        "path": "lang/pt/company.json"
      },
      {
        "path": "lang/pt/download.json"
      },
      {
        "path": "lang/pt/exchange.json"
      },
      {
        "path": "lang/pt/features.json"
      },
      {
        "path": "lang/pt/help.json"
      },
      {
        "path": "lang/pt/home.json"
      },
      {
        "path": "lang/pt/im.json"
      },
      {
        "path": "lang/pt/invitee.json"
      },
      {
        "path": "lang/pt/inviter.json"
      },
      {
        "path": "lang/pt/ipad.json"
      },
      {
        "path": "lang/pt/landing.json"
      },
      {
        "path": "lang/pt/login.json"
      },
      {
        "path": "lang/pt/newHome.json"
      },
      {
        "path": "lang/pt/payment.json"
      },
      {
        "path": "lang/pt/profile.json"
      },
      {
        "path": "lang/pt/referral.json"
      },
      {
        "path": "lang/pt/search.json"
      },
      {
        "path": "lang/pt/start.json"
      },
      {
        "path": "lang/pt/trustpoilot.json"
      }
    ]
  },
  {
    "code": "es_Es",
    "iso": "es_Es",
    "files": [
      {
        "path": "lang/es_Es/blog.json"
      },
      {
        "path": "lang/es_Es/collaborate.json"
      },
      {
        "path": "lang/es_Es/colorTrackingForm.json"
      },
      {
        "path": "lang/es_Es/common.json"
      },
      {
        "path": "lang/es_Es/company.json"
      },
      {
        "path": "lang/es_Es/download.json"
      },
      {
        "path": "lang/es_Es/exchange.json"
      },
      {
        "path": "lang/es_Es/features.json"
      },
      {
        "path": "lang/es_Es/help.json"
      },
      {
        "path": "lang/es_Es/home.json"
      },
      {
        "path": "lang/es_Es/im.json"
      },
      {
        "path": "lang/es_Es/invitee.json"
      },
      {
        "path": "lang/es_Es/inviter.json"
      },
      {
        "path": "lang/es_Es/ipad.json"
      },
      {
        "path": "lang/es_Es/landing.json"
      },
      {
        "path": "lang/es_Es/login.json"
      },
      {
        "path": "lang/es_Es/newHome.json"
      },
      {
        "path": "lang/es_Es/payment.json"
      },
      {
        "path": "lang/es_Es/profile.json"
      },
      {
        "path": "lang/es_Es/referral.json"
      },
      {
        "path": "lang/es_Es/search.json"
      },
      {
        "path": "lang/es_Es/start.json"
      },
      {
        "path": "lang/es_Es/trustpoilot.json"
      }
    ]
  },
  {
    "code": "zh-Hant",
    "iso": "zh-Hant",
    "files": [
      {
        "path": "lang/zh-Hant/blog.json"
      },
      {
        "path": "lang/zh-Hant/collaborate.json"
      },
      {
        "path": "lang/zh-Hant/colorTrackingForm.json"
      },
      {
        "path": "lang/zh-Hant/common.json"
      },
      {
        "path": "lang/zh-Hant/company.json"
      },
      {
        "path": "lang/zh-Hant/download.json"
      },
      {
        "path": "lang/zh-Hant/exchange.json"
      },
      {
        "path": "lang/zh-Hant/features.json"
      },
      {
        "path": "lang/zh-Hant/help.json"
      },
      {
        "path": "lang/zh-Hant/home.json"
      },
      {
        "path": "lang/zh-Hant/im.json"
      },
      {
        "path": "lang/zh-Hant/invitee.json"
      },
      {
        "path": "lang/zh-Hant/inviter.json"
      },
      {
        "path": "lang/zh-Hant/ipad.json"
      },
      {
        "path": "lang/zh-Hant/landing.json"
      },
      {
        "path": "lang/zh-Hant/login.json"
      },
      {
        "path": "lang/zh-Hant/newHome.json"
      },
      {
        "path": "lang/zh-Hant/payment.json"
      },
      {
        "path": "lang/zh-Hant/profile.json"
      },
      {
        "path": "lang/zh-Hant/referral.json"
      },
      {
        "path": "lang/zh-Hant/search.json"
      },
      {
        "path": "lang/zh-Hant/start.json"
      },
      {
        "path": "lang/zh-Hant/trustpoilot.json"
      }
    ]
  },
  {
    "code": "pl",
    "iso": "pl",
    "files": [
      {
        "path": "lang/pl/blog.json"
      },
      {
        "path": "lang/pl/collaborate.json"
      },
      {
        "path": "lang/pl/colorTrackingForm.json"
      },
      {
        "path": "lang/pl/common.json"
      },
      {
        "path": "lang/pl/company.json"
      },
      {
        "path": "lang/pl/download.json"
      },
      {
        "path": "lang/pl/exchange.json"
      },
      {
        "path": "lang/pl/features.json"
      },
      {
        "path": "lang/pl/help.json"
      },
      {
        "path": "lang/pl/home.json"
      },
      {
        "path": "lang/pl/im.json"
      },
      {
        "path": "lang/pl/invitee.json"
      },
      {
        "path": "lang/pl/inviter.json"
      },
      {
        "path": "lang/pl/ipad.json"
      },
      {
        "path": "lang/pl/landing.json"
      },
      {
        "path": "lang/pl/login.json"
      },
      {
        "path": "lang/pl/newHome.json"
      },
      {
        "path": "lang/pl/payment.json"
      },
      {
        "path": "lang/pl/profile.json"
      },
      {
        "path": "lang/pl/referral.json"
      },
      {
        "path": "lang/pl/search.json"
      },
      {
        "path": "lang/pl/start.json"
      },
      {
        "path": "lang/pl/trustpoilot.json"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
