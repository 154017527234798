<template>
  <div class="upgrade-dialog-wrapper">
    <div class="upgrade-dialog-wrapper-left-control">
      <div class="curren-plan-dialog-wrapper">
        <div class="title">{{ useTranslateI18n("payment.upgradeDialog.currentPlan") }}</div>
        <div class="name">{{ current_plan.name }}</div>
        <div class="period">
          {{ useTranslateI18n("payment.upgradeDialog.period", [currentPeriod]) }}
        </div>
        <div class="menu-item">
          <span class="text-1">
            {{ useTranslateI18n("payment.comboSub.t40") }}
          </span>
          <span class="text-2"> {{ current_plan.device_limit }} </span>
        </div>

        <div class="menu-item">
          <span class="text-1">
            {{ useTranslateI18n(`payment.paddleDialog.credits`, [useNumFormat(current_plan.credits)]) }}
          </span>
          <span class="text-2">
            {{
              useTranslateI18n("payment.topCard[1].desc", [
                current_plan.u_discount_per_credit_price || current_plan.singlePrice
              ])
            }}
          </span>
        </div>

        <div class="menu-item">
          <span class="text-1">
            {{ useTranslateI18n("payment.upgradeDialog.rCredits") }}
          </span>
          <span class="text-2"> {{ use_user.subscription_info_v2_web.sub_left_count }} </span>
        </div>

        <span class="tip-fl">
          {{ useTranslateI18n("payment.comboSub.t42") }}
        </span>
      </div>
    </div>
    <div class="upgrade-line-fl"></div>
    <div class="upgrade-dialog-wrapper-right-show">
      <div :class="['upgrade-plan-dialog-wrapper']">
        <div class="title">
          {{ useTranslateI18n("payment.comboSub.t75") }}
        </div>
        <div class="name">{{ next_plan.name }}</div>
        <div class="period">
          {{ useTranslateI18n("payment.upgradeDialog.period", [nextPeriod]) }}
        </div>
        <div class="menu-item">
          <span class="text-1">
            {{ useTranslateI18n("payment.comboSub.t40") }}
          </span>
          <span class="text-2"> {{ next_plan.device_limit }} </span>
        </div>
        <div class="menu-item">
          <span class="text-1">
            {{ useTranslateI18n("payment.account") }}
          </span>
          <span class="text-2"> {{ userEmail }} </span>
        </div>

        <div class="menu-item">
          <span class="text-1">
            {{ useTranslateI18n(`payment.paddleDialog.credits`, [useNumFormat(next_plan.credits)]) }}
          </span>
          <span v-if="priceDetail.discount" class="text-2">
            {{ useTranslateI18n("payment.topCard[1].desc", [priceDetail.perMoney]) }}
          </span>
          <span v-if="!priceDetail.discount" class="text-2">
            {{ useTranslateI18n("payment.topCard[1].desc", [next_plan.singlePrice]) }}
          </span>
        </div>

        <!-- 原总价 -->
        <div class="menu-item menu-item-total">
          <span class="text-1"> {{ useTranslateI18n("payment.buyDataPkgDialog.total") }} </span>
          <span class="text-2 text-n"> {{ next_plan.price }} </span>
        </div>

        <div v-if="priceDetail.discount" class="menu-item menu-item-save-discount">
          <div class="total-c">
            <CloseFl />
            <span class="total-c-co">
              {{ coupon_code }}
            </span>
            <Close v-if="showCloseIcon" class="total-c-cl" @click="closeCoupon" />
          </div>
          <div class="one-c">
            <span class="num">-{{ priceDetail.discount }}</span>
          </div>
        </div>

        <div v-if="priceDetail.discount" class="menu-item menu-item-sub-total">
          <span class="text-1"> {{ useTranslateI18n(`payment.paddleDialog.total1`) }} </span>
          <span class="text-2 text-n"> {{ priceDetail.total }} </span>
        </div>

        <!-- <div class="menu-item menu-item-after-offer">
          <span class="text-1">
            {{ useTranslateI18n("payment.comboSub.t52", [getLocalDateString(next_plan.period_end, "YYYY-MM-DD")]) }}
          </span>
          <span class="text-2 text-n"> {{ next_plan.price }} </span>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ICardDetailData } from "@/types/components/pages/combo/comboCardT"
import { useUser } from "@/store/user"
import CloseFl from "~~/assets/icons/common/payment-c-icon.svg"
import Close from "~~/assets/icons/common/payment-close.svg"

let use_user = useUser()

interface IProps {
  showCloseIcon?: boolean
  coupon_code?: string
  current_plan: ICardDetailData & INewComboProductListObj
  next_plan: ICardDetailData & INewComboProductListObj
  priceDetail: {
    total: string
    perMoney: string
    top_up_discount: string
    top_up_percentage: string
    discount: string
    percentage: string
  }
  email?: string
}
const props = withDefaults(defineProps<IProps>(), {
  showCloseIcon: () => true,
  current_plan: () => {
    return {} as ICardDetailData & INewComboProductListObj
  },
  next_plan: () => {
    return {} as ICardDetailData & INewComboProductListObj
  },
  email: () => "",
  priceDetail: () =>
    ({} as {
      total: string
      perMoney: string
      top_up_discount: string
      top_up_percentage: string
      discount: string
      percentage: string
    }),
  coupon_code: () => ""
})

const emits = defineEmits(["closeCoupon"])

const closeCoupon = () => {
  emits("closeCoupon")
}

const currentPeriod = computed(() => {
  return `${getLocalDateString(props.current_plan.period_start, "YYYY-MM-DD")} - ${getLocalDateString(
    props.current_plan.period_end,
    "YYYY-MM-DD"
  )}`
})

const nextPeriod = computed(() => {
  return `${getLocalDateString(props.next_plan.period_start, "YYYY-MM-DD")} - ${getLocalDateString(
    props.next_plan.period_end,
    "YYYY-MM-DD"
  )}`
})

const userEmail = computed(() => {
  return props.next_plan.email
})
</script>

<style scoped lang="scss">
.upgrade-dialog-wrapper {
  line-height: normal;
  color: #000;
  display: flex;
  flex-direction: column;
  .upgrade-dialog-wrapper-left-control {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .curren-plan-dialog-wrapper {
      display: flex;
      flex-direction: column;
      &-border {
        border-bottom: none;
      }
      .title {
        font-size: 14px;
        margin-bottom: 8px;
      }
      .name {
        font-size: 24px;
        @include fontSemibold;
        margin-bottom: 24px;
      }
      .period {
        font-size: 14px;
      }
      .menu-item {
        display: flex;
        margin-top: 8px;
        justify-content: space-between;
        align-items: center;
        .text-1 {
          font-size: 14px;
        }
        .text-2 {
          font-size: 16px;
          @include fontSemibold;
          word-break: break-all;
        }
        .text-n {
          color: #d25f00;
        }

        &.menu-item-sub-total {
          .text-2 {
            color: #000;
          }
        }
        &.menu-item-total {
          .text-2 {
            color: #000;
          }
        }
        &.menu-item-save-discount {
          .one-c {
            .num {
              color: #d25f00;
              @include fontSemibold;
              font-size: 14px;
            }
          }
          .total-c {
            padding: 4px;
            border-radius: 6px;
            background: #fff7e8;
            display: flex;
            align-items: center;
            .total-c-co {
              font-size: 14px;
              color: #d25f00;
              font-family: "Roboto-Medium";
              margin: 0 4px;
            }
            .total-c-cl {
              cursor: pointer;
            }
          }
        }
      }
      .menu-item-after-offer {
        margin-top: 18px;
        border-top: 0.5px solid rgba(0, 0, 0, 0.15);
        padding-top: 18px;
      }
      .tip-fl {
        margin-top: 5px;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.55);
        @include fontMedium;
      }
      .tip-r {
        display: flex;
        justify-content: flex-end;
      }
    }
  }

  .upgrade-line-fl {
    margin: 24px 0;
    height: 0.5px;
    background: rgba(0, 0, 0, 0.15);
  }

  .upgrade-dialog-wrapper-right-show {
    display: flex;
    flex-direction: column;
    .upgrade-plan-dialog-wrapper {
      display: flex;
      flex-direction: column;
      padding-bottom: 16px;
      border-bottom: 0.5px solid rgba(0, 0, 0, 0.15);
      &-border {
        border-bottom: none;
      }
      .title {
        font-size: 14px;
        margin-bottom: 8px;
      }
      .name {
        font-size: 24px;
        @include fontSemibold;
        margin-bottom: 24px;
      }
      .period {
        font-size: 14px;
      }
      .menu-item {
        display: flex;
        margin-top: 8px;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        .text-1 {
          font-size: 14px;
        }
        .text-2 {
          font-size: 16px;
          @include fontSemibold;
          word-break: break-all;
        }
        .text-n {
          color: #d25f00;
        }

        &.menu-item-sub-total {
          .text-2 {
            color: #000;
          }
        }
        &.menu-item-total {
          .text-2 {
            color: #000;
          }
        }
        &.menu-item-save-discount {
          .one-c {
            .num {
              color: #d25f00;
              @include fontSemibold;
              font-size: 14px;
            }
          }
          .total-c {
            padding: 4px;
            border-radius: 6px;
            background: #fff7e8;
            display: flex;
            align-items: center;
            .total-c-co {
              font-size: 14px;
              color: #d25f00;
              font-family: "Roboto-Medium";
              margin: 0 4px;
            }
            .total-c-cl {
              cursor: pointer;
            }
          }
        }
      }
      .menu-item-after-offer {
        margin-top: 18px;
        border-top: 0.5px solid rgba(0, 0, 0, 0.15);
        padding-top: 18px;
      }
      .tip-fl {
        margin-top: 5px;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.55);
        @include fontMedium;
      }
      .tip-r {
        display: flex;
        justify-content: flex-end;
      }
    }

    .discount-input {
      margin-top: 40px;
      margin-bottom: 40px;
      flex-direction: column;
      display: flex;
      visibility: hidden;
      &-display {
        visibility: visible;
      }

      .add-discount {
        font-size: 16px;
        line-height: 19px;
        margin-bottom: 10px;
      }
      .error-tip {
        margin-top: 10px;
        font-size: 12px;
        line-height: 14px;
        color: #fc5555;
      }
      :deep(.el-input) {
        height: 36px;
        .el-input__wrapper {
          border: 1px solid rgba(0, 0, 0, 0.3);
          border-radius: 8px;
          box-shadow: none;
          .el-input__inner {
            color: #000;
          }
        }
      }
    }
    &__bottom_control {
      display: flex;
      flex-direction: column;
      align-items: center;
      .bottom_control_btn {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
      }
      .add-discount-wrapper {
        margin-top: 17px;
      }
      .add-discount {
        font-size: 16px;
        line-height: 19px;
        color: #3083ff;
        text-decoration: underline;
        cursor: pointer;
      }
      .cancel-btn {
        margin-top: 10px;
      }
    }
  }
}

@include device-max-width-custom(970px) {
  .upgrade-dialog-wrapper {
    flex-direction: column;
    min-height: unset;
    > div {
      width: 100%;
    }
    .upgrade-dialog-wrapper-left-control {
      border-right: 0;
      padding-right: 0;
    }
    .upgrade-dialog-wrapper-right-show {
      padding-left: 0;
      .discount-input {
        margin: 20px 0;
      }
    }
  }
}
</style>
