import {
  AdvantageType,
  CommingActivityType,
  FeatureType,
  KolsSayType,
  KolsType,
  SceneType,
  SimplifyLifeType,
  UsersSayType,
  communityType
} from "~/types/pages/newHome"
import SvgIconV1 from "@/assets/images/pages/newHome/home-simplify-life-tab-icon-1.svg"
import SvgIconV2 from "@/assets/images/pages/newHome/home-simplify-life-tab-icon-2.svg"
import SvgIconV3 from "@/assets/images/pages/newHome/home-simplify-life-tab-icon-3.svg"
import { ILocaleValue } from "../i18n-country"
import { BATCH_EDITS, COLOR_AND_TONE, PORTRAIT_RETOUCHING } from "../route"
import { dateRplace } from "~/utils/pages/newHome"

export const HERO_ID = "hero_id"

export const SIMOLIFY_LIFE_ID = "simolify_life_id"

export const ADVANTAGE_ID = "advantage_id"

export const BRANK_ID = "brank_id"

export const KOL_ID = "kol_id"

export const SCENE_ID = "scene_id"

export const Breakpoints = {
  $375: 376,
  $600: 601,
  $900: 901,
  $1200: 1201,
  $1400: 1401,
  $1920: 1921
}

export const getSimplifyLifeData: () => Array<SimplifyLifeType> = () => {
  return [
    {
      icon: SvgIconV1,
      tabName: useTranslateI18n("newHome.simplifyLife.videoInfo[0].title"),
      activityColor: "#D66D0D",
      activityBackground: "#FEF5E7",
      desc: useTranslateI18n("newHome.simplifyLife.videoInfo[0].desc"),
      video: getCloudAssets("/images/pages/newHome/home-simplify-life-video-1.mp4"),
      poster: getCloudAssets("/images/pages/newHome/home-simplify-life-video-1-poster.webp"),
      alt: useTranslateI18n("newHome.simplifyLife.videoInfo[0].alt")
    },
    {
      icon: SvgIconV2,
      tabName: useTranslateI18n("newHome.simplifyLife.videoInfo[1].title"),
      activityColor: "#59C864",
      activityBackground: "#E7FEEB",
      desc: useTranslateI18n("newHome.simplifyLife.videoInfo[1].desc"),
      video: getCloudAssets("/images/pages/newHome/home-simplify-life-video-2.mp4"),
      poster: getCloudAssets("/images/pages/newHome/home-simplify-life-video-2-poster.webp"),
      alt: useTranslateI18n("newHome.simplifyLife.videoInfo[1].alt")
    },
    {
      icon: SvgIconV3,
      tabName: useTranslateI18n("newHome.simplifyLife.videoInfo[2].title"),
      activityColor: "#1967D2",
      activityBackground: "#E7F0FE",
      desc: useTranslateI18n("newHome.simplifyLife.videoInfo[2].desc"),
      video: getCloudAssets("/images/pages/newHome/home-simplify-life-video-3.mp4"),
      poster: getCloudAssets("/images/pages/newHome/home-simplify-life-video-3-poster.webp"),
      alt: useTranslateI18n("newHome.simplifyLife.videoInfo[2].alt")
    }
  ]
}

export const getAdvantageData: () => Array<AdvantageType> = () => {
  return [
    {
      tabName: useTranslateI18n("newHome.advantage.tabs[0].tabName"),
      desc: useTranslateI18n("newHome.advantage.tabs[0].desc"),
      value: 78,
      video: getCloudAssets("/images/pages/newHome/home-advantage-video-1.mp4"),
      poster: getCloudAssets("/images/pages/newHome/home-advantage-video-1-poster.webp"),
      topText: useTranslateI18n("newHome.advantage.tabs[0].topText"),
      bottomText: useTranslateI18n("newHome.advantage.tabs[0].bottomText"),
      alt: useTranslateI18n("newHome.advantage.tabs[0].alt")
    },
    {
      tabName: useTranslateI18n("newHome.advantage.tabs[1].tabName"),
      desc: useTranslateI18n("newHome.advantage.tabs[1].desc"),
      value: 64,
      video: getCloudAssets("/images/pages/newHome/home-advantage-video-2-v2.mp4"),
      poster: getCloudAssets("/images/pages/newHome/home-advantage-video-2-poster-v2.webp"),
      topText: useTranslateI18n("newHome.advantage.tabs[1].topText"),
      bottomText: useTranslateI18n("newHome.advantage.tabs[1].bottomText"),
      alt: useTranslateI18n("newHome.advantage.tabs[1].alt")
    },
    {
      tabName: useTranslateI18n("newHome.advantage.tabs[2].tabName"),
      desc: useTranslateI18n("newHome.advantage.tabs[2].desc"),
      value: 39,
      video: getCloudAssets("/images/pages/newHome/home-advantage-video-3.mp4"),
      poster: getCloudAssets("/images/pages/newHome/home-advantage-video-3-poster.webp"),
      topText: useTranslateI18n("newHome.advantage.tabs[2].topText"),
      bottomText: useTranslateI18n("newHome.advantage.tabs[2].bottomText"),
      alt: useTranslateI18n("newHome.advantage.tabs[2].alt")
    },
    {
      tabName: useTranslateI18n("newHome.advantage.tabs[3].tabName"),
      desc: useTranslateI18n("newHome.advantage.tabs[3].desc"),
      value: 243,
      video: getCloudAssets("/images/pages/newHome/home-advantage-video-4.mp4"),
      poster: getCloudAssets("/images/pages/newHome/home-advantage-video-4-poster.webp"),
      topText: useTranslateI18n("newHome.advantage.tabs[3].topText"),
      bottomText: useTranslateI18n("newHome.advantage.tabs[3].bottomText"),
      alt: useTranslateI18n("newHome.advantage.tabs[3].alt")
    }
  ]
}

export const getBrankData: () => Array<string> = () => {
  return [
    getCloudAssets("/images/pages/newHome/home-brand-img-1.png"),
    getCloudAssets("/images/pages/newHome/home-brand-img-2.png"),
    getCloudAssets("/images/pages/newHome/home-brand-img-3.png"),
    getCloudAssets("/images/pages/newHome/home-brand-img-4.png"),
    getCloudAssets("/images/pages/newHome/home-brand-img-5.png")
  ]
}

export const getSceneData: () => Array<SceneType> = () => {
  return [
    {
      tabTitle: useTranslateI18n("newHome.scene.tabText[0].title"),
      tabDesc: useTranslateI18n("newHome.scene.tabText[0].desc"),
      tabImg: getCloudAssets("/images/pages/newHome/home-scene-tab-1.webp"),
      oriImg: getCloudAssets("/images/pages/newHome/home-scene-1-before.webp"),
      alt: useTranslateI18n("newHome.scene.tabText[0].alt"),
      effectList: [
        {
          img: getCloudAssets("/images/pages/newHome/home-scene-1-after-1.webp"),
          title: useTranslateI18n("newHome.scene.hoverText[0][0].title"),
          desc: useTranslateI18n("newHome.scene.hoverText[0][0].desc"),
          left: "18%",
          top: "61%"
        },
        {
          img: getCloudAssets("/images/pages/newHome/home-scene-1-after-2.webp"),
          title: useTranslateI18n("newHome.scene.hoverText[0][1].title"),
          desc: useTranslateI18n("newHome.scene.hoverText[0][1].desc"),
          left: "52%",
          top: "14%"
        }
      ]
    },
    {
      tabTitle: useTranslateI18n("newHome.scene.tabText[1].title"),
      tabDesc: useTranslateI18n("newHome.scene.tabText[1].desc"),
      tabImg: getCloudAssets("/images/pages/newHome/home-scene-tab-2.webp"),
      oriImg: getCloudAssets("/images/pages/newHome/home-scene-2-before-v2.webp"),
      alt: useTranslateI18n("newHome.scene.tabText[1].alt"),
      effectList: [
        {
          img: getCloudAssets("/images/pages/newHome/home-scene-2-after-1-v2.webp"),
          title: useTranslateI18n("newHome.scene.hoverText[1][0].title"),
          desc: useTranslateI18n("newHome.scene.hoverText[1][0].desc"),
          left: "9.3%",
          top: "9.5%"
        },
        {
          img: getCloudAssets("/images/pages/newHome/home-scene-2-after-2-v2.webp"),
          title: useTranslateI18n("newHome.scene.hoverText[1][1].title"),
          desc: useTranslateI18n("newHome.scene.hoverText[1][1].desc"),
          left: "61.585%",
          top: "53.92%"
        }
      ]
    },
    {
      tabTitle: useTranslateI18n("newHome.scene.tabText[2].title"),
      tabDesc: useTranslateI18n("newHome.scene.tabText[2].desc"),
      tabImg: getCloudAssets("/images/pages/newHome/home-scene-tab-3-v2.webp"),
      oriImg: getCloudAssets("/images/pages/newHome/home-scene-3-before-v2.webp"),
      alt: useTranslateI18n("newHome.scene.tabText[2].alt"),
      effectList: [
        {
          img: getCloudAssets("/images/pages/newHome/home-scene-3-after-1-v2.webp"),
          title: useTranslateI18n("newHome.scene.hoverText[2][0].title"),
          desc: useTranslateI18n("newHome.scene.hoverText[2][0].desc"),
          left: "14.02%",
          top: "36.49%",
          brightness: 0.7
        },
        {
          img: getCloudAssets("/images/pages/newHome/home-scene-3-after-2-v2.webp"),
          title: useTranslateI18n("newHome.scene.hoverText[2][1].title"),
          desc: useTranslateI18n("newHome.scene.hoverText[2][1].desc"),
          left: "62.67%",
          top: "16.05%",
          brightness: 0.7
        }
      ]
    },
    {
      tabTitle: useTranslateI18n("newHome.scene.tabText[3].title"),
      tabDesc: useTranslateI18n("newHome.scene.tabText[3].desc"),
      tabImg: getCloudAssets("/images/pages/newHome/home-scene-tab-4.webp"),
      oriImg: getCloudAssets("/images/pages/newHome/home-scene-4-before.webp"),
      alt: useTranslateI18n("newHome.scene.tabText[3].alt"),
      effectList: [
        {
          img: getCloudAssets("/images/pages/newHome/home-scene-4-after-1.webp"),
          title: useTranslateI18n("newHome.scene.hoverText[3][0].title"),
          desc: useTranslateI18n("newHome.scene.hoverText[3][0].desc"),
          left: "4.5%",
          top: "50%"
        },
        {
          img: getCloudAssets("/images/pages/newHome/home-scene-4-after-2.webp"),
          title: useTranslateI18n("newHome.scene.hoverText[3][1].title"),
          desc: useTranslateI18n("newHome.scene.hoverText[3][1].desc"),
          left: "53.05%",
          top: "10.676%"
        }
      ]
    }
  ]
}

export const getFeatureData: () => Array<FeatureType> = () => {
  return [
    {
      title: useTranslateI18n("newHome.feature.cardList[0].title", [
        {
          type: "static",
          class: "green-text"
        },
        {
          class: "icon",
          type: "img",
          src: getCloudAssets("/images/pages/newHome/home-feature-icon-1.png"),
          alt: "AI photo editor"
        }
      ]),
      desc: useTranslateI18n("newHome.feature.cardList[0].desc"),
      video: getCloudAssets("/images/pages/newHome/home-feature-video-1.mp4"),
      poster: getCloudAssets("/images/pages/newHome/home-feature-video-1-poster.webp"),
      alt: useTranslateI18n("newHome.feature.cardList[0].alt")
    },
    {
      title: useTranslateI18n("newHome.feature.cardList[1].title", [
        {
          class: "blue-text",
          type: "static"
        },
        {
          type: "img",
          class: "icon",
          src: getCloudAssets("/images/pages/newHome/home-feature-icon-2.png"),
          alt: "AI photo editor"
        }
      ]),
      desc: useTranslateI18n("newHome.feature.cardList[1].desc"),
      video: getCloudAssets("/images/pages/newHome/home-feature-video-2.mp4"),
      poster: getCloudAssets("/images/pages/newHome/home-feature-video-2-poster.webp"),
      alt: useTranslateI18n("newHome.feature.cardList[1].alt"),
      path: COLOR_AND_TONE.value
    },
    {
      title: useTranslateI18n("newHome.feature.cardList[2].title", [
        {
          class: "orange-text",
          type: "static"
        },
        {
          type: "img",
          class: "icon",
          src: getCloudAssets("/images/pages/newHome/home-feature-icon-3.png"),
          alt: "AI photo editor"
        }
      ]),
      desc: useTranslateI18n("newHome.feature.cardList[2].desc"),
      video: getCloudAssets("/images/pages/newHome/home-feature-video-3.mp4"),
      poster: getCloudAssets("/images/pages/newHome/home-feature-video-3-poster.webp"),
      alt: useTranslateI18n("newHome.feature.cardList[2].alt"),
      path: PORTRAIT_RETOUCHING.value
    },
    {
      title: useTranslateI18n("newHome.feature.cardList[3].title", [
        {
          class: "red-text",
          type: "static"
        },
        {
          type: "img",
          class: "icon",
          src: getCloudAssets("/images/pages/newHome/home-feature-icon-4.png"),
          alt: "AI photo editor"
        }
      ]),
      desc: useTranslateI18n("newHome.feature.cardList[3].desc"),
      video: getCloudAssets("/images/pages/newHome/home-feature-video-4.mp4"),
      poster: getCloudAssets("/images/pages/newHome/home-feature-video-4-poster.webp"),
      alt: useTranslateI18n("newHome.feature.cardList[3].alt"),
      path: BATCH_EDITS.value
    }
  ]
}

export const getCommunityData: () => communityType = () => {
  return {
    cardList: [
      {
        title: useTranslateI18n("newHome.community.cardList[0]"),
        value: 50
      },
      {
        title: useTranslateI18n("newHome.community.cardList[1]"),
        value: 50,
        valueUnit: useTranslateI18n("newHome.community.unit")
      },
      {
        title: useTranslateI18n("newHome.community.cardList[2]"),
        value: 100
      },
      {
        title: useTranslateI18n("newHome.community.cardList[3]"),
        value: 750,
        valueUnit: useTranslateI18n("newHome.community.unit")
      }
    ],
    usersSayList: {
      [ILocaleValue.en]: getEnUsersSayList(),
      [ILocaleValue.de]: dateRplace(getDeUsersSayList(), getEnUsersSayList()),
      [ILocaleValue.es]: dateRplace(getEsUsersSayList(), getEnUsersSayList()),
      [ILocaleValue.es_Es]: dateRplace(getEsEsUsersSayList(), getEnUsersSayList()),
      [ILocaleValue.fr]: dateRplace(getFrUsersSayList(), getEnUsersSayList()),
      [ILocaleValue.it]: dateRplace(getItUsersSayList(), getEnUsersSayList()),
      [ILocaleValue.ja]: dateRplace(getJaUsersSayList(), getEnUsersSayList()),
      [ILocaleValue.ko]: dateRplace(getKoUsersSayList(), getEnUsersSayList()),
      [ILocaleValue.pt]: dateRplace(getPtUsersSayList(), getEnUsersSayList()),
      [ILocaleValue.vi]: dateRplace(getViUsersSayList(), getEnUsersSayList()),
      [ILocaleValue["zh-Hant"]]: dateRplace(getZhHantUsersSayList(), getEnUsersSayList()),
      [ILocaleValue.pl]: dateRplace(getPlUsersSayList(), getEnUsersSayList())
    },
    comingList: {
      [ILocaleValue.en]: getEnCommingActivity(),
      [ILocaleValue.de]: getEnCommingActivity(),
      [ILocaleValue.es]: getEnCommingActivity(),
      [ILocaleValue.es_Es]: getEnCommingActivity(),
      [ILocaleValue.fr]: getEnCommingActivity(),
      [ILocaleValue.it]: getEnCommingActivity(),
      [ILocaleValue.ja]: getEnCommingActivity(),
      [ILocaleValue.ko]: getEnCommingActivity(),
      [ILocaleValue.pt]: getEnCommingActivity(),
      [ILocaleValue.vi]: getEnCommingActivity(),
      [ILocaleValue["zh-Hant"]]: getEnCommingActivity(),
      [ILocaleValue.pl]: getEnCommingActivity()
    }
  }
}

export const getKolsData: () => KolsType = () => {
  return {
    [ILocaleValue.en]: getEnKolsSayList(),
    [ILocaleValue.de]: dateRplace(getDeKolsSayList(), getEnKolsSayList()),
    [ILocaleValue.es]: dateRplace(getEsKolsSayList(), getEnKolsSayList()),
    [ILocaleValue.es_Es]: dateRplace(getEsEsKolsSayList(), getEnKolsSayList()),
    [ILocaleValue.fr]: dateRplace(getFrKolsSayList(), getEnKolsSayList()),
    [ILocaleValue.it]: dateRplace(getItKolsSayList(), getEnKolsSayList()),
    [ILocaleValue.ja]: dateRplace(getJaKolsSayList(), getEnKolsSayList()),
    [ILocaleValue.ko]: dateRplace(getKoKolsSayList(), getEnKolsSayList()),
    [ILocaleValue.pt]: dateRplace(getPtKolsSayList(), getEnKolsSayList()),
    [ILocaleValue.vi]: dateRplace(getViKolsSayList(), getEnKolsSayList()),
    [ILocaleValue["zh-Hant"]]: dateRplace(getZhHantKolsSayList(), getEnKolsSayList()),
    [ILocaleValue.pl]: dateRplace(getPlKolsSayList(), getEnKolsSayList()),
  }
}

// English kols
const getEnKolsSayList: () => Array<KolsSayType> = () => {
  return [
    {
      img: getCloudAssets("/images/pages/newHome/country/en/kols/kol-Mark-Wallace.webp"),
      name: "Mark Wallace",
      desc: "I think this is a really amazing tool! It is spectacular that you can create your own presets for lighting setups and style so you can repeat the same edits with just one click. It works like magic!",
      link: `<iframe width="560" height="315" src="https://www.youtube.com/embed/b9-_zt2Ic-I?si=Vj4f4fiqpJEDJcwN" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
      alt: "The Influencer Mark Wallace"
    },
    {
      img: getCloudAssets("/images/pages/newHome/country/en/kols/kol-Vanessa-Joy.webp"),
      name: "Vanessa Joy",
      desc: "Evoto AI is a game changer for my photo retouching workflow with its powerful features. It can de-wrinkle clothes, remove red veins around the eye, and clean up hairlines effortlessly. All of this is done precisely with minimal instruction required and competitive pricing.",
      link: `<iframe width="560" height="315" src="https://www.youtube.com/embed/bSOnGbAA0zw?si=yX0Nvyl2IDM1PQ21" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
      alt: "The Influencer Vanessa Joy"
    },
    {
      img: getCloudAssets("/images/pages/newHome/country/en/kols/kol-Pratik-Naik.webp"),
      name: "Pratik Naik",
      desc: "As a retoucher, I was impressed with Evoto. It maintains skin texture while evening tones and transitions, and allows for syncing and saving presets for returning clients. Customizable workspace and frequent updates make it even better.",
      link: `<iframe width="560" height="315" src="https://www.youtube.com/embed/DoWMjm4yDp0?si=MPliptvY5mq8Dfzk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
      alt: "The Influencer Pratik Naik"
    },
    {
      img: getCloudAssets("/images/pages/newHome/country/en/kols/kol-John-Gress.webp"),
      name: "John Gress",
      desc: "Evoto AI is great for batch editing images. This really speeds up my workflow and helps polish my images a lot faster. It also gives me something more if I want to sell the batch-edited, AI-retouched images in JPEG format.",
      link: `<iframe width="560" height="315" src="https://www.youtube.com/embed/Wqz-R10fXLc?si=OXk-rnpiKUY8a8S1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
      alt: "The Influencer John Gress"
    },
    {
      img: getCloudAssets("/images/pages/newHome/country/en/kols/kol-Mark-McGee.webp"),
      name: "Mark McGee",
      desc: "I've been using Evoto, this incredible editing and retouching platform ever since it came out. It has completely revolutionized the way I edit portraits for photograph groups and individual portraits.",
      link: `<iframe width="560" height="315" src="https://www.youtube.com/embed/qm1LI_Ug2BY?si=g87k-jBwIUtc-2j_" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
      alt: "The Influencer Mark McGee"
    },
    {
      img: getCloudAssets("/images/pages/newHome/country/en/kols/kol-Pye-Jirsa.webp"),
      name: "Pye Jirsa",
      desc: "Evoto AI allows me to retouch portraits effortlessly by adjusting sliders and gives me fantastic results. The software is efficient, affordable, and can significantly reduce retouching costs.",
      link: `<iframe width="560" height="315" src="https://www.youtube.com/embed/D4cWtu9HXQI?si=0rkCXlOHH9WSkhzY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
      alt: "The Influencer Pye Jirsa"
    }
  ]
}

const getDeKolsSayList = () => {
  return []
}

const getEsKolsSayList = () => {
  return [
    {
      img: getCloudAssets("/images/pages/newHome/country/en/kols/kol-es-1.webp"),
      name: "Leo Baquero",
      desc: "Actualizaciones que si valen la pena, ya puedes acelerar tu flujo de trabajo sin perder tanto tiempo. Algunas funciones son simplemente increíbles.",
      link: `<iframe width="560" height="315" src="https://www.youtube.com/embed/gPznpQKISHI?si=UYA95Nh9uUe_YzPY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`
    },
    {
      img: getCloudAssets("/images/pages/newHome/country/en/kols/kol-es-2.webp"),
      name: "Álvaro González",
      desc: "¡Estos nuevos funciones son simplemente increíbles! Pueden resolver en un segundo lo que antes tomaba una hora de trabajo en la edición de fotos y logran crear efectos perfectos en las imágenes.",
      link: `<iframe width="560" height="315" src="https://www.youtube.com/embed/aDMSOnyCl4U?si=IA3R7HE1e9IkUJ3l" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`
    },
    {
      img: getCloudAssets("/images/pages/newHome/country/en/kols/kol-es-3.webp"),
      name: "Edu López",
      desc: "He probado algunos software de inteligencia artificial para edición de fotografías y sin duda.Evoto ha sido el que más me ha llamado la atención.",
      link: `<iframe width="560" height="315" src="https://www.youtube.com/embed/qh-T6Q0nY4E?si=rk8teXtUCBYT_wBW" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`
    }
  ]
}

const getEsEsKolsSayList = () => {
  return [
    {
      img: getCloudAssets("/images/pages/newHome/country/en/kols/kol-es-1.webp"),
      name: "Leo Baquero",
      desc: "Actualizaciones que si valen la pena, ya puedes acelerar tu flujo de trabajo sin perder tanto tiempo. Algunas funciones son simplemente increíbles.",
      link: `<iframe width="560" height="315" src="https://www.youtube.com/embed/gPznpQKISHI?si=UYA95Nh9uUe_YzPY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`
    },
    {
      img: getCloudAssets("/images/pages/newHome/country/en/kols/kol-es-2.webp"),
      name: "Álvaro González",
      desc: "¡Estos nuevos funciones son simplemente increíbles! Pueden resolver en un segundo lo que antes tomaba una hora de trabajo en la edición de fotos y logran crear efectos perfectos en las imágenes.",
      link: `<iframe width="560" height="315" src="https://www.youtube.com/embed/aDMSOnyCl4U?si=IA3R7HE1e9IkUJ3l" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`
    },
    {
      img: getCloudAssets("/images/pages/newHome/country/en/kols/kol-es-3.webp"),
      name: "Edu López",
      desc: "He probado algunos software de inteligencia artificial para edición de fotografías y sin duda.Evoto ha sido el que más me ha llamado la atención.",
      link: `<iframe width="560" height="315" src="https://www.youtube.com/embed/qh-T6Q0nY4E?si=rk8teXtUCBYT_wBW" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`
    }
  ]
}

const getFrKolsSayList = () => {
  return []
}

const getItKolsSayList = () => {
  return []
}

const getJaKolsSayList: () => Array<UsersSayType> = () => {
  return []
}

const getKoKolsSayList = () => {
  return [
    {
      img: getCloudAssets("/images/pages/newHome/country/en/kols/kol-ko-1.webp"),
      name: "포토파블로",
      desc: "이보토 프로그램이 대단한 것 같습니다. 이보토 프로그램 잘 사용하고 좋아하는 이유가 세가지인데 첫번째는 프로그램의 성능과 퀄리티입니다. 두번째 이유는 이보토 AI 굉장히 가벼워졌고 최적화가 잘 돼 있는 것 같습니다. 파일을 가져오면 굉장히 빠르거든요. 세 번째 이유는 이 프로그램 하나로 모든 것을 해결할 수 있다는 겁니다.",
      link: `<iframe width="560" height="315" src="https://www.youtube.com/embed/03dT2Rv18bY?si=S7bSBRa3M_zdeAe9" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`
    },
    {
      img: getCloudAssets("/images/pages/newHome/country/en/kols/kol-ko-2.webp"),
      name: "이강신",
      desc: "AI기술을 접목한 이보토 프로그램을 통해서 시간을 단축시킬 수 있는 그런 효과를 가져다 줄 수 있기 때문에 사진을 업으로 하시는 분들에.굉장히 좋은 선택지가 아닐까는 생각을 합니다.세상에 완벽한 건 없겠지만은 제가 사용해 본 결과로는 인물 사진에 있어서는 굉장히 아주 특화된 그런 프로그램을 할 수 있을 것 같아요.",
      link: `<iframe width="560" height="315" src="https://www.youtube.com/embed/El5wS5AfWEo?si=LxHGKlnEd4KUqefO" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`
    },
    {
      img: getCloudAssets("/images/pages/newHome/country/en/kols/kol-ko-3.webp"),
      name: "권학봉",
      desc: "사진관을 운영하거나 상업적으로 인물 사진을 촬영 하시는 분들은 클라이언트의 요구가 매우 다양하고, 수정을 요청받는 경우가 많습니다. 기존 사진 편집 도구를 사용할 경우 모든 요청사항에 신속히 대응하기가 쉽지 않은데, 이러한 고민들은Evoto AI를 통해 손쉽게 해결할 수 있습니다.",
      link: `<iframe width="560" height="315" src="https://www.youtube.com/embed/7gsEVe0SFfY?si=IjpJkL5g80oEIGIl" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`
    }
  ]
}

const getPtKolsSayList = () => {
  return []
}

const getViKolsSayList = () => {
  return []
}

const getZhHantKolsSayList = () => {
  return []
}

const getPlKolsSayList = () => {
  return []
}

// English commit
const getEnUsersSayList: () => Array<UsersSayType> = () => {
  return [
    {
      name: "Alyssa Morgan",
      tag: "New York, USA",
      avatar: getCloudAssets("/images/pages/newHome/country/en/users/home-community-user-Alyssa-Morgan.webp"),
      score: 5,
      comment: `"Evoto AI has completely transformed my photo editing process. The intuitive interface and advanced features make it so easy to enhance my images. It's like having a professional editor in my pocket!"`
    },
    {
      name: "Jordan Lee",
      tag: "Houston, USA",
      avatar: getCloudAssets("/images/pages/newHome/country/en/users/home-community-user-Jordan-Lee.webp"),
      score: 5,
      comment: `"I can't believe how efficient Evoto AI is. The results are stunning, and the time I save on editing is invaluable. Highly recommend this tool for anyone serious about their photography."`
    },
    {
      name: "Samantha Thompson",
      tag: "Vancouver, Canada",
      avatar: getCloudAssets("/images/pages/newHome/country/en/users/home-community-user-Samantha-Thompson.webp"),
      score: 5,
      comment: `"Evoto AI is a game-changer! The AI-powered tools are incredibly accurate and save me so much time. My photos have never looked better. Absolutely love it!"`
    },
    {
      name: "Chris Hernandez",
      tag: "Auckland, New Zealand",
      avatar: getCloudAssets("/images/pages/newHome/country/en/users/home-community-user-Chris-Hernandez.webp"),
      score: 5,
      comment: `"Evoto AI has elevated my photo editing to the next level. The AI suggestions are spot-on, and the app is very user-friendly. A must-have for photographers!"`
    },
    {
      name: "Emily Chen",
      tag: "London, UK",
      avatar: getCloudAssets("/images/pages/newHome/country/en/users/home-community-user-Emily-Chen.webp"),
      score: 5,
      comment: `"I'm blown away by Evoto AI! It's so easy to use, and the editing capabilities are fantastic. My photos look professional with minimal effort. Highly recommend!"`
    },
    {
      name: "David Patel",
      tag: "Singapore",
      avatar: getCloudAssets("/images/pages/newHome/country/en/users/home-community-user-David-Patel.webp"),
      score: 5,
      comment: `"Evoto AI makes photo editing a breeze. The intelligent features and sleek design have greatly improved my workflow. It's the best editing app I've used so far!"`
    },
    {
      name: "Mark Calam",
      tag: "Brisbane, Australia",
      avatar: getCloudAssets("/images/pages/newHome/country/en/users/home-community-user-Mark-Calam.webp"),
      score: 5,
      comment: `"The most amazing program to greatly improve workflow. The amount of time saved is second to none. Will continue to use and excited to consistently see new improvement."`
    }
  ]
}

const getDeUsersSayList: () => Array<UsersSayType> = () => {
  return []
}

const getEsUsersSayList: () => Array<UsersSayType> = () => {
  return []
}

const getEsEsUsersSayList: () => Array<UsersSayType> = () => {
  return []
}

const getFrUsersSayList: () => Array<UsersSayType> = () => {
  return []
}

const getItUsersSayList: () => Array<UsersSayType> = () => {
  return []
}

const getJaUsersSayList: () => Array<UsersSayType> = () => {
  return [
    {
      name: "大森 和幸 氏",
      tag: "日本",
      avatar: getCloudAssets("/images/pages/newHome/country/en/users/home-community-user-ja-1.webp"),
      score: 5,
      comment: `"レタッチのクオリティを維持しながら、編集時間の大幅な短縮を実現する夢のようなツールです。これまでのワークフローを一新する革新的な「EVOTO AI」は、プロの視点からも本気でオススメできます。"`
    },
    {
      name: "早坂 華乃 氏",
      tag: "日本",
      avatar: getCloudAssets("/images/pages/newHome/country/en/users/home-community-user-ja-2.webp"),
      score: 5,
      comment: `"Evotoに出会って、時短、クオリティ向上、売上アップ全てを叶えることができました。コンスタントに顧客ニーズを拾い上げアップデートし続けてくれてるところが最高！ "`
    }
  ]
}

const getKoUsersSayList: () => Array<UsersSayType> = () => {
  return [
    {
      name: "박도윤",
      tag: "서울시",
      avatar: getCloudAssets("/images/pages/newHome/country/en/users/home-community-user-ko-1.webp"),
      score: 5,
      comment: `"Evoto AI를 사용한 지 1년이 지났어요. 예전에 외주 업체에 의존하던 시절과는 달리, 이제는 저렴한 비용으로 더 많은 사진을 보정할 수 있게 됐습니다. 주변 동업자들도 Evoto를 활용하고 있어요. 덕분에 절약한 시간을 마케팅에 활용하니 매출이 확실히 증가했습니다."`
    },
    {
      name: "윤지원",
      tag: "대전시",
      avatar: getCloudAssets("/images/pages/newHome/country/en/users/home-community-user-ko-2.webp"),
      score: 5,
      comment: `"Evoto AI를 사용한 지 1년이 지났어요. 예전에 외주 업체에 의존하던 시절과는 달리, 이제는 저렴한 비용으로 더 많은 사진을 보정할 수 있게 됐습니다. 주변 동업자들도 Evoto를 활용하고 있어요. 덕분에 절약한 시간을 마케팅에 활용하니 매출이 확실히 증가했습니다."`
    },
    {
      name: "오은아",
      tag: "부산시",
      avatar: getCloudAssets("/images/pages/newHome/country/en/users/home-community-user-ko-3.webp"),
      score: 5,
      comment: `"저는 한 번 촬영하면 수백 장의 사진을 편집해야 하는 사진가입니다. Evoto에서 가장 애용하는 기능은 동기화 기능이에요. 덕분에 일주일 내내 작업해야 했던 사진을 이틀 만에 끝내고 바로 다음 촬영으로 갈 수 있답니다. 작업량은 2배 늘었지만 오히려 피로도는 줄어들어 참 고마운 프로그램이랍니다. 확실히 상업용 프로그램이라 저 같은 사진가에게는 꼭 필요한 도구입니다. 전문가에게 꼭 추천합니다."`
    },
    {
      name: "하은지",
      tag: "세종시",
      avatar: getCloudAssets("/images/pages/newHome/country/en/users/home-community-user-ko-4.webp"),
      score: 5,
      comment: `"Evoto를 만나고 꿈꿔왔던 스튜디오 창업에 도전했어요! 모든 보정 작업을 Evoto로 하고 있구요. 특히 피부 보정 결과물이 너무 완벽해서 놀랐답니다. 헤어, 메이크업, 의상 같은 작은 디테일도 부담 없이 작업해드리니까 꼼꼼한 사진관으로 소문이 나서 멀리서도 찾아오시는 분들도 많아요!"`
    },
    {
      name: "홍민희",
      tag: "서울시",
      avatar: getCloudAssets("/images/pages/newHome/country/en/users/home-community-user-ko-5.webp"),
      score: 5,
      comment: `"심플하고 직관적인 인터페이스 덕분에 사용하는데 금방 적응할 수 있었어요. AI 덕분에 예전보다 훨씬 빠르고 고퀄리티로 작업할 수 있어서 고객에게 결과물을 더 빨리 전달하고 수정 작업도 신속하게 진행할 수 있어서 업무 효율이 많이 좋아졌어요. 덕분에 손목도 덜 피로하고 작업이 너무 편해져서 Evoto를 선택하길 참 잘한 것 같아요."`
    }
  ]
}

const getPtUsersSayList: () => Array<UsersSayType> = () => {
  return []
}

const getViUsersSayList: () => Array<UsersSayType> = () => {
  return []
}

const getZhHantUsersSayList: () => Array<UsersSayType> = () => {
  return []
}

const getPlUsersSayList: () => Array<UsersSayType> = () => {
  return []
}

const getEnCommingActivity: () => Array<CommingActivityType> = () => {
  return [
    {
      name: "London Photo Video Trade Show & Convention",
      area: "UK - London",
      date: "Jan 15 - Jan 18, 2025",
      img: getCloudAssets(
        "/images/pages/newHome/country/en/activitys/home-community-activity-LondonPhotoVideoTradeShowConvention.webp"
      ),
      alt: "Evoto AI Photo Editor at London Photo Video Trade Show & Convention"
    },
    {
      name: "SPAC",
      area: "USA - Neveda",
      date: "Jan 21- Jan 25, 2025",
      img: getCloudAssets("/images/pages/newHome/country/en/activitys/home-community-activity-ThePhotoSPAC.webp"),
      alt: "Evoto AI Photo Editor at SPAC"
    },
    {
      name: "Sugar & Kids",
      area: "Spain - ÁVILA",
      date: "Jan 29 - Jan 31, 2025",
      img: getCloudAssets("/images/pages/newHome/country/en/activitys/home-community-activity-ThePhotoSugarKids.webp"),
      alt: "Evoto AI Photo Editor at Sugar & Kids"
    },
    {
      name: "ImagingUSA",
      area: "USA - Texas",
      date: "Feb 2 - Feb 4, 2025",
      img: getCloudAssets("/images/pages/newHome/country/en/activitys/home-community-activity-ThePhotoImagingUSA.webp"),
      alt: "Evoto AI Photo Editor at ImagingUSA"
    },
    {
      name: "SYNC",
      area: "USA - Florida",
      date: "Feb 28 - March 3, 2025",
      img: getCloudAssets("/images/pages/newHome/country/en/activitys/home-community-activity-ThePhotoSYNC.webp"),
      alt: "Evoto AI Photo Editor at SYNC"
    }
  ]
}

export const scrollData: Array<scrollItemType> = [
  {
    id: HERO_ID,
    eventHandler: () => {
      const navbg = useNavBarBg()
      navbg.value = "rgb(252, 252, 243)"
      const navBarShadow = useNavBarShadowShow()
      navBarShadow.value = false
    }
  },
  {
    id: ADVANTAGE_ID,
    eventHandler: () => {
      const navbg = useNavBarBg()
      navbg.value = "#fff"
      const navBarShadow = useNavBarShadowShow()
      navBarShadow.value = false
    }
  },
  {
    // id: BRANK_ID,
    id: SCENE_ID,
    eventHandler: () => {
      const navbg = useNavBarBg()
      navbg.value = "#fff"
      const navBarShadow = useNavBarShadowShow()
      navBarShadow.value = true
    }
  }
]

export const newHOmeSEOConfig = {
  [ILocaleValue.en]: {
    title: "Evoto AI Photo Editor, Easier Photo Editing with AI",
    keyword: "AI photo editor, photo editor, image editor, free photo editor, photo editing software, ",
    description:
      "Effortlessly edit professional-looking photos with the Evoto AI photo editor. Seamlessly edit with precision for portraits, backgrounds and fine-tuned colors."
  }
}
