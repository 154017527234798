import { createVNode, render } from "vue"
import Payment from "~~/components/pages/comboSub/Payment.vue"
import { ISuccessType } from "@/types/components/pages/comboSub"
import CancelSubDialog from "~~/components/pages/comboSub/CancelDialog.vue"
import ComboDownGradeCancelDialog from "~~/components/pages/comboSub/ComboDownGradeCancelDialog.vue"
import { Payment_Method } from "@/constant/pages/combo"
import { ICardDetailData } from "@/types/components/pages/combo/comboCardT"
import { PaymentStatusType } from "@/types/components/common/paymentStatus"
import { useUser } from "@/store/user"
import { PROFILE_PAGE_PATH } from "@/constant/route" // 邀请注册页面路由
import { TabListValue } from "@/constant/pages/profile"
import { SessStorageKey } from "@/constant/cache"
import SubUpgrade from "~~/components/pages/comboSub/SubUpgrade.vue"

type UseCookieDialogOptions = {
  onClose?: () => void
  onSubmit?: (...args: any[]) => any
  payMethods?: Payment_Method[]
  data?: ICardDetailData & INewComboProductListObj
  desc?: string
  email?: string
  cusComboCardDetail?: { card_tail: string; expires: string }
}

let showSubBuyDialog = false
let upgradeDialog = false
let downgradeDialog = false
let reactivatedDialog = false
let downgradeTipDialog = false
let cancelSubBeforeDialog = false
let deleteCardInfoDialog = false
// 订阅过或者没订阅过 点击补充包或者Pay as You Go套餐选择支付方式
export function useComboSubBuyDialog(options: UseCookieDialogOptions = {}) {
  if (showSubBuyDialog) {
    return
  }
  const vm = createVNode(Payment, {
    ...options,
    onClose() {
      showSubBuyDialog = false
    }
  })
  const { vueApp } = useNuxtApp()
  vm.appContext = vueApp._context
  render(vm, document.createElement("div"))
  showSubBuyDialog = true
}

// 撤销降级或者撤销取消订阅 成功弹窗
export const useCancelDowngradeOrCancelSubDialogSuccess = (type: ISuccessType) => {
  useCommonNormalDialog({
    dialogWrapperStyle: {
      padding: "40px"
    },
    close() {
      location.reload()
    },
    popperClass: "sign-in-or-sign-up-wrapper-dialog",
    contentSlots({ close }) {
      const com = resolveComponent("PagesComboSubSuccess")
      return (
        <com
          itype={type}
          submit={async ({ closeLoading }) => {
            close()
            location.reload()
          }}
        />
      )
    }
  })
}

export const useComboSubUpgradeDialog = (options: {
  id: number
  current_plan: ICardDetailData & INewComboProductListObj
  next_plan: ICardDetailData & INewComboProductListObj
  custom_link_uuid?: string
  email?: string
  isBackUnit?: boolean
  successCallback: (...args: any[]) => any
  errorCallback: (...args: any[]) => any
}) => {
  if (upgradeDialog) {
    return
  }
  const vm = createVNode(SubUpgrade, {
    ...options,
    close() {
      upgradeDialog = false
    },
    onBtnClick: async ({ closeLoading, coupon_code, value, closeDialog }) => {
      try {
        const { trade_no } = await postNewSubUpdate({
          product_id: options.id,
          purchase_uuid: usePurchaseReferer(),
          purchase_source: useRefWebRefferer(),
          coupon_code,
          // 兼容快捷购买链接没有token的情况
          ...(() => (options.custom_link_uuid ? { custom_link_uuid: options.custom_link_uuid } : {}))()
        })
        options.successCallback && options.successCallback({ trade_no, value })
      } catch (e) {
        options.errorCallback && options.errorCallback()
      } finally {
        closeDialog()
        closeLoading()
      }
    }
  })
  const { vueApp } = useNuxtApp()
  vm.appContext = vueApp._context
  render(vm, document.createElement("div"))

  upgradeDialog = true
}

// 降级前的提示弹窗
export const useComboSubDownGradeTip = (options: {
  current_plan: ICardDetailData & INewComboProductListObj
  next_plan: ICardDetailData & INewComboProductListObj
}): Promise<{ reason_id?: any[]; reason_content?: string; fl: boolean }> => {
  return new Promise((resolve, reject) => {
    if (downgradeTipDialog) {
      return
    }

    let closeDialog

    let isEndPromise = true

    const { current_plan, next_plan } = options
    const title = useTranslateI18n("payment.comboSub.t85", [
      next_plan.name,
      next_plan.name,
      getLocalDateString(next_plan.period_start, "YYYY-MM-DD")
    ])
    const descList = [
      useTranslateI18n("payment.comboSub.t86", [useNumFormat(current_plan.credits), useNumFormat(next_plan.credits)]),
      useTranslateI18n("payment.comboSub.t87", [current_plan.device_limit, next_plan.device_limit]),
      useTranslateI18n("payment.comboSub.t88", [
        useNumFormat(current_plan.credits * 5),
        useNumFormat(next_plan.credits * 5)
      ])
    ]

    const cancelClick = () => {
      closeDialog()
      resolve({
        fl: false
      })
    }

    const submitClick = () => {
      isEndPromise = false
      closeDialog()
      useComboDownGradeCancelDialog({
        cancel() {
          resolve({
            fl: false
          })
        },
        submit({ reason_content, reason_id }) {
          resolve({
            fl: true,
            reason_content,
            reason_id
          })
        }
      })
    }

    useCommonNormalDialog({
      dialogWrapperStyle: {
        padding: "40px"
      },
      width: 438,
      close() {
        if (isEndPromise) {
          resolve({
            fl: false
          })
        }
        downgradeTipDialog = false
      },
      popperClass: "sign-in-or-sign-up-wrapper-dialog",
      contentSlots({ close }) {
        closeDialog = close
        const com = resolveComponent("PagesComboSubDownGradeTip")
        return <com title={title} descList={descList} onCancelClick={cancelClick} onSubmitClick={submitClick} />
      }
    })
    downgradeTipDialog = true
  })
}

// 降级弹窗
export const useComboSubDownGradeDialog = async (options: {
  id: number
  current_plan: ICardDetailData & INewComboProductListObj
  next_plan: ICardDetailData & INewComboProductListObj
  successCallback: (...args: any[]) => any
  errorCallback: (...args: any[]) => any
}) => {
  if (downgradeDialog) {
    return
  }
  const { reason_id, reason_content, fl } = await useComboSubDownGradeTip({
    current_plan: options.current_plan,
    next_plan: options.next_plan
  })

  if (!fl) {
    return
  }
  useCommonNormalDialog({
    dialogWrapperStyle: {
      padding: "40px"
    },
    width: 876,
    close() {
      downgradeDialog = false
    },
    popperClass: "sign-in-or-sign-up-wrapper-dialog",
    contentSlots({ close }) {
      const com = resolveComponent("PagesComboSubDownGrade")
      return (
        <com
          {...options}
          onBtnClick={async ({ closeLoading }) => {
            try {
              const { trade_no } = await postNewSubUpdate({
                product_id: options.id,
                reason_id,
                reason_content,
                purchase_uuid: usePurchaseReferer(),
                purchase_source: useRefWebRefferer()
              })
              options.successCallback && options.successCallback(trade_no)
            } catch (e) {
              options.errorCallback && options.errorCallback()
            } finally {
              close()
              closeLoading()
            }
          }}
        />
      )
    }
  })
  downgradeDialog = true
}

// 降级填写原因弹窗
export const useComboDownGradeCancelDialog = (options: {
  cancel: (...args: any[]) => any
  submit: (options: { reason_id: any[]; reason_content: string }) => any
}) => {
  const vm = createVNode(ComboDownGradeCancelDialog, {
    ref: "cancelDownGradeDialogRef",
    cancel: options.cancel,
    submit: options.submit
  })
  const { vueApp } = useNuxtApp()
  vm.appContext = vueApp._context
  render(vm, document.createElement("div"))
}

// 取消订阅填写原因弹窗
export const useComboSubCancelDialog = async (lastClose?: (...args: any[]) => any) => {
  const fl = await useCancelSubBeforeDialog()
  if (!fl) {
    return
  }
  const vm = createVNode(CancelSubDialog, {
    ref: "cancelSubDialogRef",
    lastClose
  })
  const { vueApp } = useNuxtApp()
  vm.appContext = vueApp._context
  render(vm, document.createElement("div"))
}

// 取消订阅前置确认dialog
export const useCancelSubBeforeDialog = () => {
  if (cancelSubBeforeDialog) {
    return true
  }
  return new Promise((resolve, reject) => {
    let closeDialog

    const submit = () => {
      closeDialog()
      resolve(true)
    }

    useCommonNormalDialog({
      dialogWrapperStyle: {
        padding: "40px"
      },
      close() {
        cancelSubBeforeDialog = false
        resolve(false)
      },
      popperClass: "sign-in-or-sign-up-wrapper-dialog",
      contentSlots({ close }) {
        closeDialog = close
        const com = resolveComponent("PagesComboSubCancelSubBeforeDialog")
        return <com onSubmitClick={submit} />
      }
    })

    cancelSubBeforeDialog = true
  })
}

// 删除card信息
export const useDeleteCardInfoDialog = () => {
  if (deleteCardInfoDialog) {
    return true
  }
  return new Promise((resolve, reject) => {
    let closeDialog

    const submit = async ({ closeLoading }) => {
      try {
        await postDeleteCardInfo()
        useSuccessMessage(useTranslateI18n("payment.comboSub.t93"))
        setTimeout(() => {
          location.reload()
        }, 3000)
        resolve(true)
        closeDialog()
      } finally {
        closeLoading()
      }
    }

    const cancel = () => {
      resolve(true)
      closeDialog()
    }

    useCommonNormalDialog({
      dialogWrapperStyle: {
        padding: "40px"
      },
      close() {
        deleteCardInfoDialog = false
        resolve(false)
      },
      popperClass: "sign-in-or-sign-up-wrapper-dialog",
      contentSlots({ close }) {
        closeDialog = close
        const com = resolveComponent("PagesComboSubCancelCardBeforeDialog")
        return <com onSubmitClick={submit} onCancelClick={cancel} />
      }
    })

    deleteCardInfoDialog = true
  })
}

// 订阅reactiva提示弹窗
export const useComboSubPausedDialog = () => {
  if (getLocalStorage(SessStorageKey.IS_SHOW_REACTIVATED_DIALOG, "sessionStorage")) return
  useCommonNormalDialog({
    dialogWrapperStyle: {
      padding: "40px"
    },
    popperClass: "sign-in-or-sign-up-wrapper-dialog",
    contentSlots({ close }) {
      const com = resolveComponent("PagesComboSubPaused")
      return (
        <com
          onSubmit={async () => {
            close()
            const { trade_no } = await postNewSubRecover({
              purchase_uuid: usePurchaseReferer(),
              purchase_source: useRefWebRefferer()
            })
            useComboSubPausedResultDialog(trade_no)
          }}
        />
      )
    }
  })
  setLocalStorage(SessStorageKey.IS_SHOW_REACTIVATED_DIALOG, "true", "sessionStorage")
}

// 订阅reactivate之后的结果弹窗
export const useComboSubPausedResultDialog = (trade_no: string) => {
  if (reactivatedDialog) {
    return
  }

  const showCloseIcon = ref(false)

  useCommonNormalDialog({
    showCloseIcon,
    dialogWrapperStyle: {
      padding: "40px"
    },
    popperClass: "sign-in-or-sign-up-wrapper-dialog",
    close() {
      reactivatedDialog = false
    },
    contentSlots({ close }) {
      const com = resolveComponent("PagesComboSubPaymentStatus")
      return (
        <com
          trade_no={trade_no}
          onBtnClick={(type: PaymentStatusType) => {
            const use_user = useUser()
            if (type === PaymentStatusType.Success) {
              if (useRoute().fullPath.includes(unref(PROFILE_PAGE_PATH))) {
                return location.reload()
              }
              navigateTo(`${unref(PROFILE_PAGE_PATH)}?t=${TabListValue.MY_BILLING}`)
            } else if (type === PaymentStatusType.Failed) {
              window.open(use_user.subscription_info_v2_web.payment_updated_url, "_blank")
            }
          }}
          onResult={() => {
            showCloseIcon.value = true
          }}
        />
      )
    }
  })

  reactivatedDialog = true
}
