import { ILocaleValue } from "@/constant/i18n-country"

export const useCountryJudge = () => {
  const isJp = computed(() => {
    return useI18nCountry().value === ILocaleValue.ja
  })

  const isKo = computed(() => {
    return useI18nCountry().value === ILocaleValue.ko
  })

  const isVn = computed(() => {
    return useI18nCountry().value === ILocaleValue.vi
  })

  //  ...

  return {
    isJp,
    isKo,
    isVn
  }
}
