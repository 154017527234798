<template>
  <CommonDialog
    v-model="showDialog"
    popper-class="payment-upgrade-dialog-wrapper"
    :dialog-style="dialogStyle"
    @close="close"
  >
    <div class="upgrade-dialog-wrapper">
      <ClientOnly>
        <div class="upgrade-dialog-wrapper-left-control">
          <div class="curren-plan-dialog-wrapper">
            <div class="title">{{ useTranslateI18n("payment.upgradeDialog.currentPlan") }}</div>
            <div class="name">{{ current_plan.name }}</div>
            <div class="period">
              {{ useTranslateI18n("payment.upgradeDialog.period", [currentPeriod]) }}
            </div>
            <div class="menu-item">
              <span class="text-1">
                {{ useTranslateI18n("payment.comboSub.t40") }}
              </span>
              <span class="text-2"> {{ current_plan.device_limit }} </span>
            </div>

            <div class="menu-item">
              <span class="text-1">
                {{ useTranslateI18n(`payment.paddleDialog.credits`, [useNumFormat(current_plan.credits)]) }}
              </span>
              <span class="text-2">
                {{
                  useTranslateI18n("payment.topCard[1].desc", [
                    current_plan.u_discount_per_credit_price || current_plan.singlePrice
                  ])
                }}
              </span>
            </div>

            <div class="menu-item">
              <span class="text-1">
                {{ useTranslateI18n("payment.upgradeDialog.rCredits") }}
              </span>
              <span class="text-2"> {{ use_user.subscription_info_v2_web.sub_left_count }} </span>
            </div>

            <span class="tip-fl">
              {{ useTranslateI18n("payment.comboSub.t42") }}
            </span>
          </div>
        </div>
        <div class="upgrade-dialog-wrapper-right-show">
          <div :class="['upgrade-plan-dialog-wrapper']">
            <div class="title">
              {{ useTranslateI18n("payment.comboSub.t75") }}
            </div>
            <div class="name">{{ next_plan.name }}</div>
            <div class="period">
              {{ useTranslateI18n("payment.upgradeDialog.period", [nextPeriod]) }}
            </div>
            <div class="menu-item">
              <span class="text-1">
                {{ useTranslateI18n("payment.comboSub.t40") }}
              </span>
              <span class="text-2"> {{ next_plan.device_limit }} </span>
            </div>
            <div class="menu-item">
              <span class="text-1">
                {{ useTranslateI18n("payment.account") }}
              </span>
              <span class="text-2"> {{ userEmail }} </span>
            </div>

            <div class="menu-item">
              <span class="text-1">
                {{ useTranslateI18n(`payment.paddleDialog.credits`, [useNumFormat(next_plan.credits)]) }}
              </span>
              <span v-if="priceDetail.discount" class="text-2">
                {{ useTranslateI18n("payment.topCard[1].desc", [priceDetail.perMoney]) }}
              </span>
              <span v-if="!priceDetail.discount" class="text-2">
                {{ useTranslateI18n("payment.topCard[1].desc", [next_plan.singlePrice]) }}
              </span>
            </div>

            <!-- 原总价 -->
            <div class="menu-item menu-item-total">
              <span class="text-1"> {{ useTranslateI18n("payment.buyDataPkgDialog.total") }} </span>
              <span class="text-2 text-n"> {{ next_plan.price }} </span>
            </div>

            <div v-if="priceDetail.discount" class="menu-item menu-item-save-discount">
              <div class="total-c">
                <CloseFl />
                <span class="total-c-co">
                  {{ coupon_code }}
                </span>
                <Close class="total-c-cl" @click="closeCoupon" />
              </div>
              <div class="one-c">
                <span class="num">-{{ priceDetail.discount }}</span>
              </div>
            </div>

            <div v-if="priceDetail.discount" class="menu-item menu-item-sub-total">
              <span class="text-1"> {{ useTranslateI18n(`payment.paddleDialog.total1`) }} </span>
              <span class="text-2 text-n"> {{ priceDetail.total }} </span>
            </div>

            <div class="menu-item menu-item-after-offer">
              <span class="text-1">
                {{ useTranslateI18n("payment.comboSub.t52", [getLocalDateString(next_plan.period_end, "YYYY-MM-DD")]) }}
              </span>
              <span class="text-2 text-n"> {{ next_plan.price }} </span>
            </div>

            <!-- <span class="tip-fl tip-r"> Include Sales Tax US$13(10%) </span> -->
          </div>

          <div :class="['discount-input', showCancelBtn ? 'discount-input-display' : '']">
            <span class="add-discount">{{ useTranslateI18n(`payment.comboPayment.vnDialog.addDis`) }}</span>
            <el-input
              ref="inputRef"
              v-model.trim="hiddenValue"
              :style="{ width: 0, height: 0, opacity: 0, position: 'fixed', left: '-10000px' }"
              type="text"
              class="country-number"
              clearable="clearable"
            ></el-input>

            <el-input
              ref="inputRef"
              v-model.trim="result_coupon_code"
              type="text"
              autocomplete="new-password"
              class="country-number"
              clearable="clearable"
            ></el-input>
            <transition name="fade">
              <span v-show="discountValueErrorMsgShow" class="error-tip">{{
                useTranslateI18n(`payment.comboPayment.vnDialog.errorTip`)
              }}</span>
            </transition>
          </div>

          <div class="upgrade-dialog-wrapper-right-show__bottom_control">
            <div class="bottom_control_btn">
              <CommonButton ref="btnRef" class="submit-button" :button-style="buttonStyle1" @btn-click="submit"
                ><CommonAnimationScrollText>{{
                  showCancelBtn
                    ? useTranslateI18n(`payment.comboPayment.vnDialog.cBtn`)
                    : useTranslateI18n("payment.topCard[1].btn[1]")
                }}</CommonAnimationScrollText></CommonButton
              >

              <template v-if="showCancelBtn">
                <CommonButton
                  ref="cancelBtnRef"
                  :button-style="cancelStyle"
                  class="cancel-btn"
                  @btn-click="cancelClick"
                >
                  <CommonAnimationScrollText>
                    {{ useTranslateI18n(`payment.comboPayment.vnDialog.cancel`) }}
                  </CommonAnimationScrollText>
                </CommonButton>
              </template>
            </div>

            <div v-if="showAddDiscount" :class="['add-discount-wrapper']">
              <span class="add-discount" @click="addClick">
                {{ useTranslateI18n(`payment.comboPayment.vnDialog.addDis`) }}
              </span>
            </div>
          </div>
        </div>
      </ClientOnly>
    </div>
  </CommonDialog>
</template>

<script setup lang="ts">
import { ICardDetailData } from "@/types/components/pages/combo/comboCardT"
import Close from "~~/assets/icons/common/payment-close.svg"
import CloseFl from "~~/assets/icons/common/payment-c-icon.svg"
import { CSSProperties } from "vue"
import { useUser } from "@/store/user"
let use_user = useUser()

interface IProps {
  current_plan: ICardDetailData & INewComboProductListObj
  next_plan: ICardDetailData & INewComboProductListObj
  email?: string
  isBackUnit?: boolean
  custom_link_uuid?: any
  close?: (...args: any[]) => any
}

const props = withDefaults(defineProps<IProps>(), {
  current_plan: () => {
    return {} as ICardDetailData & INewComboProductListObj
  },
  next_plan: () => {
    return {} as ICardDetailData & INewComboProductListObj
  },
  email: () => {
    return ""
  },
  isBackUnit: () => {
    return true
  },
  custom_link_uuid: () => {
    return ""
  },
  close: () => null
})

const emit = defineEmits(["btnClick"])

let showDialog = ref(true)

let dialogStyle = reactive<CSSProperties>({
  width: 860,
  backgroundColor: "#fff",
  padding: "40px"
})

const closeDialog = () => {
  showDialog.value = false
}

const close = () => {
  props.close && props.close()
}

let btnRef = ref()

let inputRef = ref()

let showCancelBtn = ref(false)
let discountValueErrorMsgShow = ref(false)
let coupon_code = ref(props.next_plan.coupon_code)

let result_coupon_code = ref("")

let hiddenValue = ref("")

let showAddDiscount = computed(() => {
  return !showCancelBtn.value
})

let priceDetail = reactive({
  discount: "",
  total: "",
  perMoney: ""
})

const cancelStyle = reactive({
  width: "100%",
  height: "42px",
  borderRadius: "60px",
  fontSize: "16px",
  color: "#000",
  backgroundColor: "rgba(0, 0, 0, 0.1)"
})

const addClick = useDebounceFn(async () => {
  showCancelBtn.value = true
  await nextTick(() => {})
  inputRef.value && inputRef.value.focus()
}, 300)

const closeCoupon = useDebounceFn(() => {
  setInitPriceDetail()
  coupon_code.value = ""
}, 300)

const setInitPriceDetail = (init = false) => {
  if (init) {
    priceDetail.discount = props.next_plan.u_discount_amount
    priceDetail.total = props.next_plan.u_discount_price
    priceDetail.perMoney = props.next_plan.u_discount_per_credit_price
  } else {
    priceDetail.discount = ""
    priceDetail.total = ""
    priceDetail.perMoney = ""
  }
}

if (props.next_plan.coupon_code) {
  setInitPriceDetail(true)
}

const cancelClick = () => {
  showCancelBtn.value = false
  discountValueErrorMsgShow.value = false
  // setInitPriceDetail()
  result_coupon_code.value = ""
}

const discountChange = async () => {
  btnRef.value && btnRef.value.openLoading()
  if (unref(result_coupon_code)) {
    try {
      let data = await postPaymentPolyDiscount({
        product_id: props.next_plan.id,
        coupon_code: unref(result_coupon_code),
        custom_link_uuid: props.custom_link_uuid || undefined
      })
      if (unref(showCancelBtn) && !data.message) {
        discountValueErrorMsgShow.value = false
        // eslint-disable-next-line vue/no-mutating-props
        priceDetail.discount = props.isBackUnit
          ? `${useNumFormat(data.discount_amount)}${data.currency_char}`
          : data.currency_char + useNumFormat(data.discount_amount)
        // eslint-disable-next-line vue/no-mutating-props
        priceDetail.total = props.isBackUnit
          ? `${useNumFormat(data.discount_price)}${data.currency_char}`
          : data.currency_char + useNumFormat(data.discount_price)
        priceDetail.perMoney = props.isBackUnit
          ? `${useNumFormat(data.discount_per_credit_price)}${data.currency_char}`
          : data.currency_char + useNumFormat(data.discount_per_credit_price)

        coupon_code.value = result_coupon_code.value
        return true
      }
      if (unref(showCancelBtn) && data.message) {
        discountValueErrorMsgShow.value = true
      }
    } catch (e) {
      discountValueErrorMsgShow.value = true
    } finally {
      closeLoading()
    }
  } else {
    discountValueErrorMsgShow.value = true
    closeLoading()
  }
}

const closeLoading = () => {
  btnRef.value && btnRef.value.closeLoading()
}

const userEmail = computed(() => {
  return props.email || use_user.userEmail
})

const currentPeriod = computed(() => {
  return `${getLocalDateString(props.current_plan.period_start, "YYYY-MM-DD")} - ${getLocalDateString(
    props.current_plan.period_end,
    "YYYY-MM-DD"
  )}`
})

const nextPeriod = computed(() => {
  return `${getLocalDateString(props.next_plan.period_start, "YYYY-MM-DD")} - ${getLocalDateString(
    props.next_plan.period_end,
    "YYYY-MM-DD"
  )}`
})

const buttonStyle1 = reactive({
  width: "100%",
  background: "#FFE050",
  borderRadius: "60px",
  height: "42px",
  color: "#000"
})

const submit = useDebounceFn(async () => {
  if (!unref(showCancelBtn)) {
    btnRef.value && btnRef.value.openLoading()
    emit("btnClick", {
      closeLoading,
      coupon_code: unref(coupon_code),
      value: priceDetail.total || props.next_plan.price,
      closeDialog
    })
  } else {
    // 有设置优惠码传优惠码
    let data = await discountChange()
    if (!data) {
      return
    }
    showCancelBtn.value = false
  }
}, 300)
</script>

<style scoped lang="scss">
.upgrade-dialog-wrapper {
  line-height: normal;
  color: #000;
  display: flex;
  min-height: 572px;
  > div {
    width: 50%;
  }

  .upgrade-dialog-wrapper-left-control {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-right: 40px;
    border-right: 1px solid rgba(0, 0, 0, 0.15);
    .curren-plan-dialog-wrapper {
      display: flex;
      flex-direction: column;
      &-border {
        border-bottom: none;
      }
      .title {
        font-size: 14px;
        margin-bottom: 8px;
      }
      .name {
        font-size: 24px;
        @include fontSemibold;
        margin-bottom: 24px;
      }
      .period {
        font-size: 14px;
      }
      .menu-item {
        display: flex;
        margin-top: 18px;
        justify-content: space-between;
        align-items: center;
        .text-1 {
          font-size: 14px;
        }
        .text-2 {
          font-size: 16px;
          @include fontSemibold;
          word-break: break-all;
        }
        .text-n {
          color: #d25f00;
        }

        &.menu-item-sub-total {
          .text-2 {
            color: #000;
          }
        }
        &.menu-item-total {
          .text-2 {
            color: #000;
          }
        }
        &.menu-item-save-discount {
          .one-c {
            .num {
              color: #d25f00;
              @include fontSemibold;
              font-size: 14px;
            }
          }
          .total-c {
            padding: 4px;
            border-radius: 6px;
            background: #fff7e8;
            display: flex;
            align-items: center;
            .total-c-co {
              font-size: 14px;
              color: #d25f00;
              font-family: "Roboto-Medium";
              margin: 0 4px;
            }
            .total-c-cl {
              cursor: pointer;
            }
          }
        }
      }
      .menu-item-after-offer {
        margin-top: 18px;
        border-top: 0.5px solid rgba(0, 0, 0, 0.15);
        padding-top: 18px;
      }
      .tip-fl {
        margin-top: 5px;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.55);
        @include fontMedium;
      }
      .tip-r {
        display: flex;
        justify-content: flex-end;
      }
    }
  }

  .upgrade-dialog-wrapper-right-show {
    display: flex;
    flex-direction: column;
    padding-left: 40px;
    .upgrade-plan-dialog-wrapper {
      display: flex;
      flex-direction: column;
      padding-bottom: 16px;
      border-bottom: 0.5px solid rgba(0, 0, 0, 0.15);
      &-border {
        border-bottom: none;
      }
      .title {
        font-size: 14px;
        margin-bottom: 8px;
      }
      .name {
        font-size: 24px;
        @include fontSemibold;
        margin-bottom: 24px;
      }
      .period {
        font-size: 14px;
      }
      .menu-item {
        display: flex;
        margin-top: 18px;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        .text-1 {
          font-size: 14px;
        }
        .text-2 {
          font-size: 16px;
          @include fontSemibold;
          word-break: break-all;
        }
        .text-n {
          color: #d25f00;
        }

        &.menu-item-sub-total {
          .text-2 {
            color: #000;
          }
        }
        &.menu-item-total {
          .text-2 {
            color: #000;
          }
        }
        &.menu-item-save-discount {
          .one-c {
            .num {
              color: #d25f00;
              @include fontSemibold;
              font-size: 14px;
            }
          }
          .total-c {
            padding: 4px;
            border-radius: 6px;
            background: #fff7e8;
            display: flex;
            align-items: center;
            .total-c-co {
              font-size: 14px;
              color: #d25f00;
              font-family: "Roboto-Medium";
              margin: 0 4px;
            }
            .total-c-cl {
              cursor: pointer;
            }
          }
        }
      }
      .menu-item-after-offer {
        margin-top: 18px;
        border-top: 0.5px solid rgba(0, 0, 0, 0.15);
        padding-top: 18px;
      }
      .tip-fl {
        margin-top: 5px;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.55);
        @include fontMedium;
      }
      .tip-r {
        display: flex;
        justify-content: flex-end;
      }
    }

    .discount-input {
      margin-top: 40px;
      margin-bottom: 40px;
      flex-direction: column;
      display: flex;
      visibility: hidden;
      &-display {
        visibility: visible;
      }

      .add-discount {
        font-size: 16px;
        line-height: 19px;
        margin-bottom: 10px;
      }
      .error-tip {
        margin-top: 10px;
        font-size: 12px;
        line-height: 14px;
        color: #fc5555;
      }
      :deep(.el-input) {
        height: 36px;
        .el-input__wrapper {
          border: 1px solid rgba(0, 0, 0, 0.3);
          border-radius: 8px;
          box-shadow: none;
          .el-input__inner {
            color: #000;
          }
        }
      }
    }
    &__bottom_control {
      display: flex;
      flex-direction: column;
      align-items: center;
      .bottom_control_btn {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
      }
      .add-discount-wrapper {
        margin-top: 17px;
      }
      .add-discount {
        font-size: 16px;
        line-height: 19px;
        color: #3083ff;
        text-decoration: underline;
        cursor: pointer;
      }
      .cancel-btn {
        margin-top: 10px;
      }
    }
  }
}

@include device-max-width-custom(970px) {
  .upgrade-dialog-wrapper {
    flex-direction: column;
    min-height: unset;
    > div {
      width: 100%;
    }
    .upgrade-dialog-wrapper-left-control {
      border-right: 0;
      padding-right: 0;
    }
    .upgrade-dialog-wrapper-right-show {
      padding-left: 0;
      margin-top: 40px;
      .discount-input {
        margin: 20px 0;
      }
    }
  }
}
</style>

<style lang="scss">
.payment-upgrade-dialog-wrapper {
  &.welcome-page-dialog {
    .content-d {
      .t-content {
        margin: 0;
      }
    }
    @include device-max-width-custom(970px) {
      .content {
        width: 75%;
        .content-d {
          padding: 30px !important;
          width: 100% !important;
        }
      }
    }
  }
}
</style>
