<script lang="tsx">
import { PropType } from "vue"
import { INavBarLinkData, navBarLinkKey } from "@/types/components/common/navbar"
import { IClickType } from "@/constant/index"
import { navBarFeatureList } from "@/constant/navbar"
import { ALL_FEATURE } from "@/constant/route"
import CommonArrow from "@/assets/icons/common/arrow.svg"
import { NuxtLink } from "#components"
import CommonTransition from "@/components/common/Transition.vue"

export default defineComponent({
  name: "",
  props: {
    navList: {
      type: Array as PropType<INavBarLinkData>,
      default: () => {
        return []
      }
    }
  },
  emits: ["linkItemClick"],
  setup(props) {
    let resultNavList = ref<INavBarLinkData>([])

    const addNodeKey = (list: INavBarLinkData) => {
      list.map((item) => {
        item._switchFlag = false
        if (item.children && item.children.length) {
          addNodeKey(item.children)
        }
      })
    }

    watch(
      () => props.navList,
      (newValue) => {
        resultNavList.value = useCloneDeep(newValue)
        addNodeKey(resultNavList.value)
      },
      {
        immediate: true
      }
    )

    let renderIndex = 0

    return {
      renderIndex,
      resultNavList
    }
  },
  methods: {
    linkItemClick(item) {
      this.$emit("linkItemClick", item)
    },
    showChildClick(item) {
      item._switchFlag = !item._switchFlag
    },
    // 渲染features列表
    renderFeatureList(list) {
      const featureList = navBarFeatureList(list)
      return (
        <div class={["navbar-menu-list-con"]}>
          {Object.keys(featureList).map((_t, _i) => {
            return (
              <div class="features-list">
                <p class="list-category-title">{_t}</p>
                <el-divider />
                {featureList[_t].map((item, i) => {
                  return (
                    <div class="list-link-item" key={i}>
                      <NuxtLink
                        target={item.target}
                        to={item.path}
                        onClick={() => {
                          this.linkItemClick(item)
                        }}
                      >
                        {item.name}
                        {item.tag && (
                          <span className={`feature-tag-${item.tag}`}>
                            {useTranslateI18n(`features.tag.${item.tag}`)}
                          </span>
                        )}
                      </NuxtLink>
                    </div>
                  )
                })}
                <div
                  class="list-link-item see-all-item"
                  onClick={() => {
                    this.linkItemClick()
                  }}
                >
                  <NuxtLink to={ALL_FEATURE.value + "#" + "category_" + _i}>
                    {useTranslateI18n("features.seeAll")}
                  </NuxtLink>
                </div>
              </div>
            )
          })}
        </div>
      )
    },
    renderChildList(navList) {
      if (navList && navList.length) {
        return (
          <div class={["navbar-menu-list-con"]}>
            {navList.map((item) => {
              return (
                <div class="list-link-item">
                  {item.type === IClickType.Skip && (
                    <NuxtLink
                      target={item.target}
                      to={item.path}
                      onClick={() => {
                        this.linkItemClick(item)
                      }}
                    >
                      {item.name}
                    </NuxtLink>
                  )}
                  {item.type === IClickType.Click && (
                    <span
                      onClick={() => {
                        this.linkItemClick(item)
                      }}
                    >
                      {item.name}
                    </span>
                  )}
                  {item.type === IClickType.Hover && item.children && item.children.length && (
                    <div class="list-link-item-child">
                      <div
                        class="child-title"
                        onClick={() => {
                          this.showChildClick(item)
                        }}
                      >
                        <span>{item.name}</span>
                        <CommonArrow class={["arrow", item._switchFlag ? "active" : ""]} />
                      </div>
                      <CommonTransition>
                        {item._switchFlag &&
                          (item.key === navBarLinkKey.Features
                            ? this.renderFeatureList(item.children)
                            : this.renderChildList(item.children))}
                      </CommonTransition>
                    </div>
                  )}
                </div>
              )
            })}
          </div>
        )
      } else {
        return null
      }
    }
  },
  render() {
    return <div class="navbar-menu-list">{this.renderChildList(this.resultNavList)}</div>
  }
})
</script>

<style scoped lang="scss">
.navbar-menu-list {
  display: flex;
  flex-direction: column;
  .arrow {
    color: #000;
    transition: transform 0.3s ease-in-out;
    &.active {
      transform: rotate((180deg));
    }
  }
  .navbar-menu-list-con {
    display: flex;
    flex-direction: column;
    .list-link-item {
      font-weight: 510;
      font-size: 20px;
      line-height: 24px;
      line-height: 40px;
      cursor: pointer;
      a {
        color: #000;
      }
      .list-link-item-child {
        .child-title {
          display: flex;
          align-items: center;
        }
        .navbar-menu-list-con {
          padding-left: 32px;
          .list-link-item {
            padding: 12px 0px;
            color: rgba(0, 0, 0, 0.85);
            font-family: Roboto-Regular;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            display: flex;
          }
        }
      }
    }
    .features-list {
      .list-category-title {
        color: #000;
        font-family: Roboto-Medium;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        padding: 12px 0px;
      }
      .list-link-item {
        padding: 12px 0px;
        color: rgba(0, 0, 0, 0.85);
        font-family: Roboto-Regular;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        .feature-tag-New,
        .feature-tag-Hot {
          border-radius: 3px;
          padding: 2px 4px;
          font-family: Roboto-Medium;
          font-size: 11px;
          font-style: normal;
          // font-weight: 600;
          line-height: 12px;
          margin-left: 4px;
        }
        .feature-tag-New {
          background: #ffe050;
          color: #000;
        }
        .feature-tag-Hot {
          background: linear-gradient(180deg, #ff4a31 0%, #ff8831 100%);
          color: #fff;
        }
      }
      .see-all-item {
        a {
          color: #2771ff;
        }
      }

      .el-divider--horizontal {
        margin: 8px auto 8px 0px;
        width: calc(100% - 32px);
        border-color: rgba(0, 0, 0, 0.2);
        border-width: 1px;
        transform: scaleY(0.5);
      }
    }
  }
}
</style>
