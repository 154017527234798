import { ImageCompareType } from "~~/constant/pages/features"
import { getCloudAssets } from "~~/utils"
import { CSSProperties, InjectionKey, Ref } from "vue"
import landingLocale from "~~/lang/en/landing.json"
export interface IPortraitProps {
  workspaceBgImg?: string
  workspacePadding?: string
  reserve?: boolean
  title: string
  desc: string
  descList: string[]
  token?: string
  originalImg: string
  effectImg: string
  bImg?: string
  bImgStyle?: CSSProperties
}

export interface landingBtnProvideType {
  btnDisabled: Ref<boolean>
}

export const PortraitItemList = [
  {
    reserve: false,
    workspaceBgImg: getCloudAssets(`/images/pages/landing_combo/workspace-1.png`),
    workspacePadding: `6% 29% 10% 8%`,
    originalImg: getCloudAssets(`/images/pages/landing_combo/w-o-2.png`),
    effectImg: getCloudAssets(`/images/pages/landing_combo/w-e-2.png`),
    aspectRatio: "47 / 36"
  },
  {
    reserve: true,
    workspacePadding: `6% 29% 10% 8%`,
    workspaceBgImg: getCloudAssets(`/images/pages/landing_combo/workspace-7.png`),
    originalImg: getCloudAssets(`/images/pages/landing_combo/w-o-3.png`),
    effectImg: getCloudAssets(`/images/pages/landing_combo/w-e-3.png`),
    aspectRatio: "47 / 36"
  },
  {
    reserve: false,
    workspacePadding: `6% 40% 10% 19%`,
    workspaceBgImg: getCloudAssets(`/images/pages/landing_combo/workspace-5.png`),
    originalImg: getCloudAssets(`/images/pages/landing_combo/w-o-4.png`),
    effectImg: getCloudAssets(`/images/pages/landing_combo/w-e-4.png`),
    aspectRatio: "5 / 6"
  },
  {
    reserve: true,
    workspacePadding: `6% 29% 10% 8%`,
    workspaceBgImg: getCloudAssets(`/images/pages/landing_combo/workspace-6.png`),
    originalImg: getCloudAssets(`/images/pages/landing_combo/w-o-5.png`),
    effectImg: getCloudAssets(`/images/pages/landing_combo/w-e-5.png`),
    bImg: getCloudAssets(`/images/pages/landing_combo/bg-b-2.png`),
    bImgStyle: {
      position: "absolute",
      top: "2%",
      left: "-2%",
      width: "15%",
      "z-index": 2
    } as CSSProperties,
    aspectRatio: "47 / 36"
  },
  {
    reserve: false,
    workspacePadding: `6% 29% 12% 8%`,
    workspaceBgImg: getCloudAssets(`/images/pages/landing_combo/workspace-3.png`),
    originalImg: getCloudAssets(`/images/pages/landing_combo/w-o-6.png`),
    effectImg: getCloudAssets(`/images/pages/landing_combo/w-e-6.png`),
    aspectRatio: "47 / 36"
  }
]

function stringToArrList(str: string): string[] {
  if (!str.length) {
    return []
  }
  try {
    return JSON.parse(str)
  } catch (e) {
    return [str]
  }
}

export function portraitHelpFn(list: any[]): IPortraitProps[] {
  return PortraitItemList.map((_list, i) => {
    return {
      ..._list,
      title: useTranslateI18n(`landing.featureIntroduction[${i}].title`),
      desc: useTranslateI18n(`landing.featureIntroduction[${i}].description`),
      descList:
        landingLocale.landing.featureIntroduction[i]?.list &&
        Array.isArray(landingLocale.landing.featureIntroduction[i]?.list)
          ? landingLocale.landing.featureIntroduction[i]?.list.map((text, index) =>
              useTranslateI18n(`landing.featureIntroduction[${i}].list[${index}]`)
            )
          : ""
    }
  })
}

// 顶部滑杆图
export const topPortraitObj = {
  workspaceBgImg: getCloudAssets(`/images/pages/landing_combo/workspace-top.png`),
  workspacePadding: `6% 31% 12% 18%`,
  originalImg: getCloudAssets(`/images/pages/landing_combo/w-o-1.png`),
  effectImg: getCloudAssets(`/images/pages/landing_combo/w-e-1.png`),
  bImg: getCloudAssets(`/images/pages/landing_combo/bg-b-1.png`),
  bImgStyle: {
    position: "absolute",
    bottom: "0px",
    right: "15%",
    width: "19%",
    "z-index": 2
  } as CSSProperties,
  aspectRatio: "47 / 36"
}

// 推广链接错误 枚举
export enum ErrorTipType {
  Expired = 1,
  Failed = 2,
  Failed2 = 3,
  Failed3 = 4
}

export const coupon = `OFFICIALEVOTO`

// 推广链接错误弹窗文案列表分类
export const errorTipList = (code = coupon) => {
  return {
    [ErrorTipType.Expired]: {
      title: useTranslateI18n(`landing.landingCombo.errorDialog[0].title`),
      desc: useTranslateI18n(`landing.landingCombo.errorDialog[0].desc`)
    },
    [ErrorTipType.Failed]: {
      title: useTranslateI18n(`landing.landingCombo.errorDialog[1].title`),
      desc: useTranslateI18n(`landing.landingCombo.errorDialog[1].desc`)
    },
    [ErrorTipType.Failed2]: {
      title: useTranslateI18n(`landing.landingCombo.errorDialog[2].title`),
      desc: useTranslateI18n(`landing.landingCombo.errorDialog[2].desc`)
    },
    [ErrorTipType.Failed3]: {
      title: useTranslateI18n(`landing.landingCombo.errorDialog[3].title`),
      desc: useTranslateI18n(`landing.landingCombo.errorDialog[3].desc`, [code])
    }
  }
}

// 推广链接按钮点击状态 provide
export const landingBtnProvide: InjectionKey<landingBtnProvideType> = Symbol("landingBtnProvide_1")
