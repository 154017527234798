export enum ComboApi {
  GET_PRODUCT_LIST = "/v1/app/product/list",
  GET_PRODUCT_MY = "/v1/api/product/my",
  POST_VITEQR_LINK = "/v1/api/trade/create",
  POST_VITEQR_CUSTOM_LINK = "/v1/api/trade/custom_link_create",
  POST_TRADE_QUERY = "/v1/api/trade/query",
  GET_DISCOUNT = "/v1/api/trade/get_discount",
  GET_DISCOUNT_V2 = "/v1/api/payment/get_discount",
  GET_LANDING_COMBO = "/v1/app/product/get",
  GET_DOWNLOAD_COMBO = "/v1/app/product/get_by_id",
  POST_PRICING_TYPE = "/v1/api/user/update_pricing_type",
  GET_USER_PAYMENT_METHOD = "/v1/api/payment/get_method",
  POST_AIRWALLEX_PAYMENT_QUERY = "/v1/api/payment/query",
  POST_AIRWALLEX_PAYMENT_CREATE = "/v1/api/payment/create",
  GET_NEW_PRODUCT_SUB_LIST_V2 = "/v2/app/product/subscription/plan/list",
  GET_NEW_PRODUCT_SUB_LIST_V2_BY_REFERRAL = "/v2/app/product/subscription/plan/referral/list",
  GET_NEW_PRODUCT_SUB_LIST_STATUS = "/v2/api/subscription/status",
  GET_NEW_PRODUCT_SUB_LIST_OTHER_LIST = "/v2/app/product/subscription/package/list",
  GET_NEW_SUB_MY_INFO = "/v2/api/subscription/info",
  POST_NEW_SUB_BUY_TOP_UP = "/v2/api/subscription/buy_top_up", // 新版订阅购买补充包
  POST_NEW_SUB_UPDATE = "/v2/api/subscription/update", // 新版订阅升降级
  POST_NEW_SUB_RECOVER = "/v2/api/subscription/recover", // 恢复订阅
  POST_NEW_SUB_CANCEL = "/v2/api/subscription/cancel", // 取消订阅
  GET_DISCOUNT_ACTIVITY = "/v1/web/get_discount_activity", // 获取活动-？ 万圣节 黑五。
  POST_BLACK_FRIDAYS_EMAIL = "/v2/api/subscription/contact", // 黑五购买成功 用户点击按钮发送邮件
  DELETE_CARD_INFO = "/v2/api/subscription/delete_payment" // 删除卡信息
}
