export const paymentSubPageConfig = () => {
  return {
    newSub: {
      com: `newSub`,
      layout: "default"
    },
    sub: {
      com: "sub",
      layout: "default"
    },
    blackFriday: {
      com: "blackFriday",
      layout: "default"
    }
  }
}

export const paymentComboPageConfig = () => {
  return {
    combo: {
      com: `combo`,
      layout: "default"
    },
    blackFriday: {
      com: "blackFriday",
      layout: "default"
    }
  }
}

export enum IErrorPaymentType {
  VerCouponCode = 5053
}
