<template>
  <CommonDialog v-model="showDialog" popper-class="payment-vn_dialog" :dialog-style="dialogStyle">
    <div class="payment-vn-content">
      <div class="pkg-control pkg-methods">
        <div class="top-payment-content">
          <span class="title">{{ useTranslateI18n("profile.billingUsage.billing.cardData[2].title") }}</span>
          <div v-show="!showCancelBtn" class="radio-control">
            <!-- saved card -->
            <div v-if="savedCardDetail.is_saved_card" class="saved-card-wrapper">
              <el-radio-group v-model="savedCardValue" @change="paymentValue = null">
                <el-radio :label="1">
                  <div class="save-card-item">
                    <div class="save-card-item__left_show">
                      <CommonImage :src="getCloudAssets(`/images/common/pay-icons/saved_card_visa.png`)" />
                    </div>
                    <div class="save-card-item__right_show">
                      <span class="save-card-item__right_show_title">{{
                        useTranslateI18n("payment.comboSub.t29")
                      }}</span>
                      <span class="save-card-item__right_show_card">{{ savedCardDetail.card_label }}</span>
                    </div>
                  </div>
                </el-radio>
              </el-radio-group>

              <div class="saved-card-wrapper-flag">
                <span>{{ useTranslateI18n("login.signIn.or") }}</span>
              </div>
            </div>
            <el-radio-group v-model="paymentValue">
              <el-radio v-for="(obj, i) in selectRadioArr" :key="i" :label="obj.label" @change="savedCardValue = null">
                <CommonImage :src="obj.src" class="radio-payment-img" />
              </el-radio>
            </el-radio-group>
          </div>
          <div v-show="showCancelBtn" class="discount-input">
            <span class="add-discount">{{ useTranslateI18n(`payment.comboPayment.vnDialog.addDis`) }}</span>
            <el-input
              ref="inputRef"
              v-model.trim="hiddenValue"
              :style="{ width: 0, height: 0, opacity: 0, position: 'fixed', left: '-10000px' }"
              type="text"
              class="country-number"
              clearable="clearable"
            ></el-input>

            <el-input
              ref="inputRef"
              v-model.trim="result_coupon_code"
              type="text"
              autocomplete="new-password"
              class="country-number"
              clearable="clearable"
            ></el-input>
            <transition name="fade">
              <span v-show="discountValueErrorMsgShow" class="error-tip">{{
                useTranslateI18n(`payment.comboPayment.vnDialog.errorTip`)
              }}</span>
            </transition>
          </div>
        </div>
        <div class="bottom-submit">
          <!-- 同意 通知 -->
          <div v-if="!showCancelBtn && !current_plan" class="check-policy">
            <span class="tip">
              {{ useTranslateI18n(`payment.comboPayment.paymentTip1`) }}
            </span>
            <div class="check">
              <el-checkbox v-model="checkPolicy" size="large">
                <span class="check-text">
                  {{ useTranslateI18n(`payment.comboPayment.zhidao`) }}
                </span>
              </el-checkbox>
              <transition name="fade">
                <div v-show="showCheckPolicyTip" class="policy-tip">
                  <Union />
                  <span>
                    {{ useTranslateI18n(`payment.comboPayment.confirm`) }}
                  </span>
                </div>
              </transition>
            </div>
          </div>
          <CommonButton ref="submitRef" :button-style="submitStyle" class="submit-btn" @btn-click="submitClick">
            <CommonAnimationScrollText>
              {{ useTranslateI18n(`payment.comboPayment.vnDialog.cBtn`) }}
            </CommonAnimationScrollText>
          </CommonButton>
          <CommonButton
            v-if="showCancelBtn"
            ref="cancelBtnRef"
            :button-style="cancelStyle"
            class="cancel-btn"
            @btn-click="cancelClick"
          >
            <CommonAnimationScrollText>
              {{ useTranslateI18n(`payment.comboPayment.vnDialog.cancel`) }}
            </CommonAnimationScrollText>
          </CommonButton>
          <span v-if="!showCancelBtn" class="add-discount" @click="addClick">{{
            useTranslateI18n(`payment.comboPayment.vnDialog.addDis`)
          }}</span>
        </div>
      </div>

      <div class="pkg-content pkg-show-details">
        <div v-if="!current_plan" class="pkg-content-c">
          <!-- 支付信息 -->
          <div class="pkg-show-details-wrapper">
            <span class="title">{{ useTranslateI18n(`payment.comboPayment.vnDialog.title`) }}</span>
            <span class="pkg-title">
              <!-- {{ useTranslateI18n(`payment.comboPayment.paddleDialog.package`) }} -->
              {{ topTip }}
            </span>
            <span class="pkg-name">{{ orderInfo.name }}</span>
            <div class="period">{{ useTranslateI18n(`payment.paddleDialog.period`, [dateStr]) }}</div>
            <div class="credits email">
              <div class="total-c">{{ useTranslateI18n("payment.account") }}</div>
              <div class="one-c">
                <span>{{ topTitle }}</span>
              </div>
            </div>
            <div class="credits">
              <div class="total-c">
                {{ useTranslateI18n(`payment.paddleDialog.credits`, [useNumFormat(orderInfo.credits)]) }}
              </div>
              <div v-if="defaultDiscount || topUpDiscount" class="one-c">
                <span class="num">{{ priceDetail.perMoney }}</span>
                <span class="unit">&nbsp;{{ useTranslateI18n(`payment.comboPayment.paddleDialog.unit`) }}</span>
              </div>

              <div v-if="!defaultDiscount && !topUpDiscount" class="one-c">
                <span class="num">{{ orderInfo.singlePrice }}</span>
                <span class="unit">&nbsp;{{ useTranslateI18n(`payment.comboPayment.paddleDialog.unit`) }}</span>
              </div>
            </div>

            <!-- total -->
            <div class="total">
              <span class="t">{{ useTranslateI18n(`payment.paddleDialog.total`) }}</span>
              <span class="t-t">{{ orderInfo.price }}</span>
            </div>

            <!-- 补充包单独优惠 -->
            <div v-if="topUpDiscount" class="credits discount">
              <span class="t">{{ useTranslateI18n("payment.comboSub.t95") }}</span>
              <div class="one-c">
                <span class="num">-{{ topUpDiscount }}</span>
              </div>
            </div>

            <!-- discount -->
            <div v-if="defaultDiscount" class="credits discount">
              <div class="total-c">
                <CloseFl />
                <span class="total-c-co">
                  {{ coupon_code }}
                </span>
                <Close class="total-c-cl" @click="closeCoupon" />
              </div>
              <div class="one-c">
                <span class="num">-{{ defaultDiscount }}</span>
              </div>
            </div>

            <!-- sub-total -->
            <div v-if="defaultDiscount || topUpDiscount" class="total">
              <span class="t">{{ useTranslateI18n(`payment.paddleDialog.total1`) }}</span>
              <span class="t-t">{{ priceDetail.total }}</span>
            </div>
          </div>
          <!-- 描述信息 -->
          <div class="bottom-desc-list-wrapper">
            <div v-if="Number(orderInfo.credits) >= 9000 && showComboDesc" class="pkg-t-list">
              <span>{{ useTranslateI18n(`payment.paddleDialog.tip1`) }}</span>
              <span>{{ useTranslateI18n(`payment.paddleDialog.tip2`) }}</span>
            </div>

            <div v-if="showAfterSubDesc" class="sub-list">
              <div v-for="(item, i) in comboSubDescList" :key="i" class="sub-list-item">
                <div class="item-t" v-html="item"></div>
              </div>
            </div>

            <slot name="bottomDesc"></slot>
          </div>
        </div>

        <div v-if="current_plan" class="pkg-content-c">
          <RenderCom
            :coupon_code="coupon_code"
            :current_plan="current_plan"
            :compare_state="compare_state"
            :price-detail="priceDetail"
            :next_plan="orderInfo"
          />
        </div>
      </div>
    </div>
  </CommonDialog>
</template>

<script setup lang="tsx">
import { CSSProperties } from "vue"
import { ElRadio, ElRadioGroup, ElInput } from "element-plus"
import variables from "@/assets/scss/responsiveExport.module.scss"
import { ElCheckbox } from "element-plus"
import Union from "@/assets/icons/common/union-2.svg"
import { useUser } from "~/store/user"
import { IUserPaymentMethodObj, IUserPaymentPlatform } from "@/composables/api/combo"
import Close from "~~/assets/icons/common/payment-close.svg"
import CloseFl from "~~/assets/icons/common/payment-c-icon.svg"
import PagesComboSubUpgradeNoCard from "@/components/pages/comboSub/SubUpgradeNoCard.vue"
import { ICardDetailData } from "@/types/components/pages/combo/comboCardT"

const use_user = useUser()

const { comboSubDescList, executeSubDescListEvent } = useComboSubListenDom()

interface IProps {
  compare_state?: INewCompareState
  topTip: string
  showComboDesc?: boolean
  showAfterSubDesc: boolean
  isBackUnit: boolean | string
  current_plan: (ICardDetailData & IComboProductListObj) | (ICardDetailData & INewComboProductListObj)
  orderInfo: (ICardDetailData & IComboProductListObj) | (ICardDetailData & INewComboProductListObj)
  radioSelectList: IUserPaymentMethodObj[]
  showAddDiscount: boolean
  customLinkUuid?: any
  callback: (...args: any[]) => any
}
const props = withDefaults(defineProps<IProps>(), {
  showAfterSubDesc: () => {
    return false
  },
  orderInfo: () => {
    return {} as (ICardDetailData & IComboProductListObj) | (ICardDetailData & INewComboProductListObj)
  },
  isBackUnit: () => false,
  showComboDesc: () => true,
  topTip: () => useTranslateI18n(`payment.paddleDialog.sub`),
  showAddDiscount: () => true,
  customLinkUuid: () => "",
  compare_state: () => null
})

const topTitle = computed(() => {
  return props?.orderInfo.email || use_user.userEmail || `+${use_user.userInfo.phone_cc} ${use_user.userInfo.phone}`
})

const RenderCom = (
  props: {
    current_plan: (ICardDetailData & IComboProductListObj) | (ICardDetailData & INewComboProductListObj)
    next_plan: (ICardDetailData & IComboProductListObj) | (ICardDetailData & INewComboProductListObj)
    compare_state: INewCompareState
    coupon_code: string
    priceDetail: typeof priceDetail
  },
  ctx
) => {
  let comDict = {
    [INewCompareState.Upgrade]: PagesComboSubUpgradeNoCard
  }
  let com = comDict[props.compare_state as INewCompareState]
  return com ? <com {...props} onCloseCoupon={closeCoupon} /> : null
}

let showDialog = ref(true)
let inputRef = ref()

let hiddenValue = ref("")

// saved card 信息
let savedCardDetail = reactive({
  is_saved_card: false,
  card_label: ""
})

const closeDialog = () => {
  showDialog.value = false
}

let dialogStyle = reactive<CSSProperties>({
  width: 860,
  backgroundColor: "#fff",
  padding: "40px"
})

const dateStr = computed(() => {
  let nextDate = new Date()
  nextDate.setFullYear(nextDate.getFullYear() + 1)
  if (props.orderInfo?.period_start && props.orderInfo?.period_end) {
    return `${getLocalDateString(props?.orderInfo?.period_start, "YYYY/MM/DD")}-${getLocalDateString(
      props?.orderInfo?.period_end,
      "YYYY/MM/DD"
    )}`
  }
  return `${getLocalDateString(new Date(), "YYYY/MM/DD")} - ${getLocalDateString(nextDate, "YYYY/MM/DD")}`
})

const usePaymentSelectHooks = () => {
  let selectRadioArr = ref<({ label: string; src: string } & IUserPaymentMethodObj)[]>([])
  let paymentValue = ref(props.radioSelectList[0].key)
  let savedCardValue = ref(null)
  function initSelectRadio() {
    props.radioSelectList.map((pay) => {
      if (!pay.is_saved_card) {
        selectRadioArr.value.push({
          ...{ label: pay.key, src: getCloudAssets(`/images/common/pay-icons/${pay.key}.svg`) },
          ...pay
        })
      } else {
        savedCardDetail.is_saved_card = pay.is_saved_card
        savedCardDetail.card_label = `**** **** **** ${pay.card_tail}(${pay.card_expires})`
      }
    })
  }
  return {
    initSelectRadio,
    savedCardValue,
    paymentValue,
    selectRadioArr
  }
}

const useCheckPolicyBox = () => {
  let checkPolicy = ref(false)
  let showCheckPolicyTip = ref(false)
  watch(checkPolicy, (newValue) => {
    if (newValue) {
      showCheckPolicyTip.value = false
    }
  })
  return {
    checkPolicy,
    showCheckPolicyTip
  }
}

const useDiscountHooks = () => {
  let coupon_code = ref<string>(props.orderInfo?.coupon_code)

  let result_coupon_code = ref<string>("")

  let discountValueErrorMsgShow = ref(false)
  let priceDetail = reactive({
    total: "",
    perMoney: "",
    top_up_discount: "",
    top_up_percentage: "",
    discount: "",
    percentage: ""
  })

  const topUpDiscount = computed(() => {
    return priceDetail.top_up_discount
  })

  const defaultDiscount = computed(() => {
    return priceDetail.discount
  })

  const setInitPriceDetail = (init = false) => {
    if (init) {
      // 优惠后的总价
      priceDetail.total = props.orderInfo.u_discount_price
      // 优惠后的单价
      priceDetail.perMoney = props.orderInfo.u_discount_per_credit_price
      // 优惠卷优惠金额
      priceDetail.discount = props.orderInfo.u_discount_amount
      // 优惠卷优惠比例
      priceDetail.percentage = props.orderInfo.u_discount_percentage
      // 补充包优惠
      priceDetail.top_up_discount = props.orderInfo.u_top_up_discount_amount
      // 补充包优惠比例
      priceDetail.top_up_percentage = props.orderInfo.u_top_up_discount_percentage
    } else {
      priceDetail.total = ""
      priceDetail.perMoney = ""
      priceDetail.discount = ""
      priceDetail.percentage = ""
      priceDetail.top_up_discount = ""
      priceDetail.top_up_percentage = ""
    }
  }

  //  coupon_code默认初始化
  if (props.orderInfo.coupon_code || props.orderInfo.u_top_up_discount_amount) {
    setInitPriceDetail(true)
  }

  const discountChange = async (fl = false) => {
    submitRef.value && submitRef.value.openLoading()
    try {
      let data = await postPaymentPolyDiscount({
        product_id: props.orderInfo.id,
        coupon_code: fl ? unref(coupon_code) : unref(result_coupon_code),
        custom_link_uuid: props.customLinkUuid || undefined
      })
      if (!data.message) {
        discountValueErrorMsgShow.value = false

        priceDetail.total = props.isBackUnit
          ? `${useNumFormat(data.discount_price)}${data.currency_char}`
          : data.currency_char + useNumFormat(data.discount_price)

        priceDetail.perMoney = props.isBackUnit
          ? `${useNumFormat(data.discount_per_credit_price)}${data.currency_char}`
          : data.currency_char + useNumFormat(data.discount_per_credit_price)

        priceDetail.discount = data.discount_amount
          ? props.isBackUnit
            ? `${useNumFormat(data.discount_amount)}${data.currency_char}`
            : data.currency_char + useNumFormat(data.discount_amount)
          : ""

        priceDetail.percentage = data.discount_percentage ? `${data.discount_percentage}%` : ""

        priceDetail.top_up_discount = data.top_up_discount_amount
          ? props.isBackUnit
            ? `${useNumFormat(data.top_up_discount_amount)}${data.currency_char}`
            : data.currency_char + useNumFormat(data.top_up_discount_amount)
          : ""
        priceDetail.top_up_percentage = data.top_up_discount_percentage ? `${data.top_up_discount_percentage}%` : ""

        if (!fl) {
          coupon_code.value = result_coupon_code.value
        }

        return true
      }
      discountValueErrorMsgShow.value = true
    } catch (e) {
      discountValueErrorMsgShow.value = true
    } finally {
      closeLoading()
    }
  }

  const closeCoupon = useDebounceFn(() => {
    coupon_code.value = ""
    discountChange(true)
  }, 300)

  return {
    discountValueErrorMsgShow,
    coupon_code,
    result_coupon_code,
    priceDetail,
    defaultDiscount,
    topUpDiscount,
    setInitPriceDetail,
    discountChange,
    closeCoupon
  }
}

const useBtnClickHooks = () => {
  const submitStyle = reactive({
    width: "100%",
    height: "42px",
    borderRadius: "60px",
    fontSize: "16px",
    color: "#000",
    backgroundColor: variables.bgColor
  })

  const cancelStyle = reactive({
    width: "100%",
    height: "42px",
    borderRadius: "60px",
    fontSize: "16px",
    color: "#000",
    backgroundColor: "rgba(0, 0, 0, 0.1)"
  })

  const showCancelBtn = ref(false)

  let submitRef = ref()

  function closeLoading() {
    submitRef.value && submitRef.value.closeLoading()
  }

  const submitClick = useDebounceFn(async function () {
    let orderInfo = props.orderInfo
    let pay_config = selectRadioArr.value.find((pay) => pay.key === paymentValue.value)

    function next() {
      submitRef.value && submitRef.value.openLoading()
      props.callback &&
        props.callback({
          coupon_code: coupon_code.value,
          discount_price: priceDetail.discount,
          discount_percentage: priceDetail.percentage,
          top_up_discount_price: priceDetail.top_up_discount,
          top_up_discount_percentage: priceDetail.top_up_percentage,
          value: priceDetail.total || props.orderInfo.price,
          per_value: priceDetail.perMoney || props.orderInfo.singlePrice,
          orderInfo,
          pm_id: unref(paymentValue),
          pay_config,
          is_saved_card: !!savedCardValue.value,
          current_plan: props.current_plan,
          compare_state: props.compare_state,
          closeLoading,
          closeDialog
        })
    }

    if (!unref(showCancelBtn)) {
      if (!unref(checkPolicy) && !props.current_plan) {
        showCheckPolicyTip.value = true
        useAgreeSubmitDialog({
          text: useTranslateI18n(`payment.comboPayment.paymentTip1`),
          width: 502,
          submit(closeDialog) {
            checkPolicy.value = true
            next()
            closeDialog()
          }
        })
        return
      }
      next()
    } else {
      if (!unref(result_coupon_code)) {
        discountValueErrorMsgShow.value = true
        return
      }
      // 有设置优惠码传优惠码
      let data = await discountChange()
      if (!data) {
        return
      }
      showCancelBtn.value = false
    }
  }, 200)

  const cancelClick = () => {
    showCancelBtn.value = false
    discountValueErrorMsgShow.value = false
    result_coupon_code.value = ""
  }
  const addClick = useDebounceFn(async () => {
    showCancelBtn.value = true
    await nextTick(() => {})
    inputRef.value && inputRef.value.focus()
  }, 300)

  return {
    submitStyle,
    cancelStyle,
    showCancelBtn,
    submitClick,
    cancelClick,
    addClick,
    submitRef,
    closeLoading
  }
}

const { initSelectRadio, paymentValue, selectRadioArr, savedCardValue } = usePaymentSelectHooks()

const {
  coupon_code,
  discountValueErrorMsgShow,
  priceDetail,
  topUpDiscount,
  defaultDiscount,
  discountChange,
  result_coupon_code,
  closeCoupon
} = useDiscountHooks()

const { submitStyle, cancelStyle, showCancelBtn, submitClick, cancelClick, addClick, submitRef, closeLoading } =
  useBtnClickHooks()

const { checkPolicy, showCheckPolicyTip } = useCheckPolicyBox()

initSelectRadio()

onMounted(() => {
  setTimeout(() => {
    executeSubDescListEvent()
  })
})
</script>

<style lang="scss" scoped>
.payment-vn-content {
  display: flex;
  color: #000;
  font-size: 14px;
  line-height: normal;
  min-height: 450px;
  > div {
    width: 50%;
  }
  .title {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    display: flex;
    @include fontSemibold;
  }

  .pkg-content {
    display: flex;
    flex-direction: column;
    .pkg-content-c {
      display: flex;
      flex-direction: column;
    }
    .pkg-title {
      color: rgba(0, 0, 0, 0.55);
    }
    .pkg-name {
      font-size: 24px;
      line-height: 29px;
      margin-top: 4px;
      @include fontSemibold;
    }
    .period {
      margin-top: 24px;
    }
    .credits {
      margin-top: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &.email {
        flex-wrap: wrap;
      }
      .one-c {
        .num {
          font-size: 16px;
          @include fontSemibold;
          line-height: 19px;
        }
        .unit {
          color: rgba(0, 0, 0, 0.55);
        }
      }
    }
    .discount {
      .one-c {
        .num {
          color: #d25f00;
        }
      }
      .total-c {
        padding: 4px;
        border-radius: 6px;
        background: #fff7e8;
        display: flex;
        align-items: center;
        .total-c-co {
          font-size: 12px;
          color: #d25f00;
          font-family: "Roboto-Medium";
          margin: 0 4px;
        }
        .total-c-cl {
          cursor: pointer;
        }
      }
    }
    .total {
      margin-top: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .t {
      }
      .t-t {
        font-size: 16px;
        line-height: 19px;
        @include fontSemibold;
      }
    }
  }

  .bottom-desc-list-wrapper {
    margin-top: 18px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    .pkg-t-list {
      padding-top: 17px;
      padding-bottom: 24px;
      display: flex;
      flex-direction: column;
      font-size: 12px;
      line-height: normal;
      color: #000;
      > span:nth-child(2n) {
        margin-top: 12px;
      }
    }
    :deep(.item-b-wrapper) {
      color: rgba(0, 0, 0, 0.55);
      font-size: 14px;
      line-height: 22px;
      .item-b {
        color: rgba(0, 0, 0, 0.85);
      }
    }

    .sub-list {
      display: flex;
      flex-direction: column;
      &-item {
        font-size: 12px;
        line-height: normal;
        color: rgba(0, 0, 0, 0.55);
        margin-bottom: 12px;
        display: flex;
        &::before {
          content: "";
          margin-top: 5px;
          width: 4px;
          height: 4px;
          border-radius: 50%;
          background-color: rgba(0, 0, 0, 0.55);
          display: inline-block;
          margin-right: 8px;
          flex-shrink: 0;
        }
        &:nth-last-child(1) {
          margin-bottom: 0;
        }
        :deep(.paddle-payment-sub-b) {
          color: rgba(0, 0, 0, 0.85);
          @include fontMedium;
          .paddle-payment-sub-click {
            text-decoration: underline;
            cursor: pointer;
          }
        }
        :deep(.paddle-payment-sub-b-2) {
          color: rgba(0, 0, 0, 0.55);
          @include fontSemibold;
          .paddle-payment-sub-click {
            text-decoration: underline;
            cursor: pointer;
          }
        }
        :deep(.paddle-payment-sub-b-t1) {
          text-decoration: underline;
          cursor: pointer;
        }
        :deep(.paddle-payment-sub-b-t2) {
          text-decoration: underline;
          cursor: pointer;
        }
        :deep(.paddle-payment-sub-b-t3) {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }

  .pkg-control {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .top-payment-content {
    }

    .radio-control {
      .radio-payment-img {
        width: 76px;
        height: 32px;
      }
      :deep(.el-radio-group) {
        label.el-radio {
          margin-bottom: 16px;
          margin-right: 18px;
          height: 36px;
          .el-radio__inner {
            width: 20px;
            height: 20px;
            &::after {
              width: 7px;
              height: 7px;
            }
          }
        }
        label.el-radio:nth-child(3n) {
          margin-right: 0;
        }
      }

      @include device-max-width-600 {
        :deep(.el-radio-group) {
          label.el-radio {
            margin-right: 12px;
          }
        }
      }

      .saved-card-wrapper {
        .saved-card-wrapper-flag {
          width: 100%;
          height: 17px;
          position: relative;
          margin: 16px 0;
          &::before {
            content: "";
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            position: absolute;
            width: 100%;
            height: 0.5px;
            background-color: rgba(0, 0, 0, 0.3);
          }
          > span {
            color: rgba(0, 0, 0, 0.3);
            height: 17px;
            line-height: 17px;
            font-weight: 400;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            padding: 0 8px;
            background-color: #fff;
          }
        }
        :deep(.el-radio-group) {
          width: 100%;
          .el-radio {
            width: 100%;
            margin-right: 0;
            margin-bottom: 0;
            height: auto;
            .el-radio__label {
              flex: 1;
              .save-card-item {
                padding: 12px 16px;
                font-size: 12px;
                font-family: "Roboto-Regular";
                line-height: normal;
                display: flex;
                align-items: center;
                background-color: #f2f4ff;
                .save-card-item__left_show {
                  width: 32px;
                  height: 32px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  border-radius: 50%;
                  background-color: #fff;
                  .img-load {
                    width: 22px;
                    height: auto;
                  }
                }
                .save-card-item__right_show {
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  margin-left: 16px;
                  &_title {
                    color: rgba(0, 0, 0, 0.55);
                  }
                  &_card {
                    margin-top: 4px;
                    color: #000;
                  }
                }

                @include device-max-width-375 {
                  .save-card-item__right_show {
                    margin-left: 5px;
                  }
                }
              }
            }
          }
        }
      }
    }
    .bottom-submit {
      display: flex;
      flex-direction: column;
      align-items: center;
      .check-policy {
        width: 100%;
        background: #ffece8;
        border-radius: 8px;
        padding: 16px;
        margin-top: 36px;
        margin-bottom: 11px;
        display: flex;
        flex-direction: column;
        .tip {
          font-size: 14px;
          line-height: 17px;
          color: #16181a;
        }
        .check {
          display: flex;
          align-items: center;
          margin-top: 16px;
          :deep(.el-checkbox) {
            height: unset;
            color: #16181a;
            .el-checkbox__input.is-checked + .el-checkbox__label {
              color: #16181a;
            }
            .el-checkbox__label {
              padding-left: 6px;
            }
            .el-checkbox__inner {
              width: 16px;
              height: 16px;
              background-color: transparent;
              border-color: #16181a;
            }
            .el-checkbox__inner::after {
              border-color: #16181a;
              left: 5px;
              width: 4px;
              top: 1px;
            }
          }
          .policy-tip {
            padding-left: 9px;
            display: flex;
            align-items: center;
            border-left: 1px solid rgba(0, 0, 0, 0.12);
            color: #fc5555;
            font-size: 14px;
            margin-left: 4px;
            line-height: 17px;
            > span {
              margin-left: 4px;
              flex: 1;
            }
          }
        }
      }
      .submit-btn {
        position: sticky;
        bottom: 0;
        z-index: 1;
      }
      .cancel-btn {
        margin-top: 10px;
      }
      .add-discount {
        font-size: 16px;
        line-height: 19px;
        color: #3083ff;
        text-decoration: underline;
        margin-top: 17px;
        cursor: pointer;
      }
    }
    .discount-input {
      margin-top: 26px;
      display: flex;
      flex-direction: column;
      .add-discount {
        font-size: 16px;
        line-height: 19px;
        margin-bottom: 10px;
      }
      .error-tip {
        margin-top: 10px;
        font-size: 12px;
        line-height: 14px;
        color: #fc5555;
      }
      :deep(.el-input) {
        height: 36px;
        .el-input__wrapper {
          border: 1px solid rgba(0, 0, 0, 0.3);
          border-radius: 8px;
          box-shadow: none;
          .el-input__inner {
            color: #000;
          }
        }
      }
    }
    &.pkg-control-b {
      :deep(.el-radio-group) {
        margin: 20px 0;
      }
    }
  }

  .pkg-methods {
    border-right: 0.5px solid rgba(0, 0, 0, 0.15);
    padding-right: 40px;
    .title {
      margin-bottom: 24px;
    }
  }
  .pkg-show-details {
    padding-left: 40px;
    .pkg-show-details-wrapper {
      display: flex;
      flex-direction: column;
      padding-bottom: 18px;
      border-bottom: 0.5px solid rgba(0, 0, 0, 0.15);
      .title {
        margin-bottom: 24px;
        display: none;
      }
    }
  }
}

@include device-max-width-custom(960px) {
  .payment-vn-content {
    display: flex;
    flex-direction: column;
    flex-direction: column-reverse;
    > div {
      width: 100%;
    }
    .pkg-methods {
      border-right: 0;
      padding-right: 0;
      .top-payment-content {
        .discount-input {
          margin-bottom: 26px;
        }
      }
    }
    .pkg-show-details {
      margin-bottom: 20px;
      padding-left: 0;
      .pkg-show-details-wrapper {
        .title {
          display: flex;
        }
      }
    }
  }
}
</style>

<style lang="scss">
.welcome-page-dialog {
  &.payment-vn_dialog {
    @include device-max-width-custom(960px) {
      .content {
        width: 80%;
      }
    }

    @include device-max-width-600 {
      .content {
        width: 80%;
        .content-d {
          width: 100% !important;
        }
      }
    }
    @include device-max-width-375 {
      .content {
        width: 75%;
        .content-d {
          padding: 20px !important;
        }
        .close-icon {
          right: -32px;
        }
      }
    }
  }
}
</style>
