<template>
  <div class="cancel-card-before-dialog">
    <div class="t1">{{ useTranslateI18n(`payment.comboSub.t91`) }}</div>
    <div class="content">
      <span class="title">{{ useTranslateI18n(`payment.comboSub.t92`) }}</span>
      <span class="content-c">
        {{ `****${subscription_info_v2_web.card_tail} | Expires ${subscription_info_v2_web.expires}` }}
      </span>
      <div class="bg">
        <CommonImage :src="bg" />
      </div>
    </div>

    <div class="control-btn">
      <CommonButton :button-style="cancelStyle" @btn-click="cancelClick">
        <CommonAnimationScrollText>{{
          useTranslateI18n(`profile.billingUsage.sub.cancelDialog.btn[0]`)
        }}</CommonAnimationScrollText>
      </CommonButton>
      <CommonButton ref="btnRef" :button-style="submitStyle" @btn-click="submitClick">
        <CommonAnimationScrollText>{{ useTranslateI18n(`login.email.done`) }}</CommonAnimationScrollText>
      </CommonButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useUser } from "@/store/user"
import { getCloudAssets } from "~~/utils"
import variables from "@/assets/scss/responsiveExport.module.scss"

let bg = getCloudAssets("/images/common/cancel-card-icon1.png")

const { subscription_info_v2_web } = storeToRefs(useUser())

const emits = defineEmits(["cancelClick", "submitClick"])

const submitStyle = reactive({
  width: "100%",
  height: "42px",
  borderRadius: "60px",
  fontSize: "14px",
  color: "rgba(0, 0, 0, 0.85)",
  backgroundColor: variables.bgColor
})

const cancelStyle = reactive({
  width: "100%",
  height: "42px",
  borderRadius: "60px",
  fontSize: "14px",
  color: "rgba(0, 0, 0, 0.85)",
  backgroundColor: "rgba(0, 0, 0, 0.05)"
})

let btnRef = ref()

const closeLoading = () => {
  btnRef.value && btnRef.value.closeLoading()
}

const openLoading = () => {
  btnRef.value && btnRef.value.openLoading()
}

const cancelClick = () => {
  emits("cancelClick")
}

const submitClick = () => {
  openLoading()
  emits("submitClick", { closeLoading })
}
</script>

<style scoped lang="scss">
.cancel-card-before-dialog {
  display: flex;
  flex-direction: column;
  line-height: normal;
  color: #000;
  .t1 {
    @include fontSemibold;
    font-size: 24px;
  }
  .content {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    padding: 16px;
    border-radius: 8px;
    opacity: 0.8;
    background: #dbebff;
    position: relative;
    .title {
      font-size: 16px;
      @include fontMedium;
    }
    .content-c {
      margin-top: 13px;
      font-size: 14px;
    }
    .bg {
      position: absolute;
      bottom: 0;
      display: flex;
      width: 126px;
      height: auto;
      aspect-ratio: 126/57;
      left: 99px;
    }
  }
  .control-btn {
    display: flex;
    margin-top: 40px;
    .common-button {
      flex: 1;
      &:nth-last-child(1) {
        margin-left: 8px;
      }
    }
  }
}
</style>
