// 广告来源
import { IReferrerParams, IRefererUUid } from "@/constant/referrer"
import { LocalStorageKey, SessStorageKey } from "@/constant/cache"
import { PAYMENT_COMBO_PROCEED_ROUTE, PAYMENT_PAGE_PATH, PAYMENT_COMBO_SUB } from "~/constant/route"
import { blackSourceDict, appBlackSourceDict, useAllActivitys } from "~/constant/useAllActivitys"

// 这个来源在落地页购买页面，记录google tt fb
export const useReferrer = () =>
  useState("referrer", () => getLocalStorage(LocalStorageKey.TEMP_AD_CHANNEL_P) || undefined)

export const useReferrer2 = () =>
  useState("referrer2", () => getLocalStorage(LocalStorageKey.TEMP_AD_CHANNEL_P2) || undefined)

// 获取购买来源,有储存渠道uuid的使用渠道uuid，app内购买的使用app
export const usePurchaseReferer = () => {
  const { _p } = useRoute().query
  const isClientInlinePage = computed(() => {
    const { $bridge } = useNuxtApp()
    return !!($bridge && Object.keys($bridge).length)
  })
  if (isClientInlinePage.value) {
    return IRefererUUid.app
  } else if (_p && _p === "app_login") {
    return IRefererUUid.app
  } else {
    return useReferrer2().value || useReferrer().value || IRefererUUid.web
  }
}

export const useRefWebRefferer = () => {
  const { hallows_open } = useAllActivitys()
  const { query, path } = useRoute()
  const { _p } = query
  const isClientInlinePage = computed(() => {
    const { $bridge } = useNuxtApp()
    return !!($bridge && Object.keys($bridge).length)
  })
  if (isClientInlinePage.value || _p === "app_login") {
    return unref(hallows_open) ? appBlackSourceDict[process.env.NUXT_APP_ENV].sId : IRefererUUid.app
  } else if (
    path === unref(PAYMENT_COMBO_PROCEED_ROUTE) ||
    path === unref(PAYMENT_PAGE_PATH) ||
    path === unref(PAYMENT_COMBO_SUB)
  ) {
    // 如果是特殊的payment 落地页的话，取落地页的来源值
    return query._uuid && query.sId
      ? useReferrer2().value || useReferrer().value || "undefined"
      : unref(hallows_open)
      ? blackSourceDict[process.env.NUXT_APP_ENV].sId
      : IRefererUUid.web
  } else {
    return useReferrer2().value || useReferrer().value || "undefined"
  }
}

// 落地页商家来源名称（一级，二级）记录
export const useChannelGroupName = () =>
  useState<{ channel: string; subchannel: string }>("channelGroup", () => {
    return {} as { channel: string; subchannel: string }
  })

// 判断是否referrer2 来源是否错误
export const useReferrer2IsCorrect = () => useState("useReferrer2IsCorrect", () => true)

export const useSetReferrer = (query: Record<string, unknown>) => {
  const referrer = useReferrer()
  const queryKeys = Object.keys(query)
  const matchKeys = Object.keys(IReferrerParams)
  for (const key of queryKeys) {
    for (const matchKey of matchKeys) {
      if (key === matchKey) {
        referrer.value = IReferrerParams[key]
        setLocalStorage(LocalStorageKey.TEMP_AD_CHANNEL_P, referrer.value)
        return
      }
    }
  }
}

export const useGetChannel = () => {
  const hasChannelNames = computed(() => !!Object.keys(unref(useChannelGroupName())).length)

  const channel = computed(() => unref(useChannelGroupName()).channel)
  const subchannel = computed(() => unref(useChannelGroupName()).subchannel)

  async function setChannelGroupName(sId: string) {
    const { first_name, second_name } = await getOrgChannelByUuid(sId)
    useChannelGroupName().value.channel = first_name
    useChannelGroupName().value.subchannel = second_name
  }

  return {
    channel,
    subchannel,
    hasChannelNames,
    setChannelGroupName
  }
}
