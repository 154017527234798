import { IClickType } from "@/constant/index"

export enum navBarLinkKey {
  Features = "_Features",
  Community = "_Community",
  Pricing = "_Pricing",
  Download = "_Download",
  Blog = "_Blog",
  Forum = "_Forum"
}

export enum featuresCategory {
  ProTools = "Pro Tools",
  BatchEdit = "Batch Edit",
  PhotographyBusiness = "Photography Business"
}

export interface INavBarLinkObj {
  name?: string
  path?: string
  key?: string
  type: IClickType
  slotName?: string
  children?: INavBarLinkObj[]
  category?: string // 分类
  index?: number // 索引顺序
  target?: string
  [x: string]: any
}

export type INavBarLinkData = INavBarLinkObj[]
