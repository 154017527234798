<script lang="tsx">
import { PropType } from "vue"
import { INavBarLinkData, navBarLinkKey } from "@/types/components/common/navbar"
import { IClickType } from "@/constant/index"
import { navBarFeatureList } from "@/constant/navbar"
import { ALL_FEATURE } from "@/constant/route"
import CommonArrow from "@/assets/icons/common/arrow.svg"
import { ClientOnly, NuxtLink } from "#components"

export default defineComponent({
  name: "",
  props: {
    navList: {
      type: Array as PropType<INavBarLinkData>,
      default: () => {
        return []
      }
    }
  },
  emits: ["linkItemClick"],
  setup() {
    const dropDownKey = ref(0)
    return {
      dropDownKey
    }
  },
  methods: {
    linkItemClick(item, dropRefKey) {
      if (dropRefKey) {
        const dropdown = this.$refs["dropdownRef_" + dropRefKey]
        dropdown?.handleClose()
      }
      item && this.$emit("linkItemClick", item)
    },
    // 渲染features列表
    renderFeatureList(list, dropdownRef) {
      const featureList = navBarFeatureList(list)
      return (
        <div class="navbar-menu-list-container navbar-features-list-container">
          {Object.keys(featureList).map((_t, _i) => {
            return (
              <div class="features-list">
                <p class="list-category-title">{_t}</p>
                <el-divider />
                {featureList[_t].map((item, i) => {
                  return (
                    <div class="list-link-item" key={i}>
                      <NuxtLink
                        target={item.target}
                        to={item.path}
                        onClick={() => {
                          this.linkItemClick(item, dropdownRef)
                        }}
                      >
                        {item.name}
                        {item.tag && (
                          <span className={`feature-tag-${item.tag}`}>
                            {useTranslateI18n(`features.tag.${item.tag}`)}
                          </span>
                        )}
                      </NuxtLink>
                    </div>
                  )
                })}
                <div class="list-link-item see-all-item">
                  <NuxtLink
                    to={ALL_FEATURE.value + "#" + "category_" + _i}
                    onClick={() => {
                      this.linkItemClick("", dropdownRef)
                    }}
                  >
                    {useTranslateI18n("features.seeAll")}
                  </NuxtLink>
                </div>
              </div>
            )
          })}
        </div>
      )
    },
    renderChildList(list, dropdownRef) {
      return (
        <div class="navbar-menu-list-container">
          {list.map((item, i) => {
            return (
              <div class="list-link-item" key={i}>
                {item.type === IClickType.Skip && (
                  <NuxtLink
                    to={item.path}
                    target={item.target}
                    onClick={() => {
                      this.linkItemClick(item, dropdownRef)
                    }}
                  >
                    {item.name}
                  </NuxtLink>
                )}
                {item.type === IClickType.Click && (
                  <>
                    <span
                      onClick={() => {
                        this.linkItemClick(item, dropdownRef)
                      }}
                    >
                      {item.name}
                    </span>
                    {item.slotName && this.$slots[item.slotName](item)}
                  </>
                )}
                {item.type === IClickType.Hover && item.children && item.children.length && (
                  <el-dropdown
                    popper-class="link-drop-down_zz"
                    showTimeout={100}
                    ref={"dropdownRef_" + item.key}
                    trigger="hover"
                    key={item.key}
                    onVisibleChange={(visible) => {
                      if (visible) {
                        this.dropDownKey++
                      }
                    }}
                  >
                    {{
                      default: () => {
                        return (
                          <span class="el-dropdown-link">
                            {item.name}
                            <CommonArrow />
                          </span>
                        )
                      },
                      dropdown: () => {
                        return item.key === navBarLinkKey.Features ? (
                          <ClientOnly key={this.dropDownKey}>
                            {this.renderFeatureList(item.children, item.key)}
                          </ClientOnly>
                        ) : (
                          <ClientOnly key={this.dropDownKey}>
                            {this.renderChildList(item.children, item.key)}
                          </ClientOnly>
                        )
                      }
                    }}
                  </el-dropdown>
                )}
              </div>
            )
          })}
        </div>
      )
    }
  },
  render() {
    return <div class="navbar-menu-list-warpper">{this.renderChildList(this.navList)}</div>
  }
})
</script>

<style scoped lang="scss">
.navbar-menu-list-warpper {
  .navbar-menu-list-container {
    display: flex;
    align-items: center;
    .list-link-item {
      margin-right: 32px;
      font-size: 16px;
      font-weight: 400;
      cursor: pointer;
      display: flex;
      align-items: center;
      line-height: 21px;
      color: #000;
      position: relative;
      a {
        color: #000;
      }
      :deep(.el-dropdown) {
        line-height: 21px;
        display: flex;
        .el-dropdown-link {
          display: flex;
          align-items: center;
          font-size: 16px;
          font-weight: 400;
          outline: 0;
          color: #000;
          svg {
            transition: transform linear 0.2s;
          }
          &:hover {
            svg {
              transform: rotate(180deg);
            }
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
.el-popper.el-dropdown__popper.link-drop-down_zz {
  border: none;
  background: #ffffff;
  box-shadow: 0px 16px 40px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  .el-popper__arrow {
    display: none;
  }
  .navbar-menu-list-container {
    padding: 16px;
    .list-link-item {
      color: #000;
      font-weight: 400;
      font-size: 16px;
      line-height: 21px;
      display: flex;
      border-radius: 8px;
      transition: all 0.2s linear;
      cursor: pointer;
      &:hover {
        // background: $bg-color;
        background: rgba(0, 0, 0, 0.03);
      }
      a,
      span {
        color: #000;
        padding: 12px 16px;
      }
    }
    .features-list {
      .list-link-item {
        a {
          display: flex;
          align-items: center;
          font-size: 14px;
        }
        &:hover {
          background: rgba(0, 0, 0, 0.03);
        }
        .feature-tag-New,
        .feature-tag-Hot {
          border-radius: 3px;
          padding: 2px 4px;
          font-family: Roboto-Medium;
          font-size: 11px;
          font-style: normal;
          // font-weight: 600;
          line-height: 12px;
          margin-left: 4px;
        }
        .feature-tag-New {
          background: #ffe050;
          color: #000;
        }
        .feature-tag-Hot {
          background: linear-gradient(180deg, #ff4a31 0%, #ff8831 100%);
          color: #fff;
        }
      }
      .see-all-item {
        a {
          color: rgba(0, 0, 0, 0.55);
          &:hover {
            color: #2771ff;
          }
        }
      }
    }
  }
  .navbar-features-list-container {
    display: flex;
    padding: 0px;
    .features-list {
      padding: 20px;
      .list-category-title {
        padding: 12px 16px;
        color: #000;
        font-family: Roboto-Semibold;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
      .el-divider--horizontal {
        margin: 8px auto;
        width: calc(100% - 32px);
        border-color: rgba(0, 0, 0, 0.2);
        border-width: 1px;
        transform: scaleY(0.5);
      }
    }
  }
}
</style>
