import PaddelDialog from "@/components/pages/payment/PaddelDialog.vue"
import { createVNode, render } from "vue"
import { ICardDetailData } from "@/types/components/pages/combo/comboCardT"

interface IPaddleOptions {
  current_plan?: (ICardDetailData & IComboProductListObj) | (ICardDetailData & INewComboProductListObj)
  compare_state?: INewCompareState
  coupon_code?: string
  subTotal?: string
  subPerTotal?: string
  discount_price?: string
  discount_percentage?: number | string
  isNewSub?: boolean
  trade_no?: string
  checkout_url?: string
  isPassThrough?: boolean
  isRedeem?: boolean
  isBackUnit?: boolean | string // 有值单位就是放后面
  uuid?: string // 记录落地页购买次数需要
  char?: string
  orderInfo?: (ICardDetailData & IComboProductListObj) | (ICardDetailData & INewComboProductListObj)
  showDialog?: boolean
  topTip?: string
  unit?: string
  showBottomTip?: boolean
  isPointer?: boolean
  paddleNoLoginPassThroughUUid?: string
  landingBfConfig?: {
    gift_card_receiver: string
    sender_name: string
  }
  showBeforeDesc?: boolean
  showAfterSubDesc?: boolean
  top_up_discount_price?: string
  top_up_discount_percentage?: string
  paymentSuccess?: (...args: any[]) => any
  paymentFail?: (...args: any[]) => any
  onClose?: (...args: any[]) => any
}

let showPaddleDialog = false

export const usePaddleDialog = (options: IPaddleOptions = {}) => {
  if (showPaddleDialog) {
    return
  }
  const showDialog = ref(true)
  const vm = createVNode(PaddelDialog, {
    ...options,
    showDialog,
    onClose() {
      showDialog.value = false
      showPaddleDialog = false
    }
  })
  const { vueApp } = useNuxtApp()
  vm.appContext = vueApp._context
  render(vm, document.createElement("div"))
  showPaddleDialog = true
}
