import { CookiesKey } from "~/constant/cookies"

export enum LocalStorageKey {
  AllowCookiesPrivacy = "a_c_p_2",
  IsAllowCookies = "_is_a_c_p_2",
  SignInApp = "s_i_a", // evoto客户端登陆标识
  TEMP_AD_CHANNEL_P = "_t_ad_c_p", // 储存进入官网的渠道来源参数值
  TEMP_AD_CHANNEL_P2 = "_t_ad_c_p2", // 储存进入官网的渠道来源参数值
  Download = "dl_c", // 抢先版下载次数
  SERVER_PAYMENT_EVENT_ID = "s_p_e_id", // 上报服务端购买事件的 唯一id
  REDEEM_CODE = "redeem_code", // 兑换码
  IS_SET_REG_PSD = "_is_set_reg_psd", // 判断是否设过置了密码
  PAYMENT_PRODUCT_ID = "_p_r_id", // 记录套餐id
  OTHER_SIGNUP_DIALOG = "_o_sign_d" // 邮箱注册成功弹窗进入个人中心
}

export enum SessStorageKey {
  Language = "l_l", // 当地语言环境
  TEMP_PAYMENT_LOGIN = "_t_p_l", // 是否在定价页面登陆？ 埋点需要
  COUNTDOWN_TIMESTAMP = "c_t",
  TEMP_INVITEE_SUCCESS = "t_i_s", // 邀请活动 -》 注册成功的标识 -》  使用后删除
  TEMP_INVITEE_SUCCESS_GOOGLE = "t_i_s_g", // 邀请活动 google登录成功会重定向一次，避免第一次把状态拿到 -》 使用后删除
  TEMP_INVITEE_FAILED = "t_i_f", // 邀请活动-》三方注册失败，已存在用户 标记 -》使用后删除
  HIDE_ANNO_FLAG = "h_a_f", // 隐藏顶部诈骗提示
  IS_SUCCESS_PASSION_ORDER = `is_true_ps_order`, // 标记是否支付一笔passion订单成功了,就上报一次sharesales埋点，暂时性记录Passion订单id
  SHARESALES_FLAG = "share_f", // 标记sharesales进来的，保存到session
  CAPTCHA_TOKEN = "_captcha_tk", // 临时记录 人机验证 token
  CHANNEL_HISTORY = "_c_h_k", // 记录用户进入官网的渠道来源
  IS_BAD_DIALOG = "_bad_dialog", // 记录黑产用户是自己跳转到我的账单页面
  IS_SHOW_AUTO_SWITCH_LANGUAGE = "_is_show_s_language",
  IS_SHOW_REACTIVATED_DIALOG = "_is_show_reactivated_dialog",
  SSO_REDIRECT_URL = "_sso_r_u", // 记录sso重定向url
  REFERRAL_RECORD_LOGIN = "_referral_record_login"
}

export enum myInfoKey {
  SIDE_UUID = "_get_oauth_temp_uuid",
  TEMP_REDIRECT = "_redirect", // 三方登陆记录当前fullPath
  TEMP_OTHER_LINk_SOURCE = "_t_o_l_s", // 三方登陆来源是fb或者google
  AGREE_EMAIL_SUB = "a_e_s" // 是否同意邮件订阅协议
}
