import PaymentVnDialog from "@/components/pages/payment/PaymentVnDialog.vue"
import { createVNode, render, VNode } from "vue"
import { IOrderInfo } from "@/types/components/pages/payment/paymentVnDialog"
import { IUserPaymentMethodObj } from "@/composables/api/combo"

export const useSelectPaymentDialog = (options: {
  coupon_code?: string
  customLinkUuid?: any
  topTip: string
  showComboDesc?: boolean
  showAfterSubDesc?: boolean
  orderInfo?: IOrderInfo
  current_plan?: IOrderInfo
  compare_state?: INewCompareState
  radioSelectList?: IUserPaymentMethodObj[]
  isBackUnit?: boolean | string
  showAddDiscount?: boolean
  bottomDescSlot?: (...args: any[]) => VNode
  callback?: (...args: any[]) => any
}) => {
  const {
    orderInfo,
    radioSelectList,
    isBackUnit,
    callback,
    showAfterSubDesc,
    showComboDesc,
    topTip,
    showAddDiscount,
    customLinkUuid,
    coupon_code,
    current_plan,
    compare_state
  } = options
  const vm = createVNode(
    PaymentVnDialog,
    {
      coupon_code,
      customLinkUuid,
      topTip,
      showComboDesc,
      showAfterSubDesc,
      orderInfo,
      current_plan,
      compare_state,
      radioSelectList,
      isBackUnit,
      showAddDiscount,
      callback
    },
    {
      bottomDesc: options.bottomDescSlot
    }
  )
  const { vueApp } = useNuxtApp()
  vm.appContext = vueApp._context
  render(vm, document.createElement("div"))
}
