import validate from "/usr/src/nuxt-app/node_modules/nuxt/dist/pages/runtime/validate.js";
import router_45global from "/usr/src/nuxt-app/middleware/router.global.ts";
import manifest_45route_45rule from "/usr/src/nuxt-app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  router_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "blog-redirect": () => import("/usr/src/nuxt-app/middleware/blogRedirect.ts"),
  download: () => import("/usr/src/nuxt-app/middleware/download.ts"),
  invitation: () => import("/usr/src/nuxt-app/middleware/invitation.ts"),
  "ipad-redirect": () => import("/usr/src/nuxt-app/middleware/ipadRedirect.ts"),
  landing: () => import("/usr/src/nuxt-app/middleware/landing.ts"),
  "landing-k": () => import("/usr/src/nuxt-app/middleware/landingK.ts"),
  "landing-shot": () => import("/usr/src/nuxt-app/middleware/landingShot.ts"),
  "na-redirect": () => import("/usr/src/nuxt-app/middleware/naRedirect.ts"),
  "new-home": () => import("/usr/src/nuxt-app/middleware/newHome.ts"),
  "no-show-nav-and-footer": () => import("/usr/src/nuxt-app/middleware/noShowNavAndFooter.ts"),
  "payment-combo": () => import("/usr/src/nuxt-app/middleware/paymentCombo.ts"),
  "payment-new-sub": () => import("/usr/src/nuxt-app/middleware/paymentNewSub.ts"),
  "payment-sub": () => import("/usr/src/nuxt-app/middleware/paymentSub.ts"),
  "payment-sub2": () => import("/usr/src/nuxt-app/middleware/paymentSub2.ts"),
  profile: () => import("/usr/src/nuxt-app/middleware/profile.tsx")
}