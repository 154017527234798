import { getFAQListReq } from "~~/composables/api/help"
import { faqType, gitBookType } from "~~/constant/pages/help"

type FAQItemType = {
  title: string
  content: string
}

interface HelpState {
  helpFAQList: Array<FAQItemType>
  pricingFAQList: Array<FAQItemType>
  subPricingFAQList: Array<FAQItemType>
  changeSkinColoeFAQList: Array<FAQItemType>
  referralFAQList: Array<FAQItemType>
  docLink: string
  calendly: string
  quickStart: string
}
export const useHelpStore = defineStore("help", () => {
  const helpState = reactive<HelpState>({
    helpFAQList: [],
    pricingFAQList: [],
    subPricingFAQList: [],
    changeSkinColoeFAQList: [],
    referralFAQList: [],
    docLink: "https://help.evoto.ai",
    calendly: "https://meetings.hubspot.com/evoto-lisa/csm-meeting",
    quickStart: ""
  })

  const { usePtag, useH2Tag, useDivTag, useUlTag, useLiTag, useATag, useSpanTag } = useTransTag()

  const formatDate = (data, className = "") => {
    const list = splitData(data)
    const dataList = []
    for (let i = 0; i < list.length; i++) {
      dataList.push({
        title: list[i].title,
        value: (i + 1).toString(),
        content: () => {
          return h("div", {
            innerHTML: traversalDom(list[i].data),
            class: className
          })
        }
      })
    }
    return dataList
  }

  const traversalDom = (data: any) => {
    let dom = ""
    for (let i = 0; i < data.length; i++) {
      if (data[i].nodes) {
        dom += getTag(data[i].type, traversalDom(data[i].nodes), data[i].data)
      } else {
        dom += getTag(data[i].type, data[i].leaves.map((leave) => leave.text).join(""), data[i].data)
      }
    }
    return dom
  }

  const getTag = (type: gitBookType, content: string, attribute: object) => {
    if (type === gitBookType.SPAN) {
      return usePtag(content)
    } else if (type === gitBookType.H2) {
      return useH2Tag(content)
    } else if (type === gitBookType.UL) {
      return useUlTag(content)
    } else if (type === gitBookType.LI) {
      return useLiTag(content)
    } else if (type === gitBookType.A) {
      return useATag(content, attribute)
    } else {
      return useSpanTag(content)
    }
  }

  const splitData = (data) => {
    let list = []
    let obj = {
      title: "",
      data: []
    }
    for (let i = 0; i < data.length; i++) {
      if (data[i].type === gitBookType.H1) {
        list.push(obj)
        obj = {
          title: "",
          data: []
        }
        obj.title = data[i].nodes[0].leaves[0].text
      } else {
        obj.data.push(data[i])
      }
    }
    list.push(obj)
    list = list.slice(1, list.length)
    return list
  }

  const getHelpFAQList = async () => {
    const res = await getFAQListReq({ id: faqType.help })
    const dataStr = res.data
    helpState.docLink = res.meta.url
    const data = JSON.parse(dataStr)
    helpState.helpFAQList = formatDate(data)
  }

  const getPricingFAQList = async () => {
    const res = await getFAQListReq({ id: faqType.pricing })
    const dataStr = res.data
    helpState.docLink = res.meta.url
    const data = JSON.parse(dataStr)
    const className = "collapse-child-content"
    helpState.pricingFAQList = formatDate(data, className)
  }

  const getQuickStart = async () => {
    const res = await getFAQListReq({ id: faqType.quickStart })
    helpState.quickStart = res.meta.url
  }

  const getSubPricingFaqList = async () => {
    const res = await getFAQListReq({ id: faqType.subPricing })
    const dataStr = res.data
    helpState.docLink = res.meta.url
    const data = JSON.parse(dataStr)
    const className = "collapse-child-content"
    helpState.subPricingFAQList = formatDate(data, className)
  }

  const getReferralFaqList = async () => {
    // const res = await getFAQListReq({ id: faqType.referral })
    const res = await getFAQListReq({ id: faqType.referralCode })
    const dataStr = res.data
    const data = JSON.parse(dataStr)
    // 可以在服务端不进行缓存
    window && (helpState.referralFAQList = formatDate(data))
    return formatDate(data)
  }

  return {
    helpState,
    getHelpFAQList,
    getPricingFAQList,
    getQuickStart,
    getSubPricingFaqList,
    getReferralFaqList
  }
})
