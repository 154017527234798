import { ResizeSensor } from "css-element-queries"

export type scrollItemType = {
  id: string
  extraHeight?: number
  translate?: number
  eventHandler?: () => any
  halfScreenEventHandler?: () => any
}

export const useNavBarScroll = (scrollList: Array<scrollItemType>) => {
  const { y } = useWindowScroll()

  let halfScreenHeight: any = 0

  let extraHeightTotal = 0

  let scrollTopList = reactive<Array<number>>([])
  const currentIndex = ref(0)
  const halfIndex = ref(-1)

  const setScrollData = () => {
    scrollTopList = []
    extraHeightTotal = 0
    for (let i = 0; i < scrollList.length; i++) {
      if (!document.getElementById(scrollList[i].id)) {
        continue
      }
      const offsetTop = document.getElementById(scrollList[i].id)?.offsetTop
      let value = offsetTop ? offsetTop + extraHeightTotal : extraHeightTotal
      value = scrollList[i].translate ? value + (scrollList[i].translate as number) : value
      scrollTopList.push(value)
      const height = scrollList[i].extraHeight ? scrollList[i].extraHeight : 0
      extraHeightTotal += height as number
    }
    halfScreenHeight = window.innerHeight / 2
    scrollEvent()
  }

  const flag1 = new Date()
  const flag2 = new Date()

  let callBack1: any = null
  let callBack2: any = null

  const scrollEvent = () => {
    const scrollTop = y.value
    const list = scrollTopList.filter((v) => {
      return v <= scrollTop
    })
    const halfList = scrollTopList.filter((v) => {
      return v <= scrollTop + halfScreenHeight
    })
    if (currentIndex.value != list.length - 1) {
      callBack1 = flag1
    }

    if (halfIndex.value != halfList.length - 1) {
      callBack2 = flag2
    }
    currentIndex.value = list.length - 1
    halfIndex.value = halfList.length - 1

    if (callBack1 == flag1) {
      callBack1 = scrollList[currentIndex.value]?.eventHandler
      callBack1 && callBack1()
    }
    if (callBack2 == flag2) {
      callBack2 = scrollList[halfList.length - 1].halfScreenEventHandler
      callBack2 && callBack2()
    }
  }

  watch(
    () => y.value,
    (newValue, oldValue) => {
      scrollEvent()
    },
    { immediate: true }
  )

  onMounted(() => {
    const body = document.getElementsByTagName("body")[0] as HTMLElement

    new ResizeSensor(body, () => {
      setScrollData()
    })
    setScrollData()
  })

  return {}
}
