import { createVNode, render, CSSProperties } from "vue"
import CountDownDialog from "~~/components/pages/download/CountDownDialog.vue"

type countDownDialogParams = {
  zIndex?: number
  popperClass?: string
  width?: number
  headTitle?: string
  headDesc?: string
  contentDesc?: string
  submitBtnText?: string
  cancelBtnText?: string
  submitCallback?: ({ closeLoading, closeDialog }: { closeLoading: () => any; closeDialog: () => any }) => any
  cancelCallback?: ({ closeLoading, closeDialog }: { closeLoading: () => any; closeDialog: () => any }) => any
  btnDisabled?: { submit: boolean; cancel: boolean }
  contentSlots?: ({ close }: { close: () => any }) => VNode
  showCloseIcon?: boolean | Ref<boolean>
  close?: (...args: any[]) => any
  loadSuccess?: (...args: any[]) => any
  dialogWrapperStyle?: CSSProperties
  closeTimeout?: number
}
export function useCountDownDialog(options: countDownDialogParams = {}) {
  const vm = createVNode(CountDownDialog, {
    ...options
  })
  const { vueApp } = useNuxtApp()
  vm.appContext = vueApp._context
  render(vm, document.createElement("div"))
}
