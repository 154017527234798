// feature映射Key (对应图片文件夹)
export const featureKey = {
  changeSkinColor: "changeSkinColor",
  backgroundRemoval: "backgroundRemoval",
  portraitRetouching: "portraitRetouching",
  hairColorChanger: "hairColorChanger",
  glareRemoval: "glareRemoval",
  blemishRemoval: "blemishRemoval",
  headshot: "headshot",
  smileGeneration: "smileGeneration",
  imageCropper: "imageCropper",
  batchEdits: "batchEdits",
  clothesWrinkleRemover: "clothesWrinkleRemover",
  tetheringShooting: "tetheringShooting",
  wedding: "wedding",
  presetPhotoEditor: "presetPhotoEditor",
  bodyEditor: "bodyEditor",
  skyReplacement: "skyReplacement",
  colorMatch: "colorMatch",
  doubleChinRemover: "doubleChinRemover",
  allFeatures: "allFeatures"
}
// 各feature页跳转下载携带参数
export const toDownloadQueryDict = {
  "/features/change-skin-color": "sId=naudfdyg&_uuid=gczqghbp",
  "/features/background-removal-service": "sId=dycbwkx2&_uuid=hqnkaunm",
  "/features/portrait-retouching": "_uuid=pnsdxsqf&sId=puqg7k3d",
  "/features/hair-color-changer": "_uuid=ikzsqbum&sId=vvvrnjud",
  "/features/photo-glare-remover": "_uuid=xd9uqfmd&sId=kxwxjbth",
  "/features/blemish-removal": "_uuid=pczrhtqw&sId=5ufprbga",
  "/features/headshot-photography": "_uuid=dlndyigx&sId=vb8oxm6y",
  "/features/change-smile-filter": "_uuid=yjqyvagt&sId=bjcb9m6z",
  "/features/ai-crop-image": "_uuid=zk4b5q6x&sId=93hwhnmk",
  "/features/batch-edits": "_uuid=jj2mjnsy&sId=sminku7q",
  "/features/remove-wrinkles-from-clothes": "_uuid=xpnep5h9&sId=z5i2yywx",
  "/features/tethering-shooting-software": "_uuid=mzc6a6mu&sId=hpvujhha",
  "/features/wedding-photography": "_uuid=hlhhcfxl&sId=gcqaatkw",
  "/features/preset-photo-editor": "_uuid=zlwzla4p&sId=9eevtjpp",
  "/features/body-editor": "_uuid=gfhwkfad&sId=yrdafdux",
  "/features/sky-replacement": "_uuid=5gu7ehrh&sId=kg8xjtgh",
  "/features/ai-color-match": "_uuid=neomkt9y&sId=x3kcytjg",
  "/features/double-chin-remover": "_uuid=b984mhhj&sId=rffvarkv",
  "/features": "_uuid=m5goaxjz&sId=n44sspgy"
}
// 直接下载的feature
export const directDownloadFeature = ["/features/hair-color-changer"]
// 有试用的feature
export const hasTrialFeature = [
  featureKey.backgroundRemoval,
  featureKey.hairColorChanger,
  featureKey.glareRemoval,
  featureKey.blemishRemoval,
  featureKey.smileGeneration,
  featureKey.imageCropper,
  featureKey.clothesWrinkleRemover,
  featureKey.skyReplacement,
  featureKey.colorMatch
]
// 标签
export enum featureTag {
  hot = "Hot",
  new = "New"
}
// feature 页面首屏颜色分类
export enum HeroBgColor {
  PORTRAIT = "linear-gradient(180deg, rgba(39, 113, 255, 0.20) 0%, rgba(39, 113, 255, 0.00) 100%)", // 人像
  ONLINE = "linear-gradient(180deg, rgba(120, 56, 255, 0.20) 0%, rgba(120, 56, 255, 0.00) 100%)", // 联机
  BACKGROUND = "linear-gradient(180deg, rgba(255, 122, 0, 0.20) 0%, rgba(255, 122, 0, 0.00) 100%)", // 背景
  CLOTHES = "linear-gradient(180deg, rgba(60, 56, 255, 0.20) 0%, rgba(60, 56, 255, 0.00) 100%)", // 服装
  CROP = "linear-gradient(180deg, rgba(22, 157, 255, 0.20) 0%, rgba(22, 157, 255, 0.00) 100%)", // 裁剪
  COLOR = "linear-gradient(180deg, rgba(255, 63, 51, 0.20) 0%, rgba(255, 63, 51, 0.00) 100%)" // 颜色
}
// feature 页面navbar颜色分类
export enum NavBgColor {
  PORTRAIT = "#D7E3FF", // 人像
  ONLINE = "#E5D8FF", // 联机
  BACKGROUND = "#FFE5CD", // 背景
  CLOTHES = "#D8D8FF", // 服装
  CROP = "#D1ECFF", // 裁剪
  COLOR = "#FFD9D6" // 颜色
}
// 卡片颜色分类
export enum CardBgColor {
  PORTRAIT = "linear-gradient(180deg, rgba(39, 113, 255, 0.20) 0%, rgba(39, 113, 255, 0.10) 100%)", // 人像
  ONLINE = "linear-gradient(180deg, rgba(60, 56, 255, 0.15) 0%, rgba(60, 56, 255, 0.10) 100%)", // 联机
  BACKGROUND = "linear-gradient(180deg, rgba(255, 122, 0, 0.20) 0%, rgba(255, 122, 0, 0.10) 100%)", // 背景
  COLOR = "linear-gradient(180deg, rgba(255, 63, 51, 0.20) 0%, rgba(255, 63, 51, 0.10) 100%)", // 颜色
  CLOTHES = "linear-gradient(180deg, rgba(60, 56, 255, 0.20) 0%, rgba(60, 56, 255, 0.10) 100%)", // 服装
  CROP = "linear-gradient(180deg, rgba(22, 157, 255, 0.20) 0%, rgba(22, 157, 255, 0.10) 100%)" // 裁剪
}
export enum IErrorType {
  unFormatCode = 3001, // 上传图片格式不符合
  noFaceCode = 3003
}
