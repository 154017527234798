import { INavBarLinkData } from "@/types/components/common/navbar"
import { IClickType } from "@/constant/index"
import { navBarLinkKey, featuresCategory } from "@/types/components/common/navbar"
import { featureKey, hasTrialFeature, featureTag } from "@/constant/newFeature"
import { ILocaleValue } from "~/constant/i18n-country"
import {
  BACKGROUND_ADJUSTMENTS,
  BATCH_EDITS,
  COLOR_AND_TONE,
  DOWNLOAD_PAGE_PATH,
  EXCLUSIVE_PRESETS,
  HELP_PAGE_PATH,
  PAYMENT_COMBO_ROUTE,
  CHANGE_SKIN_COLOR,
  BACKGROUND_REMOVAL,
  HAIR_COLOR_CHANGER,
  PORTRAIT_RETOUCHING,
  GLARE_REMOVAL,
  BLEMISH_REMOVAL,
  HEADSHOT,
  SMILE_GENERATION,
  CROP_IMAGE,
  CLOTHES_WRINKLE_REMOVER,
  TETHERED_SHOOTING,
  WEDDING,
  PRESET_PHOTO_EDITOR,
  BODY_EDITOR,
  SKY_REPLACEMENT,
  COLOR_MATCH,
  DOUBLE_CHIN_REMOVAL,
  START_PAGE_PATH,
  PAYMENT_PAGE_PATH,
  BLOG_PAGE,
  IPAD_PAGE,
  REDIRECT_BLOG_PAGE
} from "./route"

const getExternalBlogLink = () => {
  const isBlogLanguageIndex = [ILocaleValue.de, ILocaleValue.fr].includes(useI18nCountry().value as ILocaleValue)
  return isBlogLanguageIndex ? `${REDIRECT_BLOG_PAGE}${useI18nCountry().value}` : REDIRECT_BLOG_PAGE
}

export const navbarLinkDict: () => INavBarLinkData = () => {
  return [
    {
      type: IClickType.Skip,
      path: unref(IPAD_PAGE)
    },
    {
      type: IClickType.Hover,
      key: navBarLinkKey.Features,
      children: [
        {
          path: unref(CHANGE_SKIN_COLOR),
          type: IClickType.Skip,
          category: featuresCategory.ProTools,
          index: 2,
          key: featureKey.changeSkinColor
        },
        {
          path: unref(BACKGROUND_REMOVAL),
          type: IClickType.Skip,
          category: featuresCategory.ProTools,
          index: 3,
          key: featureKey.backgroundRemoval
        },
        {
          path: unref(PORTRAIT_RETOUCHING),
          type: IClickType.Skip,
          category: featuresCategory.ProTools,
          index: 1,
          key: featureKey.portraitRetouching,
          tag: featureTag.hot
        },
        {
          path: unref(HAIR_COLOR_CHANGER),
          type: IClickType.Skip,
          category: featuresCategory.ProTools,
          index: 9,
          key: featureKey.hairColorChanger
        },
        {
          path: unref(GLARE_REMOVAL),
          type: IClickType.Skip,
          category: featuresCategory.ProTools,
          index: 4,
          key: featureKey.glareRemoval
        },
        {
          path: unref(BLEMISH_REMOVAL),
          type: IClickType.Skip,
          category: featuresCategory.ProTools,
          index: 7,
          key: featureKey.blemishRemoval
        },
        {
          path: unref(HEADSHOT),
          type: IClickType.Skip,
          category: featuresCategory.PhotographyBusiness,
          index: 1,
          key: featureKey.headshot
        },
        {
          path: unref(SMILE_GENERATION),
          type: IClickType.Skip,
          category: featuresCategory.ProTools,
          index: 8,
          key: featureKey.smileGeneration
        },
        {
          path: unref(CROP_IMAGE),
          type: IClickType.Skip,
          category: featuresCategory.BatchEdit,
          index: 3,
          key: featureKey.imageCropper
        },
        {
          path: unref(BATCH_EDITS),
          type: IClickType.Skip,
          category: featuresCategory.BatchEdit,
          index: 2,
          key: featureKey.batchEdits
        },
        // 菜单栏下线（保留进入）
        {
          path: unref(CLOTHES_WRINKLE_REMOVER),
          type: IClickType.Skip,
          category: featuresCategory.ProTools,
          key: featureKey.clothesWrinkleRemover,
          index: 8
        },
        {
          path: unref(TETHERED_SHOOTING),
          type: IClickType.Skip,
          category: featuresCategory.PhotographyBusiness,
          key: featureKey.tetheringShooting,
          index: 9,
          tag: featureTag.hot
        },
        {
          path: unref(WEDDING),
          type: IClickType.Skip,
          category: featuresCategory.PhotographyBusiness,
          index: 2,
          key: featureKey.wedding
        },
        {
          path: unref(PRESET_PHOTO_EDITOR),
          type: IClickType.Skip,
          category: featuresCategory.BatchEdit,
          index: 1,
          key: featureKey.presetPhotoEditor
        },
        {
          path: unref(BODY_EDITOR),
          type: IClickType.Skip,
          category: featuresCategory.ProTools,
          key: featureKey.bodyEditor,
          index: 5
        },
        {
          path: unref(SKY_REPLACEMENT),
          type: IClickType.Skip,
          category: featuresCategory.ProTools,
          key: featureKey.skyReplacement,
          index: 7
        },
        {
          path: unref(COLOR_MATCH),
          type: IClickType.Skip,
          category: featuresCategory.ProTools,
          index: 0,
          key: featureKey.colorMatch,
          tag: featureTag.new
        },
        {
          path: unref(DOUBLE_CHIN_REMOVAL),
          type: IClickType.Skip,
          category: featuresCategory.ProTools,
          key: featureKey.doubleChinRemover,
          index: 11
        },
        {
          path: unref(COLOR_AND_TONE),
          type: IClickType.Skip
        },
        {
          path: unref(BACKGROUND_ADJUSTMENTS),
          type: IClickType.Skip
          // category: featuresCategory.ProTools,
          // index: 8
        },
        {
          path: unref(EXCLUSIVE_PRESETS),
          type: IClickType.Skip
        }
      ]
    },
    {
      type: IClickType.Hover,
      key: navBarLinkKey.Community,
      children: [
        {
          type: IClickType.Click,
          key: navBarLinkKey.Forum
        },
        {
          path: unref(START_PAGE_PATH),
          type: IClickType.Skip
        },
        {
          path: getExternalBlogLink(),
          type: IClickType.Skip,
          target: "_blank",
          key: navBarLinkKey.Blog
        }
      ]
    },
    {
      path: unref(PAYMENT_PAGE_PATH),
      type: IClickType.Click,
      key: navBarLinkKey.Pricing,
      slotName: "payment"
    },
    {
      path: unref(HELP_PAGE_PATH),
      type: IClickType.Skip
    },
    {
      path: unref(DOWNLOAD_PAGE_PATH),
      key: navBarLinkKey.Download,
      type: IClickType.Skip
    }
  ]
}
export const navbarLink: (...args: any[]) => INavBarLinkData = () => {
  const list = navbarLinkDict()

  const result = list.map((item, i) => {
    return {
      name: useTranslateI18n(`common.navbarLink[${i}].name`),
      path: item.path,
      type: item.type,
      key: item.key,
      slotName: item.slotName,
      target: item.target,
      children:
        item.children &&
        item.children.map((cItem, index) => {
          const res = {
            name: useTranslateI18n(`common.navbarLink[${i}].children[${index}].name`),
            path: cItem.path,
            type: cItem.type,
            key: cItem.key,
            target: cItem.target
          }
          // 添加features的category
          if (item.key === navBarLinkKey.Features) {
            res["index"] = cItem.index
            res["tag"] = cItem.tag
            res["category"] = useTranslateI18n(`common.featuresCategory.${cItem.category}`)
          }
          return res
        })
    }
  })
  return result
}
// 不同设备的navabr的style 字典  超过1920取1920的样式
export const deviceDict = {
  1920: {
    startPaddingTop: 48,
    endPaddingTop: 24
  },
  600: {
    startPaddingTop: 16,
    endPaddingTop: 8
  }
}

export const NAV_ID = "nav-id"

export enum INavbarTheme {
  Dark = "dark",
  White = "white"
}
// 获取feature的字典
export const featureListDict: () => INavBarLinkData = () => {
  return navbarLinkDict()
    .filter((item) => {
      return item.key === navBarLinkKey.Features
    })[0]
    .children.map((_item, i) => {
      return {
        enName: useTranslateI18n(`common.navbarLink[1].children[${i}].name`, [], "", ILocaleValue.en), // 英文feature 的name
        name: useTranslateI18n(`common.navbarLink[1].children[${i}].name`), // 翻译feature 的name
        hasTrial: hasTrialFeature.includes(_item.key),
        ..._item
      }
    })
}
// 导航栏 feature 列表
export const navBarFeatureList = (list: INavBarLinkData) => {
  const featureList = list.reduce(
    (acc, item) => {
      // 不存在分类
      if (!item.category || item.category.startsWith("common.featuresCategory")) return acc
      if (!acc[item.category]) {
        acc[item.category] = []
      }
      acc[item.category].push(item)
      acc[item.category].sort((a, b) => {
        return a.index - b.index
      })
      return acc
    },
    {
      // 固定分类列顺序
      [useTranslateI18n("common.featuresCategory.Pro Tools")]: [],
      [useTranslateI18n("common.featuresCategory.Batch Edit")]: [],
      [useTranslateI18n("common.featuresCategory.Photography Business")]: []
    }
  )
  // 只截取前6个
  Object.keys(featureList).forEach((key) => {
    if (featureList[key].length > 6) {
      featureList[key] = featureList[key].slice(0, 6)
    }
  })
  return featureList
}
