<template>
  <div class="sign-in-or-sign-up-wrapper">
    <span class="t-1">
      {{ useTranslateI18n("login.newLogin.t1") }}
    </span>
    <span class="t-2" v-html="topTitle"></span>
    <CommonForm
      ref="formRef"
      :form-input-list="formInputList"
      :form-inline="false"
      :form-rules="formRules"
      form-label-position="top"
      :form-model="formModel"
    />

    <CommonButton ref="btnRef" class="btn1" :button-style="buttonStyle1" @btn-click="signInOrSignUpHandler"
      ><CommonAnimationScrollText>{{ useTranslateI18n("login.newLogin.t1") }}</CommonAnimationScrollText></CommonButton
    >

    <span v-if="!enterEmail" class="last-desc">
      {{ useTranslateI18n("login.newLogin.t44") }}
    </span>

    <div class="tip-desc">
      <span @click="recoveryEmail">{{ useTranslateI18n("login.signIn.trouble") }}</span>
    </div>

    <div class="other-login-in">
      <div class="text-flag">
        <span>{{ useTranslateI18n("login.signIn.or") }}</span>
      </div>
      <div class="btn-list-wrapper">
        <div v-for="(item, i) in loginButtonList" :key="i" class="btn-list-item" @click="otherLoginClick(item.action)">
          <CommonAnimationScrollText>
            <img :src="item.icon" alt="" />
            <span>{{ item.text }}</span>
          </CommonAnimationScrollText>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="tsx">
import { IFormPropsInputList } from "@/types/components/common/form"
import { FormRules } from "element-plus"
import { EMAIL_RE } from "@/constant/regex"
import { LOGIN_SCENE, Login_Method, SocialName } from "@/constant/login"
import { myInfoKey } from "@/constant/cache"
import { CookiesKey } from "~~/constant/cookies"
import GooleIcon from "@/assets/icons/common/goole-icon.png"
import FacebookIcon from "@/assets/icons/common/facebook-icon.png"
import { isNthBinaryDigitOne } from "@/utils/utils"

interface IProps {
  email: string
  phone: {
    countryValue: string
    inputValue: string
  }
}

const props = withDefaults(defineProps<IProps>(), {
  email() {
    return ""
  },
  phone() {
    return undefined
  }
})

const emits = defineEmits(["mainClick", "closeSingUp"])

// 判断选择方式 email or phone
let enterEmail = ref(!unref(props.phone))

const topTitle = computed(() => {
  return unref(enterEmail)
    ? useTranslateI18n("login.newLogin.t15", [{ type: "static", class: "t-2-l" }])
    : useTranslateI18n("login.newLogin.t16", [{ type: "static", class: "t-2-l" }])
})

const { _pointLoginAndRegFlowPageButtonView, _pointWebsitePopupView } = useCommonMp()
const { _pointLoginSignUpClick } = useStSdkCommonMp()

let formModel = reactive({
  email: "",
  phoneConfig: {}
})

const showEmailErrorObj = reactive({
  show: false,
  platform: 1,
  socialName: ""
})

const showEmailError = (type: Login_Method) => {
  showEmailErrorObj.show = true
  showEmailErrorObj.platform = type
  showEmailErrorObj.socialName = SocialName[type]
}

let user_exist = ref()

let is_set_psd = ref()

let is_mfa = ref()

const formRef = ref()

let formInputList = ref<IFormPropsInputList<typeof formModel>>([
  {
    type: "input",
    model: "email",
    showItem: computed(() => !!unref(enterEmail)),
    label: useTranslateI18n("login.label.email"),
    clearable: true,
    enterClick: true,
    enterClickFn: () => {
      signInOrSignUpHandler(false)
    },
    childSlots: (item) => {
      return (
        <div class="email-exist" vShow={showEmailErrorObj.show}>
          {
            <div
              onClick={emailExistHandler}
              v-html={useTranslateI18n(`login.signUp.errLogin2`, [
                {
                  text: useTranslateI18n(`login.signUp.errLogin3`, [showEmailErrorObj.socialName]),
                  class: "c-email-exist"
                }
              ])}
            ></div>
          }
        </div>
      )
    }
  },
  {
    type: "countrySelect",
    model: "phoneConfig",
    isRemotePhone: true,
    showItem: computed(() => !unref(enterEmail)),
    label: useTranslateI18n("login.newLogin.t17"),
    placeholder: useTranslateI18n("login.newLogin.t18")
  }
])

const validatorEmail = async (rule: any, value: any, callback: any) => {
  let regex = EMAIL_RE
  showEmailErrorObj.show = false
  if (!regex.test(value)) {
    return callback(new Error(useTranslateI18n("login.signIn.error")))
  } else {
    let loginInfo = await getLoginInfoApi({ email: formModel.email })
    let login_tag = loginInfo.login_tag
    let last_login = loginInfo.last_login
    user_exist.value = loginInfo.exist
    is_set_psd.value = isNthBinaryDigitOne(login_tag, 1)
    is_mfa.value = loginInfo.mfa
    // 如果是第三方登录了但是没有设置密码，提示
    if (isNthBinaryDigitOne(login_tag, 2) && !isNthBinaryDigitOne(login_tag, 1) && !isNthBinaryDigitOne(login_tag, 3)) {
      showEmailError(Login_Method.Google)
    } else if (
      isNthBinaryDigitOne(login_tag, 3) &&
      !isNthBinaryDigitOne(login_tag, 1) &&
      !isNthBinaryDigitOne(login_tag, 2)
    ) {
      showEmailError(Login_Method.Facebook)
    }
    return callback()
  }
}

const { validator: validatorP } = useValidatorInterPhone(true, (loginInfo) => {
  let login_tag = loginInfo.login_tag
  user_exist.value = loginInfo.exist
  is_set_psd.value = isNthBinaryDigitOne(login_tag, 1)
  is_mfa.value = loginInfo.mfa
})

let formRules = reactive<FormRules>({
  email: [
    {
      validator: validatorEmail as any,
      trigger: "blur"
    }
  ],
  phoneConfig: [
    {
      validator: validatorP,
      trigger: "blur"
    }
  ]
})

// 第三方登录点击
const otherLoginClick = useDebounceFn(async (id) => {
  let referral_code = useRoute().query.referral_code
  _pointLoginAndRegFlowPageButtonView({
    featureName: "main",
    elementName: id === Login_Method.Google ? "google_oauth" : "facebook_oauth",
    pageTitle: "main_register_page"
  })
  _pointLoginSignUpClick({
    element_name: id === Login_Method.Google ? "google_oauth" : "facebook_oauth",
    element_position: "popup",
    element_type: "button",
    popup_name: "login_or_signup",
    feature_module: "login_or_signup",
    feature_name: "main"
  })
  let data = await getOauthUrlApi({ type: id })
  let loginInfoCookie = useCookie(CookiesKey.TR_LOGIN_M)
  let referralCodeCookie = useCookie(CookiesKey.REFERRAL_CODE)
  if (referral_code) {
    referralCodeCookie.value = referral_code as string
  }
  // 记录临时重定向地址
  loginInfoCookie.value = JSON.stringify({
    [myInfoKey.SIDE_UUID]: data.uuid,
    [myInfoKey.TEMP_REDIRECT]: encodeURIComponent(useRoute().fullPath),
    [myInfoKey.TEMP_OTHER_LINk_SOURCE]: id,
    [myInfoKey.AGREE_EMAIL_SUB]: false
  })
  location.href = data.url
}, 300)

const buttonStyle1 = reactive({
  width: "100%",
  background: "#FFE050",
  color: `rgba(0, 0, 0, 0.85)`,
  borderRadius: "60px",
  height: "42px",
  fontSize: "14px",
  fontFamily: "Inter-Regular"
})

const loginButtonList = [
  {
    text: useTranslateI18n("login.signIn.btnText[0]"),
    icon: GooleIcon,
    action: Login_Method.Google
  },
  {
    text: useTranslateI18n("login.signIn.btnText[1]"),
    icon: FacebookIcon,
    action: Login_Method.Facebook
  }
]

const signInOrSignUpHandler = useDebounceFn(async (p = true) => {
  p &&
    _pointLoginAndRegFlowPageButtonView({
      featureName: "main",
      elementName: "login_or_signup",
      pageTitle: "main_register_page"
    })

  p &&
    _pointWebsitePopupView({
      featureModule: "login_registration",
      featureName: "main",
      pageTitle: "main_page"
    })
  p &&
    _pointLoginSignUpClick({
      element_name: "login_or_signup",
      element_position: "popup",
      element_type: "button",
      popup_name: "login_or_signup",
      feature_module: "login_or_signup",
      feature_name: "main"
    })

  if (formRef.value) {
    try {
      await formRef.value.validateField(enterEmail.value ? "email" : "phoneConfig")
      let emitResult = {
        exist: user_exist.value,
        is_set_psd: is_set_psd.value,
        is_mfa: is_mfa.value
      } as any
      if (enterEmail.value) {
        emitResult.email = formModel.email
      } else {
        emitResult.phone = formModel.phoneConfig
      }
      emits("mainClick", emitResult)
    } catch (e) {}
  }
}, 300)

const emailExistHandler = (e) => {
  if (e.target.className === "c-email-exist") {
    otherLoginClick(showEmailErrorObj.platform)
  }
}

let loginWithPhoneEvent = null

const loginInWithPhoneHandler = () => {
  _pointLoginAndRegFlowPageButtonView({
    featureName: "main",
    elementName: unref(enterEmail) ? "login_with_phone" : "login_with_email"
  })
  _pointLoginSignUpClick({
    element_name: unref(enterEmail) ? "login_with_phone" : "login_with_email",
    element_position: "popup",
    element_type: "button",
    popup_name: "login_or_signup",
    feature_module: "login_or_signup",
    feature_name: "main"
  })
  enterEmail.value = !enterEmail.value
  formRef.value && formRef.value.clearForms()
  formModel.email = ""
  formModel.phoneConfig = {}
  loginWithPhoneEvent && loginWithPhoneEvent()
  nextTick(() => {
    loginWithPhoneEventFn()
  })
}

const loginWithPhoneEventFn = () => {
  loginWithPhoneEvent = useEventListener(
    document.querySelector(".sign-in-or-sign-up-wrapper .t-2 .t-2-l"),
    "click",
    loginInWithPhoneHandler
  )
}

const recoveryEmail = () => {
  let closeDialog = null
  const save = async (data: { email: string }) => {
    const { email } = data
    await sendEmail({ email })
    closeDialog()
    useSuccessMessage(useTranslateI18n(`login.signIn.msg`))
  }
  useCommonNormalDialog({
    popperClass: "sign-in-or-sign-up-wrapper-dialog",
    contentSlots({ close }) {
      closeDialog = close
      const com = resolveComponent("CommonNewLoginRecoveryEmail")
      return <com onClose={closeDialog} onSave={save} />
    }
  })
  emits("closeSingUp")
}

onMounted(() => {
  loginWithPhoneEventFn()
})

onBeforeUnmount(() => {
  loginWithPhoneEvent && loginWithPhoneEvent()
})
</script>

<style scoped lang="scss">
.sign-in-or-sign-up-wrapper {
  display: flex;
  flex-direction: column;
  .t-1 {
    font-size: 24px;
    line-height: normal;
    @include fontSemibold;
    color: #000;
  }
  .t-2 {
    color: rgba(0, 0, 0, 0.55);
    font-size: 16px;
    line-height: 150%; /* 24px */
    margin-top: 8px;
    margin-bottom: 48px;
    @include fontRegular;
    :deep(.t-2-l) {
      position: relative;
      line-height: 150%;
      cursor: pointer;
      text-decoration: underline;
    }
  }
  .last-desc {
    margin-top: 16px;
    color: rgba(0, 0, 0, 0.55);
    line-height: normal;
  }
  .tip-desc {
    display: inline-block;
    color: #fc5555;
    text-align: right;
    font-family: "PingFang SC";
    margin-top: 12px;
    span {
      cursor: pointer;
    }
  }
  .other-login-in {
    display: flex;
    margin-top: 40px;
    flex-direction: column;
    .text-flag {
      width: 100%;
      height: 17px;
      position: relative;
      &::before {
        content: "";
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        position: absolute;
        width: 100%;
        height: 0.5px;
        background-color: rgba(0, 0, 0, 0.3);
      }
      > span {
        color: rgba(0, 0, 0, 0.3);
        height: 17px;
        line-height: 17px;
        font-weight: 400;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        padding: 0 8px;
        background-color: #fff;
      }
    }
    .btn-list-wrapper {
      margin-top: 14px;
      .btn-list-item {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 42px;
        background: rgba(0, 0, 0, 0.05);
        border-radius: 42px;
        cursor: pointer;
        color: rgba(0, 0, 0, 0.55);
        font-size: 14px;
        margin-bottom: 8px;
        @include ease-in-out;
        &:hover {
          background: rgba(0, 0, 0, 0.15);
        }
        img {
          width: 16px;
          margin-right: 8px;
        }
      }
    }
    .account {
      display: flex;
      justify-content: center;
      margin-top: 8px;
      line-height: 17px;
      span:nth-child(1) {
        font-weight: 400;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.55);
      }
      span:nth-child(2) {
        font-weight: 500;
        font-size: 12px;
        color: #1877f2;
        margin-left: 4px;
        cursor: pointer;
      }
    }
  }
  :deep(.pix-common-search) {
    .el-form {
      .el-form-item {
        margin-bottom: 24px;
        .el-form-item__label {
          font-size: 12px;
          line-height: normal;
          color: rgba(0, 0, 0, 0.55);
        }
        .el-input__wrapper {
          .el-input__inner {
            height: 34px;
          }
        }
        .country-select {
          height: 36px;
          line-height: 36px;
          .el-select {
            margin-left: 1px;
            .el-input {
              .el-input__wrapper {
                height: 100%;
                padding: 0 12px !important;
                border-radius: 0;
                .el-input__inner {
                  height: 100% !important;
                  width: 45px;
                }
                .el-input__suffix {
                  .el-input__suffix-inner {
                    i {
                      width: 16px;
                      height: 16px;
                      background-image: url("@/assets/images/pages/payment/select-icon2.png");
                      background-size: 100% 100%;
                      font-size: 0;
                      margin-left: 0;
                    }
                  }
                }
              }
            }
          }
          > .el-input {
            .el-input__wrapper {
              padding-left: 85px !important;
              .el-input__inner {
                height: 36px;
              }
            }
          }
        }
      }
    }
  }
}
</style>

<style lang="scss">
.email-exist {
  padding-top: 5px;
  font-size: 12px;
  line-height: 17px;
  color: $bg-error-color;
  .c-email-exist {
    text-decoration: underline;
    cursor: pointer;
    margin-left: 3px;
  }
}
</style>
