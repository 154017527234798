<template>
  <div :class="['home-page']">
    <PagesNewHomeHero />
    <PagesNewHomeSimplifyLife />
    <PagesNewHomeCommonAnimationStickyCover>
      <LazyPagesNewHomeKols />
    </PagesNewHomeCommonAnimationStickyCover>
    <PagesNewHomeAdvantage />
    <!-- <LazyPagesNewHomeBrank /> -->
    <PagesNewHomeScene />
    <PagesNewHomeEnjoyBox>
      <template #top>
        <PagesNewHomeFeature />
      </template>
      <template #default="{ aniParams }">
        <PagesNewHomeEnjoy :ani-params="aniParams" />
      </template>
      <template #bottom>
        <PagesNewHomeCommunity />
      </template>
    </PagesNewHomeEnjoyBox>
    <PagesNewHomeEnding />
    <PagesNewHomeCommonAnimationTranslateScroll>
      <PagesNewHomeCommonFooter />
    </PagesNewHomeCommonAnimationTranslateScroll>
    <PagesHomeSidebar />
    <PagesNewHomeGiftBox v-if="hallows_open" />
  </div>
</template>

<script lang="ts" setup>
import { scrollData } from "~/constant/pages/newHome"
import { useAllActivitys } from "~/constant/useAllActivitys"

const { hallows_open } = useAllActivitys()

definePageMeta({
  layout: "home",
  middleware: ["new-home"]
})
useNavBarScroll(scrollData)

onBeforeUnmount(() => {
  // 移除导航栏阴影
  useNavBarShadowShow().value = false
})

// const setSEO = () => {
//   const seoInfo = newHOmeSEOConfig[useI18nCountry().value] || newHOmeSEOConfig[ILocaleValue.en]
//   const { title, description, keyword } = seoInfo
//   useHead({
//     title: title,
//     titleTemplate: "",
//     meta: [
//       {
//         hid: "description",
//         name: "description",
//         content: description
//       },
//       {
//         hid: "keywords",
//         name: "keywords",
//         content: keyword
//       }
//     ]
//   })
// }

// setSEO()
</script>

<style scoped lang="scss">
:deep(*) {
  user-select: unset;
}
:deep(.sticky-animation-box) {
  .sticky-box {
    background: #fcfcf3;
  }
}
</style>
