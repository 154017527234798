<template>
  <div class="cancel-sub-before-dialog">
    <div class="t1">{{ useTranslateI18n(`profile.billingUsage.billing.cardData[1].title1`) }}</div>
    <div class="content">
      <div class="name">
        <span class="title">{{ subscription_info_v2_web.plan_name }}</span>
        <span class="cancel" @click="submit"> {{ useTranslateI18n(`profile.confirm.cancel`) }}</span>
      </div>
      <div class="credits">
        <span
          class="c-remain"
          v-html="
            useTranslateI18n(`payment.comboSub.t89`, [
              subscription_info_v2_web.left_count,
              { type: 'static', class: 't-1' }
            ])
          "
        ></span>
        <span
          class="c-total"
          v-html="
            useTranslateI18n(`payment.comboSub.t90`, [
              subscription_info_v2_web.total_count,
              { type: 'static', class: 't-1' }
            ])
          "
        ></span>
      </div>
      <div class="desc">{{ desc }}</div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useUser } from "@/store/user"

interface IProps {
  title: string
  descList: string[]
}

const props = withDefaults(defineProps<IProps>(), {
  title() {
    return ""
  },
  descList() {
    return []
  }
})

const { subscription_info_v2_web } = storeToRefs(useUser())

const desc = computed(() => {
  return useTranslateI18n(`profile.billingUsage.sub.normalDesc`, [
    unref(subscription_info_v2_web).next_plan_credits,
    unref(subscription_info_v2_web).next_payment
  ])
})

const emits = defineEmits(["submitClick"])

const submit = useDebounceFn(() => {
  emits("submitClick")
}, 300)
</script>

<style scoped lang="scss">
.cancel-sub-before-dialog {
  display: flex;
  flex-direction: column;
  line-height: normal;
  color: #000;
  .t1 {
    @include fontSemibold;
    font-size: 24px;
  }
  .content {
    margin-top: 24px;
    border-radius: 12px;
    background: #f6f7fb;
    padding: 20px;
    display: flex;
    flex-direction: column;
    .name {
      display: flex;
      justify-content: space-between;
      .title {
        font-size: 16px;
        color: #000;
        @include fontSemibold;
      }
      .cancel {
        font-size: 14px;
        color: #306fff;
        cursor: pointer;
      }
    }
    .credits {
      margin-top: 16px;
      display: flex;
      .c-remain,
      .c-total {
        font-size: 14px;
        color: #000;
        align-items: center;
        @include fontSemibold;
        :deep(.t-1) {
          opacity: 0.55;
          @include fontRegular;
        }
      }
      .c-total {
        margin-left: 27px;
      }
    }
    .desc {
      margin-top: 16px;
      font-size: 12px;
      color: #000;
      opacity: 0.55;
      @include fontMedium;
    }
  }
}
</style>
