import { fetchSoftwareInfo, SoftwareInfo, IMeta } from "~~/composables/api/download"
import { SoftwareType, SoftwareTypeDict } from "~~/constant/pages/download"
import { AbOn } from "@/composables/api/download"
import { downloadPlatformAppType, DownloadPlatform } from "@/constant"

interface DownloadState {
  // softwareList: Pick<SoftwareInfo, "runtime" | "package_url" | "app_type" | "version">[]
  softwareList: SoftwareInfo[]
  meta: IMeta
  flag: boolean
  softwareType: SoftwareType
}
export const useDownloadStore = defineStore("app", () => {
  const downloadState = reactive<DownloadState>({
    softwareList: [],
    meta: {} as IMeta,
    flag: false, // throttle
    softwareType: SoftwareType.Trial
  })

  // macarm渠道包下载的版本
  const is_channel_mac_arm_web = computed(() => downloadState.meta.is_channel_mac_arm_web)
  //mac渠道包下载的版本
  const is_channel_mac_intel_web = computed(() => downloadState.meta.is_channel_mac_intel_web)
  //win渠道包下载的版本
  const is_channel_win_web = computed(() => downloadState.meta.is_channel_win_web)

  // 是否是抢先版本
  const isTrialSoftwareType = computed(() => downloadState.softwareType === SoftwareType.Trial)

  // 获取稳定版或者抢先版的包
  const getSoftwareList = computed(() =>
    downloadState.softwareList.filter((item) => item.type === downloadState.softwareType)
  )

  // 稳定版本的包
  const getSoftwareStableList = computed(() =>
    downloadState.softwareList.filter((item) => item.type === SoftwareType.Stable)
  )

  // 抢先版本的包
  const getSoftwareTrialList = computed(() =>
    downloadState.softwareList.filter((item) => item.type === SoftwareType.Trial)
  )

  function getChannelId(appType) {
    let resultSource
    switch (appType) {
      case downloadPlatformAppType[DownloadPlatform.DownloadMac]:
        resultSource = is_channel_mac_arm_web.value ? getSoftwareTrialList.value : getSoftwareStableList.value
        break

      case downloadPlatformAppType[DownloadPlatform.DownloadMacIntel]:
        resultSource = is_channel_mac_intel_web.value ? getSoftwareTrialList.value : getSoftwareStableList.value

        break

      case downloadPlatformAppType[DownloadPlatform.DownloadWin]:
        resultSource = is_channel_win_web.value ? getSoftwareTrialList.value : getSoftwareStableList.value
        break
    }
    return resultSource.find((item) => item.app_type === appType).id
  }

  // 是否显示抢先版和体验版的switch
  const isAllSoftwareList = computed(() => {
    return downloadState.meta.ab_on === AbOn.All
  })

  // 设置softwareType
  const setSoftwareType = (type: SoftwareType) => {
    downloadState.softwareType = type
  }

  async function fetchSoftwareList(isClient = false) {
    try {
      if (downloadState.softwareList.length) {
        return
      }
      // downloadState.flag = true
      const { data, meta } = (await fetchSoftwareInfo(isClient)) as any
      downloadState.softwareList = data
      // 设置灰度信息
      downloadState.meta = meta

      if (!isAllSoftwareList.value) {
        setSoftwareType(SoftwareTypeDict[downloadState.meta.ab_on])
      }
    } catch (err) {
      console.log(err)
      Promise.reject(err)
    } finally {
      downloadState.flag = false
    }
  }

  return {
    downloadState,

    getSoftwareList,

    isAllSoftwareList,

    getSoftwareStableList,

    isTrialSoftwareType,

    setSoftwareType,

    fetchSoftwareList,
    getChannelId,
    getSoftwareTrialList,
    is_channel_mac_arm_web,
    is_channel_mac_intel_web,
    is_channel_win_web
  }
})
