import { TOKEN_ROUTER } from "@/constant/permission/index"
import { useUser } from "@/store/user"
import { TOKEN_KEY } from "@/constant/cookies"
import { LocalStorageKey } from "@/constant/cache"
import { getHeadLanguage } from "@/constant/i18n-country"
import { IErrorLoginType } from "@/constant/login"
import { IErrorType } from "@/constant/newFeature"
import { stHeaderEncodeURI } from "@/composables/useTsSdk"
import { IErrorPaymentType } from "@/constant/payment"
// import type { UseFetchOptions } from "nuxt/dist/app/composables"
import type { FetchOptions } from "ohmyfetch"

import type { ResponseConfig } from "./interface"
import { enumErrorMessage, codePos, messagePos, TIMEOUT_TIME, commonErrorCode } from "./enum"

const noMessageTipDict = [
  IErrorLoginType.RepeatVerify,
  IErrorLoginType.Retrieve,
  IErrorLoginType.RedeemCode1,
  IErrorLoginType.RedeemCode2,
  IErrorLoginType.VerCode,
  IErrorType.unFormatCode,
  IErrorType.noFaceCode,
  IErrorPaymentType.VerCouponCode
]

const baseUrl = process.env.BASE_URL || "/"

function isOnline() {
  if (process.client) {
    return window.navigator ? window.navigator.onLine : true
  }
}

export function getToken() {
  const token = useCookie(TOKEN_KEY)
  return token.value || ""
}

export function resetToken() {
  const token = useCookie(TOKEN_KEY)
  token.value = null
}

// 处理错误信息
export function handlerError(reject, err) {
  const use_user = useUser()
  if (process.server) {
    if (err.statusCode == 401) {
      // resetToken()
    }
    return reject(err)
  }
  if (!isOnline()) {
    useErrorMessage(enumErrorMessage.NET_WORK_MESSAGE)
    err.message = enumErrorMessage.NET_WORK_MESSAGE
    return reject(err)
  }
  const code = useGet(err, codePos, useGet(err, "response.status", useGet(err, "statusCode", -1)))
  const message = useGet(
    err,
    messagePos,
    useGet(err, "response.statusText", useGet(err, "message", enumErrorMessage.UNKNOWN_MESSAGE))
  )
  err.message =
    err.message === enumErrorMessage.NET_WORK_MESSAGE
      ? enumErrorMessage.NET_WORK_MESSAGE
      : message || enumErrorMessage.UNKNOWN_MESSAGE
  switch (code) {
    case 401:
      // 客户端接口容错
      // eslint-disable-next-line no-case-declarations
      const { isInlineClient } = useClientBridgeRouteGuard()
      if (isInlineClient) {
        use_user.clearUserInfo(false)
        return useErrorMessage({ message: commonErrorCode["403"], duration: 15000 })
      }

      // 401 没权限的话清空信息 刷新页面
      if (process.client) {
        use_user.clearUserInfo()
      }
      // // 有需要权限的路由
      // if (TOKEN_ROUTER.includes(useRoute().path)) {
      //   // 暂时跳首页
      // }
      return reject(err)
    default:
      break
  }
  if (!noMessageTipDict.includes(code)) {
    useErrorMessage(err.message)
  }
  return reject(err)
}

export const fetch = <K = any, T = ResponseConfig<K>["data"]>(
  url: string,
  options?: FetchOptions,
  isTotalData?: boolean
): Promise<T> => {
  return new Promise((resolve, reject) => {
    Promise.race([
      $fetch(url, {
        ...options,
        baseURL: baseUrl,
        headers: {
          Authorization: getToken(),
          Language: getHeadLanguage(), // 语言标识
          "Ev-Point": stHeaderEncodeURI(),
          ...(options.headers || {})
        }
      }),
      new Promise(function (_resolve, _reject) {
        setTimeout(() => _reject(new Error(enumErrorMessage.NET_WORK_MESSAGE)), TIMEOUT_TIME)
      })
    ])
      .then((data: ResponseConfig) => {
        const code = data.code
        if (data instanceof Blob) {
          resolve(data)
        }
        switch (code) {
          case 0:
          case 200: {
            isTotalData ? resolve(data) : resolve(data.data)
          }
        }
        reject(data)
      })
      .catch((err: any) => {
        handlerError(reject, err)
      })
  })
}

export default new (class Http {
  get<K = any, T = ResponseConfig<K>["data"]>(
    url: string,
    params?: Record<string, any>,
    options?: FetchOptions,
    isTotalData?: boolean
  ): Promise<T> {
    return fetch(
      url,
      {
        method: "get",
        params,
        headers: {
          "Content-Type": "application/json"
        },
        ...options
      },
      isTotalData
    )
  }

  post<K = any, T = ResponseConfig<K>["data"]>(
    url: string,
    body?: Record<string, any>,
    options?: FetchOptions,
    isTotalData?: boolean
  ): Promise<T> {
    return fetch(
      url,
      {
        method: "post",
        body,
        headers: {
          "Content-Type": "application/json"
        },
        ...options
      },
      isTotalData
    )
  }

  put<K = any, T = ResponseConfig<K>["data"]>(
    url: string,
    body?: Record<string, any>,
    options?: FetchOptions,
    isTotalData?: boolean
  ): Promise<T> {
    return fetch(
      url,
      {
        method: "put",
        body,
        ...options
      },
      isTotalData
    )
  }
})()
